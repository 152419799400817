import { translate as t } from '../../../../../utils/ts/translate';

function httpCodeToStatusText(code: number) {
  switch (code) {
    case 403:
      return {
        errorText: t('httpErrors.code403errorText'),
        statusText: t('httpErrors.code403statusTextText')
      };
    case 404:
      return {
        errorText: t('httpErrors.code404errorText'),
        statusText: t('httpErrors.code404statusTextText')
      };
    case 500:
      return {
        errorText: t('httpErrors.code500errorText'),
        statusText: t('httpErrors.code500statusTextText')
      };
    default:
      return {
        errorText: t('httpErrors.code500errorText'),
        statusText: t('httpErrors.code500statusTextText')
      };
  }
}

export default httpCodeToStatusText;
