import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  DOWNLOAD_TEXTURE_FILE,
  DOWNLOAD_TEXTURE_FILE_START,
  DOWNLOAD_TEXTURE_FILE_SUCCESS,
  DOWNLOAD_TEXTURE_FILE_ERROR
} from '../TextureFileConstants';

import { postItemStart, postItemResource } from '../../common/sagas';

function* downloadTextureFile({ id, options, cb }) {
  yield put(postItemStart(DOWNLOAD_TEXTURE_FILE_START, id, options));

  const downloadTextureFileAction = yield spawn(postItemResource, `texture_files/${id}/download`, {
    success: DOWNLOAD_TEXTURE_FILE_SUCCESS,
    error: DOWNLOAD_TEXTURE_FILE_ERROR
  }, { id, options, cb });
  yield take(LOCATION_CHANGE);
  yield cancel(downloadTextureFileAction);
}

export default function* watchDownloadTextureFile() {
  yield takeEvery(DOWNLOAD_TEXTURE_FILE, downloadTextureFile);
}
