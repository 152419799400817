import { Component } from 'react';
import PropTypes from 'prop-types';
import compact from 'lodash/compact';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import snakeCase from 'lodash/snakeCase';
import omit from 'lodash/omit';

import { prepareRequest } from '../../../../utils/prepareRequest';

class BaseContainer extends Component {
  static propTypes = {
    currentUser: PropTypes.shape({ id: PropTypes.number }).isRequired,
    namespace: PropTypes.string,
    createNamespace: PropTypes.string,
    updateNamespace: PropTypes.string,
    removeNamespace: PropTypes.string,
    serializer: PropTypes.string,
    action: PropTypes.string,
    scope: PropTypes.string,
    fetchType: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    filters: PropTypes.shape().isRequired,
    params: PropTypes.shape(),
    subscriptions: PropTypes.bool,
    item: PropTypes.shape({ id: PropTypes.number }).isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })).isRequired,
    itemsMeta: PropTypes.shape({ page: PropTypes.number }).isRequired,
    pageSize: PropTypes.number,
    withoutPagination: PropTypes.bool,
    persistFilters: PropTypes.bool,
    fetchOptions: PropTypes.shape(),
    createOptions: PropTypes.shape(),
    updateOptions: PropTypes.shape(),
    removeOptions: PropTypes.shape(),
    fetchItems: PropTypes.func,
    subscribeToItemsChannel: PropTypes.func,
    unsubscribeFromItemsChannel: PropTypes.func,
    clearItemFilters: PropTypes.func,
    clearModalValues: PropTypes.func,
    fetchItemsCallback: PropTypes.func,
    fetchItem: PropTypes.func,
    createItem: PropTypes.func,
    afterCreate: PropTypes.func,
    updateItem: PropTypes.func,
    afterUpdate: PropTypes.func,
    removeItem: PropTypes.func,
    afterRemove: PropTypes.func
  };

  static defaultProps = {
    action: null,
    scope: null,
    fetchType: 'index',
    namespace: null,
    createNamespace: null,
    updateNamespace: null,
    removeNamespace: null,
    pageSize: 50,
    withoutPagination: false,
    clearItemFilters: null,
    clearModalValues: null,
    persistFilters: false,
    subscriptions: false,
    id: null,
    params: null,
    fetchItemsCallback: null,
    fetchItem: null,
    createItem: null,
    afterCreate: null,
    updateItem: null,
    afterUpdate: null,
    removeItem: null,
    afterRemove: null,
    serializer: null,
    fetchOptions: {},
    createOptions: {},
    updateOptions: {},
    removeOptions: {},
    fetchItems: null,
    subscribeToItemsChannel: null,
    unsubscribeFromItemsChannel: null
  };

  constructor(props) {
    super(props);

    this.action = this.props.action || snakeCase(compact([
      this.props.scope,
      this.props.scope,
      this.props.fetchType,
      this.props.serializer
    ]).join('_'));
  }

  componentDidMount() {
    const { currentUser, fetchType, id, filters, fetchOptions, params, pageSize, withoutPagination, namespace, serializer, persistFilters } = this.props;

    if (fetchType !== 'ignore') {
      currentUser.hasPermissions(this.action);
      // throw new Error(`User #${currentUser.get('id')} has no permission ${this.action}`);
    }

    if (fetchType === 'show') {
      this.fetchShowData(id, {
        ...fetchOptions,
        params,
        namespace,
        serializer
      });
    }

    if (fetchType === 'index') {
      this.fetchData({
        filters,
        params,
        pageSize,
        withoutPagination,
        namespace,
        serializer,
        persistFilters
      });
    }
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    const { fetchType } = this.props;

    if ((fetchType === 'index') && !isEqual(this.props.filters, nextProps.filters)) {
      this.handleFilterChange(nextProps.filters);
    }

    if ((fetchType === 'index') && this.props.serializer !== nextProps.serializer) {
      this.fetchData({
        filters: this.props.filters,
        params: this.props.params,
        pageSize: this.props.pageSize,
        namespace: this.props.namespace,
        serializer: nextProps.serializer,
        persistFilters: this.props.persistFilters
      });
    }

    if (fetchType === 'show' && this.props.serializer !== nextProps.serializer) {
      this.fetchShowData(this.props.id, {
        ...this.props.fetchOptions,
        params: this.props.params,
        namespace: this.props.namespace,
        serializer: nextProps.serializer
      });
    }
  }

  componentWillUnmount() {
    const { currentUser, fetchType, items, item, subscriptions } = this.props;

    if (subscriptions && fetchType !== 'ignore' && currentUser.hasPermissions(this.action)) {
      this.removeSubscriptions(fetchType === 'index' ? items : [item]);
    }
  }

  addSubscriptions(items) {
    if (!this.props.subscriptions) {
      return null;
    }

    return items.map(item => this.props.subscribeToItemsChannel('updated', item.id));
  }

  removeSubscriptions(items) {
    if (!this.props.subscriptions) {
      return null;
    }

    return items.map(item => this.props.unsubscribeFromItemsChannel('updated', item.id));
  }

  fetchData(options, cb = null) {
    return prepareRequest(this.props.fetchItems, this.props.itemsMeta, options, ({ items }) => {
      this.addSubscriptions(items);
      cb && cb();
    });
  }

  fetchShowData(id, options, cb = null) {
    return this.props.fetchItem(id, options, ({ item }) => {
      this.addSubscriptions([item]);
      cb && cb();
    });
  }

  handleLoadMore = (options = {}) => this.fetchData({
    page: get(this.props.itemsMeta, 'page') + 1,
    pageSize: this.props.pageSize,
    withoutPagination: this.props.withoutPagination,
    filters: this.props.filters,
    persistFilters: false,
    namespace: this.props.namespace,
    serializer: this.props.serializer,
    params: this.props.params,
    loadMore: true,
    ...options
  });

  handleFilterChange = values => {
    const { items, itemsMeta, persistFilters, fetchItemsCallback } = this.props;

    this.removeSubscriptions(items);
    this.fetchData({
      page: 1,
      pageSize: this.props.pageSize,
      withoutPagination: this.props.withoutPagination,
      namespace: this.props.namespace,
      serializer: this.props.serializer,
      filters: { ...get(itemsMeta, 'filters'), ...values },
      persistFilters
    }, fetchItemsCallback);
  };

  handleClearFilters = () => {
    this.props.clearItemFilters({ namespace: this.props.namespace });
    this.removeSubscriptions(this.props.items);
    this.fetchData({
      page: 1,
      pageSize: this.props.pageSize,
      withoutPagination: this.props.withoutPagination,
      filters: {},
      namespace: this.props.namespace,
      serializer: this.props.serializer,
      persistFilters: this.props.persistFilters
    });
  };

  handleRemoveFilters = filterKeys => {
    const { items, itemsMeta, persistFilters, fetchItemsCallback } = this.props;

    this.removeSubscriptions(items);
    this.fetchData({
      page: 1,
      pageSize: this.props.pageSize,
      withoutPagination: this.props.withoutPagination,
      namespace: this.props.namespace,
      serializer: this.props.serializer,
      filters: omit(get(itemsMeta, 'filters'), filterKeys),
      persistFilters
    }, fetchItemsCallback);
  };

  handleClearModalValues = () => this.props.clearModalValues({ namespace: this.props.namespace });

  handleFetchItems = (options, cb = null) => this.fetchData({
    filters: this.props.filters,
    params: this.props.params,
    pageSize: this.props.pageSize,
    withoutPagination: this.props.withoutPagination,
    namespace: this.props.namespace,
    serializer: this.props.serializer,
    persistFilters: this.props.persistFilters,
    ...options
  }, cb);

  handleFetchItem = itemOrId => this.props.fetchItem(isObject(itemOrId) ? itemOrId.id : itemOrId, {
    ...this.props.fetchOptions,
    params: this.props.params,
    namespace: this.props.namespace,
    serializer: this.props.serializer
  });

  handleCreateItem = (values, cb, form) => vals => this.props.createItem({
    ...(values || {}),
    ...(vals || {})
  }, {
    ...this.props.createOptions,
    ...(this.props.namespace ? { namespace: this.props.namespace } : {}),
    ...(this.props.createNamespace ? { namespace: this.props.createNamespace } : {}),
    ...(this.props.serializer ? { serializer: this.props.serializer } : {}),
    form
  }, item => {
    this.addSubscriptions([item]);
    cb && cb(item);
    this.props.afterCreate && this.props.afterCreate(item);
  });

  handleUpdateItem = (itemOrId, valuesOrCallback, callbackOrOptions) => (vals, cb, form) => this.props.updateItem(
    isObject(itemOrId) ? itemOrId.id : itemOrId,
    (isFunction(valuesOrCallback) ? null : valuesOrCallback) || vals,
    {
      ...this.props.updateOptions,
      ...(this.props.namespace ? { namespace: this.props.namespace } : {}),
      ...(this.props.updateNamespace ? { namespace: this.props.updateNamespace } : {}),
      ...(this.props.serializer ? { serializer: this.props.serializer } : {}),
      ...(isObject(callbackOrOptions) ? callbackOrOptions : {}),
      form
    }, item => {
      (callbackOrOptions && isFunction(callbackOrOptions)) && callbackOrOptions(item);
      (valuesOrCallback && isFunction(valuesOrCallback)) && valuesOrCallback(item);
      cb && cb(item);
      this.props.afterUpdate && this.props.afterUpdate(item);
    }
  );

  handleRemoveItem = (itemOrId, cb, options = {}) => itm => this.props.removeItem(((isObject(itemOrId) ? itemOrId : { id: itemOrId }) || itm).id, {
    ...options,
    ...this.props.removeOptions,
    ...(this.props.namespace ? { namespace: this.props.namespace } : {}),
    ...(this.props.removeNamespace ? { namespace: this.props.removeNamespace } : {})
  }, () => {
    cb && cb();
    this.props.afterRemove && this.props.afterRemove();
  });

  handleRemoveSubscriptions = items => items.map(item => this.props.unsubscribeFromItemsChannel('updated', item.id));

  render() {
    return this.props.children({
      fetchItems: this.handleFetchItems,
      loadMoreItems: this.handleLoadMore,
      changeItemsFilter: this.handleFilterChange,
      clearItemsFilters: this.handleClearFilters,
      removeItemsFilters: this.handleRemoveFilters,
      clearModalValues: this.handleClearModalValues,
      fetchItem: this.handleFetchItem,
      createItem: this.handleCreateItem,
      updateItem: this.handleUpdateItem,
      removeItem: this.handleRemoveItem,
      subscribeToItemsChannel: this.props.subscribeToItemsChannel,
      unsubscribeFromItemsChannel: this.props.unsubscribeFromItemsChannel,
      removeSubscriptions: this.handleRemoveSubscriptions
    });
  }
}
export default BaseContainer;
