import React from 'react';

import {
  fetchBills as fetchBillsAction,
  fetchBill as fetchBillAction,
  clearBillsFilters as clearBillsFiltersAction,
  clearBillModalValues as clearBillModalValuesAction,
  downloadBillsXls as downloadBillsXlsAction,
  payBillFromAccount as payBillFromAccountAction,
  checkBillPayment as checkBillPaymentAction,
  stripePayment as stripePaymentAction,
  cancelBill as cancelBillAction,
  setBillPaying as setBillPayingAction,
  checkOrderPayment as checkOrderPaymentAction
} from '../../../bills/BillActions';

import { baseHOC } from '../utils';

const withBills = (options = {}) => WrappedComponent => {
  function BillsHoc(props) {
    return (
      <WrappedComponent {...props} />
    );
  }

  return baseHOC(options, {
    fetchBills: fetchBillsAction,
    fetchBill: fetchBillAction,
    downloadBillsXls: downloadBillsXlsAction,
    clearBillsFilters: clearBillsFiltersAction,
    clearBillModalValues: clearBillModalValuesAction,
    payBillFromAccount: payBillFromAccountAction,
    checkBillPayment: checkBillPaymentAction,
    stripePayment: stripePaymentAction,
    cancelBill: cancelBillAction,
    setBillPaying: setBillPayingAction,
    checkOrderPayment: checkOrderPaymentAction
  }, {
    downloadBillsXls: ({ downloadBillsXls }) => (params, cb) => () => downloadBillsXls(params, cb),
    payBillFromAccount: ({ payBillFromAccount }) => (bill, cb) => payBillFromAccount(bill.id, {}, cb),
    checkBillPayment: ({ checkBillPayment }) => (bill, cb) => () => checkBillPayment(bill.id, {}, cb),
    stripePayment: ({ stripePayment }) => (bill, cb) => token => stripePayment(bill.id, { source: token.id }, { pure: true }, cb),
    cancelBill: ({ cancelBill }) => (bill, cb) => cancelBill(bill.id, {}, cb),
    setBillPaying: ({ setBillPaying }) => (bill, cb) => setBillPaying(bill.id, {}, cb),
    checkOrderPayment: ({ checkOrderPayment }) => (values, cb) => checkOrderPayment(values, { pure: true }, cb)
  })(BillsHoc);
};

export default withBills;
