import React, { memo } from 'react';
import cl from 'classnames';

import { Image } from '../../../types';

import { Files } from '../../../utils/Files';

interface AvatarProps {
  addClass?: string;
  className?: string;
  grayscale?: boolean;
  image: Image | null;
  style?: {};
  version?: string;
  width?: string;
  height?: string;
  alt?: string;
  title?: string;
}

function Avatar({
  className,
  addClass,
  image,
  style,
  version = 'thumb_150x150',
  width = '36',
  height = '36',
  alt = '',
  title,
  grayscale = false
}: AvatarProps) {
  return (
    <img
      className={className || cl('rounded-circle', { grayscale }, addClass)}
      src={Files.image(image, version)}
      alt={alt}
      title={title}
      style={style}
      width={width}
      height={height}
    />
  );
}

export default memo(Avatar);
