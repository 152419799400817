import Promise from 'bluebird';

import { authLoading, authStopLoading, authSuccessMessage, authError } from '../AuthActions';

import { translate as t } from '../../utils';
import { mapErrorsToForm } from '../../utils/mapErrorsToForm';
import { apiRequest } from '../../utils/apiRequest';
import { removeAuthToken } from '../../utils/authToken';

// updatePassword

export default function updatePassword(auth) {
  removeAuthToken();

  return dispatch => {
    dispatch(authLoading());

    return apiRequest.authPost('auth/password', { auth })
      .then(() => {
        dispatch(authStopLoading());
        dispatch(authSuccessMessage(t('pages.update_password.success_message')));
        setTimeout(() => dispatch(authSuccessMessage()), 5000);
        return Promise.resolve();
      })
      .catch(error => mapErrorsToForm(dispatch, error, authError(error)));
  };
}
