export default {
  plural: 'Reports',
  general: 'General',
  ReportsCount: 'Reports {count}',
  ReportInfo: 'Report Info',
  Report: 'Report',
  updateReport: 'Update Report',
  createReport: 'Create Report',
  editReport: 'Edit Report',
  deleteReport: 'Delete Report',
  ReportUpdatedSuccess: 'Report successfully updated.',
  ReportDeletedSuccess: 'Report successfully deleted.',
  ReportCreatedSuccess: 'Report successfully created.',

  types: {
    finance: 'Finance Reports',
    pm: 'PM Reports',
    cm: 'CM Reports',
    errors: 'Errors Reports',
    clients_global_debt: 'Global Clients Debt',
    projects_global_debt: 'Global Projects Debt',
    tasks_global_balance: 'Global Tasks Balance',
    tasks_global: 'Projects Global Report',
    discussions_global: 'Tasks Global Report',
    global_tasks_debt: 'Global Tasks Debt',
    suspended_discussions: 'Suspended Tasks',
    payer_bills: 'Received Payments',
    burned_discussions: 'Burned Tasks',
    discussions_check_lists: 'Task Checklists',
    discussions_delta: 'Reply Rate',
    discussions_with_task_account: 'Discussions with TA',
    postponed_terms: 'Postponed Terms',
    double_distributions: 'Double Distributions',
    projects_global_balance: 'Global Projects Balance',
    tags_discussions: 'Tagged Tasks',
    clients_balances: 'Clients Balances',
    minus_distributions: 'Minus Distributions',
    managers_dynamics: 'Managers Dynamics',
    total_amounts: 'Total Client Payments',
    workers_balances: 'Workers Balances',
    workers_clients: 'Client to Worker Payments',
    workers_global_debt: 'Global Workers Debt',
    account_receivable_discussions: 'Tasks Account Receivable Report',
    workers_time: 'Workers Time Report',
    workers_revenue: 'Workers Revenue Report',
    clients_revenue: 'Clients Revenue Report',
    users_revenue_share: 'Revenue Share Report',
    clients_average_payment_frequency: 'Clients Average Payment Frequency Report',
    clients_global: 'Clients Global Report',
    workers_global: 'Workers Global Report',
    workers_downloads: 'Workers Downloads',
    workers_downloads_detailed: 'Workers Downloads Detailed',
    discussions: {
      delta: 'Tasks Delta Report',
      burned: 'Burned Tasks Report',
      suspended: 'Suspended Tasks Report',
      postponed_terms: 'Tasks Postponed Terms Report',
      check_lists: 'Tasks Check Lists Report'
    },
    users: {
      total_amounts: 'Total Amounts Report',
      workers_balances: 'Workers Balances Report',
      workers_clients: 'Workers Clients Report',
      clients_balances: 'Clients Balances Report',
      managers_dynamics: 'Managers Dynamics Report',
      global_clients_debt: 'Global Clients Debt',
      global_workers_debt: 'Global Workers Debt'
    },
    distributions: {
      double: 'Double Distributions Report',
      minus: 'Minus Distributions Report'
    },
    bills: {
      payer: 'Payer Bills Report'
    },
    projects: {
      global_debt: 'Global Projects Debt',
      global_balance: 'Global Projects Balance'
    },
    tags: {
      discussions: 'Tagged Tasks'
    }
  },

  statuses: {
    initialized: 'Initialized',
    processing: 'Processing',
    completed: 'Completed',
    failed: 'Failed'
  }
};
