import React, { memo } from 'react';

import { ID } from '../../../../../types';

import { ModalLink, ModalLinkProps } from '../../../../../helpers/ts/ModalLink';

import { PageRender } from '../PageRender';

interface PageModalLinkProps extends Omit<ModalLinkProps, 'children'> {
  pageId: ID;
}

function PageModalLink({ pageId, ...modalLinkProps }: PageModalLinkProps) {
  return (
    <ModalLink modalSize="lg" {...modalLinkProps}>
      <PageRender id={pageId} />
    </ModalLink>
  );
}

export default memo(PageModalLink);
