import { CANCEL_BILL } from '../BillConstants';

// cancelBill

export default function cancelBill(id, options = {}, cb) {
  return dispatch => dispatch({
    type: CANCEL_BILL,
    id,
    options,
    cb
  });
}
