import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import pure from 'recompose/pure';
import get from 'lodash/get';

import { Button } from '../../helpers';
import { translate as t } from '../../utils';

function ModalHelper({
  opened, fetched, form, disabled, isLoading, autoFocus, withoutToggle, withoutCancel, withoutSubmit, withoutFooter,
  withRemove, modalIcon, modalTitle, modalSize, cancelAddClass, submitTooltip, submitAddClass, submitColor, submitIcon,
  submitI18nText, submitText, cancelI18nText, cancelText, submitDisabled, handleEnter, handleExit, handleOpened,
  handleClosed, toggleOpened, hideModal, renderSubmit, handleSubmit, handleSave, children, removeAddClass, removeText,
  removeI18nText, handleRemove, backdrop
}) {
  return (
    <Modal
      backdrop={backdrop}
      isOpen={opened}
      size={modalSize}
      toggle={withoutToggle ? null : toggleOpened}
      autoFocus={autoFocus}
      onEnter={handleEnter}
      onExit={handleExit}
      onOpened={handleOpened}
      onClosed={handleClosed}
    >
      <ModalHeader toggle={toggleOpened}>
        <div className="modal-title text-muted font-size-base">
          <i className={modalIcon} />
          {modalTitle}
        </div>
      </ModalHeader>
      <ModalBody>
        {children}
      </ModalBody>
      {renderSubmit ? (
        <ModalFooter className="card-footer py-2 justify-content-between border-top">
          <div>
            {withRemove ? (
              <Button addClass={removeAddClass} disabled={disabled} size="sm" onClick={handleRemove}>
                {(removeI18nText && t(removeI18nText)) || removeText || t('words.remove')}
              </Button>
            ) : null}
          </div>
          <div>
            {withoutCancel ? null : (
              <Button addClass={cancelAddClass} disabled={disabled} size="sm" onClick={toggleOpened}>
                {(cancelI18nText && t(cancelI18nText)) || cancelText || t('words.cancel')}
              </Button>
            )}
            {renderSubmit({ hideModal })}
          </div>
        </ModalFooter>
      ) : null}
      {fetched && form && !withoutSubmit ? (
        <ModalFooter className="card-footer py-2 justify-content-between border-top">
          <div>
            {withRemove ? (
              <Button addClass={removeAddClass} disabled={disabled} size="sm" onClick={handleRemove}>
                {(removeI18nText && t(removeI18nText)) || removeText || t('words.remove')}
              </Button>
            ) : null}
          </div>
          <div>
            {withoutCancel ? null : (
              <Button addClass={cancelAddClass} disabled={disabled} size="sm" onClick={toggleOpened}>
                {(cancelI18nText && t(cancelI18nText)) || cancelText || t('words.cancel')}
              </Button>
            )}
            {withoutSubmit ? null : (
              <Button
                id={get(submitTooltip, 'target')}
                addClass={submitAddClass}
                disabled={disabled || submitDisabled}
                size="sm"
                color={submitColor}
                icon={submitIcon}
                tooltip={submitTooltip}
                onClick={handleSubmit}
              >
                {isLoading ? t('words.processing') : (submitI18nText && t(submitI18nText)) || submitText || t('words.save')}
              </Button>
            )}
          </div>
        </ModalFooter>
      ) : null}
      {withoutFooter ? null : (
        <ModalFooter className="card-footer py-2 justify-content-between border-top">
          <div>
            {withRemove ? (
              <Button addClass={removeAddClass} disabled={disabled} size="sm" onClick={handleRemove}>
                {(removeI18nText && t(removeI18nText)) || removeText || t('words.remove')}
              </Button>
            ) : null}
          </div>
          <div>
            {withoutCancel ? null : (
              <Button addClass={cancelAddClass} disabled={submitDisabled} size="sm" onClick={toggleOpened}>
                {(cancelI18nText && t(cancelI18nText)) || cancelText || t('words.cancel')}
              </Button>
            )}
            <Button
              id={get(submitTooltip, 'target')}
              addClass={submitAddClass}
              disabled={submitDisabled}
              size="sm"
              color={submitColor}
              icon={submitIcon}
              tooltip={submitTooltip}
              onClick={handleSave}
            >
              {isLoading ? t('words.processing') : (submitI18nText && t(submitI18nText)) || submitText || t('words.save')}
            </Button>
          </div>
        </ModalFooter>
      )}
    </Modal>
  );
}

ModalHelper.propTypes = {
  backdrop: PropTypes.bool,
  opened: PropTypes.bool.isRequired,
  modalTitle: PropTypes.node,
  modalIcon: PropTypes.string,
  modalSize: PropTypes.string,
  form: PropTypes.string,
  withoutFooter: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  withoutToggle: PropTypes.bool,
  withoutCancel: PropTypes.bool,
  withoutSubmit: PropTypes.bool,
  withRemove: PropTypes.bool,
  cancelAddClass: PropTypes.string,
  removeAddClass: PropTypes.string,
  submitColor: PropTypes.string,
  submitIcon: PropTypes.string,
  submitAddClass: PropTypes.string,
  submitTooltip: PropTypes.string,
  submitI18nText: PropTypes.string,
  submitText: PropTypes.node,
  cancelI18nText: PropTypes.string,
  cancelText: PropTypes.node,
  removeI18nText: PropTypes.string,
  removeText: PropTypes.node,
  disabled: PropTypes.bool,
  fetched: PropTypes.bool,
  isLoading: PropTypes.bool,
  autoFocus: PropTypes.bool,
  toggleOpened: PropTypes.func,
  tooltip: PropTypes.shape({
    target: PropTypes.string.isRequired,
    value: PropTypes.node.isRequired,
    placement: PropTypes.string
  }),
  handleEnter: PropTypes.func.isRequired,
  handleExit: PropTypes.func.isRequired,
  handleOpened: PropTypes.func.isRequired,
  handleClosed: PropTypes.func.isRequired,
  hideModal: PropTypes.func,
  renderSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleSave: PropTypes.func,
  handleRemove: PropTypes.func
};

ModalHelper.defaultProps = {
  backdrop: true,
  modalTitle: null,
  modalIcon: null,
  modalSize: 'sm',
  disabled: false,
  fetched: true,
  isLoading: false,
  autoFocus: false,
  form: null,
  withoutFooter: false,
  withoutToggle: false,
  withoutCancel: false,
  withoutSubmit: false,
  withRemove: false,
  submitAddClass: null,
  submitDisabled: null,
  cancelAddClass: null,
  removeAddClass: null,
  submitTooltip: null,
  submitColor: 'primary',
  submitIcon: null,
  submitI18nText: null,
  submitText: null,
  cancelI18nText: null,
  cancelText: null,
  removeI18nText: null,
  removeText: null,
  renderSubmit: null,
  tooltip: null,
  hideModal: null,
  toggleOpened: null,
  handleSubmit: null,
  handleSave: null,
  handleRemove: null
};

export default compose(
  withHandlers({
    handleEnter: ({ onEnter }) => () => onEnter && onEnter(),
    handleOpened: ({ onOpened }) => () => onOpened && onOpened(),
    handleClosed: ({ onClosed }) => () => onClosed && onClosed(),
    handleExit: ({ onExit }) => () => onExit && onExit()
  }),
  pure
)(ModalHelper);
