import React, { PureComponent, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import get from 'lodash/get';
import Favicon from 'react-favicon';

import { parseReferer } from '../../../utils/parseReferer';

import { Header } from '../../components';
import { Footer } from '../../components/ts/Footer';
import { CozyHeader } from '../../components/CozyHeader';

import { auth } from '../../routes/auth';
import { LoadableWrapper } from '../../../utils/LoadableWrapper';
import { isUserCozy } from '../../../utils/ts/isUserCozy';
import { CozyStorage } from '../../../utils/ts/CozyStorage';
import { COZY_FAVICON_URL, MAIN_FAVICON_URL } from '../../../config';

const ErrorPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "errors" */'../../components/ErrorPage/ErrorPage') });

class AuthAppContainer extends PureComponent {
  componentDidMount() {
    const referer = parseReferer(get(this.props, 'location.search'));

    const pathName = get(this.props, 'location.pathname');

    if (referer === 'cozymakeover.com' || /auth\/cozy/.test(pathName)) {
      CozyStorage.setIsUserCozy(true);
    }
  }

  render() {
    const referer = parseReferer(get(this.props, 'location.search'));
    const isCozy = referer === 'cozymakeover.com' || isUserCozy();

    return (
      <Fragment>
        {isCozy ? (
          <Fragment>
            <Favicon url={COZY_FAVICON_URL} />
            <CozyHeader auth />
          </Fragment>
        ) : (
          <Fragment>
            <Favicon url={MAIN_FAVICON_URL} />
            <Header auth />
          </Fragment>
        )}
        <div className="page-content">
          <div className="content-wrapper">
            <div className="content d-flex justify-content-center align-items-center">
              <Switch>
                {auth}
                <Route key="error" render={props => <ErrorPage statusCode={404} {...props} />} />
              </Switch>
            </div>
            <Footer />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AuthAppContainer;
