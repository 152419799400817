export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';

export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_START = 'UPDATE_PASSWORD_START';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const DELETE_ACCOUNT_START = 'DELETE_ACCOUNT_START';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_ERROR = 'DELETE_ACCOUNT_ERROR';

export const BLOCK_USER_ACCOUNT = 'BLOCK_USER_ACCOUNT';
export const BLOCK_USER_ACCOUNT_START = 'BLOCK_USER_ACCOUNT_START';
export const BLOCK_USER_ACCOUNT_SUCCESS = 'BLOCK_USER_ACCOUNT_SUCCESS';
export const BLOCK_USER_ACCOUNT_ERROR = 'BLOCK_USER_ACCOUNT_ERROR';

export const UNBLOCK_USER_ACCOUNT = 'UNBLOCK_USER_ACCOUNT';
export const UNBLOCK_USER_ACCOUNT_START = 'UNBLOCK_USER_ACCOUNT_START';
export const UNBLOCK_USER_ACCOUNT_SUCCESS = 'UNBLOCK_USER_ACCOUNT_SUCCESS';
export const UNBLOCK_USER_ACCOUNT_ERROR = 'UNBLOCK_USER_ACCOUNT_ERROR';

export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const UPDATE_USER_PASSWORD_START = 'UPDATE_USER_PASSWORD_START';
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS';
export const UPDATE_USER_PASSWORD_ERROR = 'UPDATE_USER_PASSWORD_ERROR';

export const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL';
export const UPDATE_USER_EMAIL_START = 'UPDATE_USER_EMAIL_START';
export const UPDATE_USER_EMAIL_SUCCESS = 'UPDATE_USER_EMAIL_SUCCESS';
export const UPDATE_USER_EMAIL_ERROR = 'UPDATE_USER_EMAIL_ERROR';

export const DELETE_USER_ACCOUNT = 'DELETE_USER_ACCOUNT';
export const DELETE_USER_ACCOUNT_START = 'DELETE_USER_ACCOUNT_START';
export const DELETE_USER_ACCOUNT_SUCCESS = 'DELETE_USER_ACCOUNT_SUCCESS';
export const DELETE_USER_ACCOUNT_ERROR = 'DELETE_USER_ACCOUNT_ERROR';

export const TOGGLE_SELECTED_USER = 'TOGGLE_SELECTED_USER';
export const TOGGLE_SELECTED_USER_START = 'TOGGLE_SELECTED_USER_START';
export const TOGGLE_SELECTED_USER_SUCCESS = 'TOGGLE_SELECTED_USER_SUCCESS';
export const TOGGLE_SELECTED_USER_ERROR = 'TOGGLE_SELECTED_USER_ERROR';

export const BLOCK_USERS = 'BLOCK_USERS';
export const BLOCK_USERS_START = 'BLOCK_USERS_START';
export const BLOCK_USERS_SUCCESS = 'BLOCK_USERS_SUCCESS';
export const BLOCK_USERS_ERROR = 'BLOCK_USERS_ERROR';

export const UNBLOCK_USERS = 'UNBLOCK_USERS';
export const UNBLOCK_USERS_START = 'UNBLOCK_USERS_START';
export const UNBLOCK_USERS_SUCCESS = 'UNBLOCK_USERS_SUCCESS';
export const UNBLOCK_USERS_ERROR = 'UNBLOCK_USERS_ERROR';

export const OVERWATCH_USERS = 'OVERWATCH_USERS';
export const OVERWATCH_USERS_START = 'OVERWATCH_USERS_START';
export const OVERWATCH_USERS_SUCCESS = 'OVERWATCH_USERS_SUCCESS';
export const OVERWATCH_USERS_ERROR = 'OVERWATCH_USERS_ERROR';

export const CANCEL_OVERWATCH_USERS = 'CANCEL_OVERWATCH_USERS';
export const CANCEL_OVERWATCH_USERS_START = 'CANCEL_OVERWATCH_USERS_START';
export const CANCEL_OVERWATCH_USERS_SUCCESS = 'CANCEL_OVERWATCH_USERS_SUCCESS';
export const CANCEL_OVERWATCH_USERS_ERROR = 'CANCEL_OVERWATCH_USERS_ERROR';

export const ADD_USERS_GROUPS = 'ADD_USERS_GROUPS';
export const ADD_USERS_GROUPS_START = 'ADD_USERS_GROUPS_START';
export const ADD_USERS_GROUPS_SUCCESS = 'ADD_USERS_GROUPS_SUCCESS';
export const ADD_USERS_GROUPS_ERROR = 'ADD_USERS_GROUPS_ERROR';

export const DOWNLOAD_USERS_XLS = 'DOWNLOAD_USERS_XLS';
export const DOWNLOAD_USERS_XLS_START = 'DOWNLOAD_USERS_XLS_START';
export const DOWNLOAD_USERS_XLS_SUCCESS = 'DOWNLOAD_USERS_XLS_SUCCESS';
export const DOWNLOAD_USERS_XLS_ERROR = 'DOWNLOAD_USERS_XLS_ERROR';

export const DOWNLOAD_WORKER_DOCUMENTS = 'DOWNLOAD_WORKER_DOCUMENTS';
export const DOWNLOAD_WORKER_DOCUMENTS_START = 'DOWNLOAD_WORKER_DOCUMENTS_START';
export const DOWNLOAD_WORKER_DOCUMENTS_SUCCESS = 'DOWNLOAD_WORKER_DOCUMENTS_SUCCESS';
export const DOWNLOAD_WORKER_DOCUMENTS_ERROR = 'DOWNLOAD_WORKER_DOCUMENTS_ERROR';

export const CHANGE_USERS_SELLER = 'CHANGE_USERS_SELLER';
export const CHANGE_USERS_SELLER_START = 'CHANGE_USERS_SELLER_START';
export const CHANGE_USERS_SELLER_SUCCESS = 'CHANGE_USERS_SELLER_SUCCESS';
export const CHANGE_USERS_SELLER_ERROR = 'CHANGE_USERS_SELLER_ERROR';

export const CHANGE_USERS_UPSELLER = 'CHANGE_USERS_UPSELLER';
export const CHANGE_USERS_UPSELLER_START = 'CHANGE_USERS_UPSELLER_START';
export const CHANGE_USERS_UPSELLER_SUCCESS = 'CHANGE_USERS_UPSELLER_SUCCESS';
export const CHANGE_USERS_UPSELLER_ERROR = 'CHANGE_USERS_UPSELLER_ERROR';

export const SOCKET_SUBSCRIBE_TO_USERS_CHANNEL = 'SOCKET_SUBSCRIBE_TO_USERS_CHANNEL';
export const SOCKET_UNSUBSCRIBE_FROM_USERS_CHANNEL = 'SOCKET_UNSUBSCRIBE_FROM_USERS_CHANNEL';

export const CLEAR_USER_FILTERS_START = 'CLEAR_USER_FILTERS_START';
export const CLEAR_USER_FILTERS_SUCCESS = 'CLEAR_USER_FILTERS_SUCCESS';
export const CLEAR_USER_MODAL_VALUES = 'CLEAR_USER_MODAL_VALUES';

export const USER_FILE_ATTACHMENTS_FORM = 'USER_FILE_ATTACHMENTS_FORM';
export const USER_SELECTED_PRODUCT_UPDATE_FORM = 'USER_SELECTED_PRODUCT_UPDATE_FORM';
export const USER_SELECTED_PRODUCTS_FORM = 'USER_SELECTED_PRODUCTS_FORM';
export const USER_SELECTED_TEMPLATE_UPDATE_FORM = 'USER_SELECTED_TEMPLATE_UPDATE_FORM';
export const USER_SELECTED_TEMPLATES_FORM = 'USER_SELECTED_TEMPLATES_FORM';
export const SUBMIT_PROJECT_PROFILE_FILES_FORM = 'SUBMIT_PROJECT_PROFILE_FILES_FORM';

export const USER_PROJECT_PROFILE_DUPLICATE_SELECTED_PRODUCTS_FORM = 'USER_PROJECT_PROFILE_DUPLICATE_SELECTED_PRODUCTS_FORM';
export const USER_PROJECT_PROFILE_DUPLICATE_SELECTED_TEMPLATES_FORM = 'USER_PROJECT_PROFILE_DUPLICATE_SELECTED_TEMPLATES_FORM';
export const USER_PROJECT_PROFILE_DUPLICATE_FILES_FORM = 'USER_PROJECT_PROFILE_DUPLICATE_FILES_FORM';

export const USERS_LIST_TABLE_FORM = 'USERS_LIST_TABLE_FORM';
export const CHANGE_USER_AVATAR_FORM = 'CHANGE_USER_AVATAR_FORM';
export const CHANGE_USERS_GROUPS_FORM = 'CHANGE_USERS_GROUPS_FORM';
export const CHANGE_USERS_SELLER_FORM = 'CHANGE_USERS_SELLER_FORM';
export const CHANGE_USERS_UPSELLER_FORM = 'CHANGE_USERS_UPSELLER_FORM';

export const REGISTER_WORKER_SECOND_STAGE_FORM = 'REGISTER_WORKER_SECOND_STAGE_FORM';
