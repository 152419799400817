import { createReducer } from 'redux-create-reducer';

import { initProjectsFilters } from './common';
import {
  defaultState,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError,
  fetchItemStart, fetchItemSuccess, fetchItemError,
  createItemStart, createItemSuccess, createItemError,
  updateItemStart, updateItemSuccess, updateItemError,
  clearItemsFiltersStart, clearItemsFiltersSuccess,
  clearModalValues, itemsChannelUpdated, locationChange
} from '../../common/reducers';

import {
  toggleSelectedProjectStart,
  toggleSelectedProjectSuccess,
  toggleSelectedProjectError
} from './toggleSelectedProject';

import {
  acceptInviteToProjectStart,
  acceptInviteToProjectSuccess,
  acceptInviteToProjectError
} from './acceptInviteToProject';

import {
  inviteUsersToProjectStart,
  inviteUsersToProjectSuccess,
  inviteUsersToProjectError
} from './inviteUsersToProject';

import {
  removeUsersFromProjectStart,
  removeUsersFromProjectSuccess,
  removeUsersFromProjectError
} from './removeUsersFromProject';

import {
  createProjectBillsStart,
  createProjectBillsSuccess,
  createProjectBillsError
} from './createProjectBills';

import {
  createProjectsBillsStart,
  createProjectsBillsSuccess,
  createProjectsBillsError
} from './createProjectsBills';

import {
  updateProjectsAmountsStart,
  updateProjectsAmountsSuccess,
  updateProjectsAmountsError
} from './updateProjectsAmounts';

import {
  submitProjectFileAttachmentsError,
  submitProjectFileAttachmentsStart,
  submitProjectFileAttachmentsSuccess
} from './submitProjectFileAttachments';

import {
  submitProjectSelectedProductsError,
  submitProjectSelectedProductsStart,
  submitProjectSelectedProductsSuccess
} from './submitProjectSelectedProducts';

import {
  submitProjectSelectedTemplatesError,
  submitProjectSelectedTemplatesStart,
  submitProjectSelectedTemplatesSuccess
} from './submitProjectSelectedTemplates';

import {
  toggleProjectNotificationsError,
  toggleProjectNotificationsStart,
  toggleProjectNotificationsSuccess
} from './toggleProjectNotifications';

const defaultProjectsState = {
  ...defaultState,

  statuses: [
    'definition',
    'implementation',
    'qa',
    'incomplete_specification',
    // 'payment_waiting',
    // 'paid',
    'acceptance',
    'performed',
    'canceled'
  ],

  billTypes: [
    // 'rietumu',
    // 'payoneer',
    '2checkout',
    'fondy',
    'stripe',
    'easypay',
    // 'bank_account_andrey',
    'system',
    // 'amount',
    // 'cancel_amount',
    'debit_account',
    'cancel_debit_account',
    'bonus',
    'cancel_bonus'
    // 'cancel_payment'
  ]
};

export default createReducer(defaultProjectsState, {
  '@@router/LOCATION_CHANGE': locationChange(defaultProjectsState),
  AUTH_USER: initProjectsFilters,

  FETCH_PROJECTS_START: fetchItemsStart,
  FETCH_PROJECTS_SUCCESS: fetchItemsSuccess,
  FETCH_PROJECTS_ERROR: fetchItemsError,

  FETCH_PROJECT_START: fetchItemStart,
  FETCH_PROJECT_SUCCESS: fetchItemSuccess,
  FETCH_PROJECT_ERROR: fetchItemError,

  CREATE_PROJECT_START: createItemStart,
  CREATE_PROJECT_SUCCESS: createItemSuccess,
  CREATE_PROJECT_ERROR: createItemError,

  UPDATE_PROJECT_START: updateItemStart,
  UPDATE_PROJECT_SUCCESS: updateItemSuccess,
  UPDATE_PROJECT_ERROR: updateItemError,

  TOGGLE_SELECTED_PROJECT_START: toggleSelectedProjectStart,
  TOGGLE_SELECTED_PROJECT_SUCCESS: toggleSelectedProjectSuccess,
  TOGGLE_SELECTED_PROJECT_ERROR: toggleSelectedProjectError,

  ACCEPT_INVITE_TO_PROJECT_START: acceptInviteToProjectStart,
  ACCEPT_INVITE_TO_PROJECT_SUCCESS: acceptInviteToProjectSuccess,
  ACCEPT_INVITE_TO_PROJECT_ERROR: acceptInviteToProjectError,

  INVITE_USERS_TO_PROJECT_START: inviteUsersToProjectStart,
  INVITE_USERS_TO_PROJECT_SUCCESS: inviteUsersToProjectSuccess,
  INVITE_USERS_TO_PROJECT_ERROR: inviteUsersToProjectError,

  REMOVE_USERS_FROM_PROJECT_START: removeUsersFromProjectStart,
  REMOVE_USERS_FROM_PROJECT_SUCCESS: removeUsersFromProjectSuccess,
  REMOVE_USERS_FROM_PROJECT_ERROR: removeUsersFromProjectError,

  CLEAR_PROJECT_FILTERS_START: clearItemsFiltersStart,
  CLEAR_PROJECT_FILTERS_SUCCESS: clearItemsFiltersSuccess,

  CLEAR_PROJECT_MODAL_VALUES: clearModalValues,

  PROJECTS_CHANNEL_UPDATED: itemsChannelUpdated,

  CREATE_PROJECT_BILLS_START: createProjectBillsStart,
  CREATE_PROJECT_BILLS_SUCCESS: createProjectBillsSuccess,
  CREATE_PROJECT_BILLS_ERROR: createProjectBillsError,

  CREATE_PROJECTS_BILLS_START: createProjectsBillsStart,
  CREATE_PROJECTS_BILLS_SUCCESS: createProjectsBillsSuccess,
  CREATE_PROJECTS_BILLS_ERROR: createProjectsBillsError,

  UPDATE_PROJECTS_AMOUNTS_START: updateProjectsAmountsStart,
  UPDATE_PROJECTS_AMOUNTS_SUCCESS: updateProjectsAmountsSuccess,
  UPDATE_PROJECTS_AMOUNTS_ERROR: updateProjectsAmountsError,

  SUBMIT_PROJECT_FILE_ATTACHMENTS_START: submitProjectFileAttachmentsStart,
  SUBMIT_PROJECT_FILE_ATTACHMENTS_SUCCESS: submitProjectFileAttachmentsSuccess,
  SUBMIT_PROJECT_FILE_ATTACHMENTS_ERROR: submitProjectFileAttachmentsError,

  SUBMIT_PROJECT_SELECTED_PRODUCTS_START: submitProjectSelectedProductsStart,
  SUBMIT_PROJECT_SELECTED_PRODUCTS_SUCCESS: submitProjectSelectedProductsSuccess,
  SUBMIT_PROJECT_SELECTED_PRODUCTS_ERROR: submitProjectSelectedProductsError,

  SUBMIT_PROJECT_SELECTED_TEMPLATES_START: submitProjectSelectedTemplatesStart,
  SUBMIT_PROJECT_SELECTED_TEMPLATES_SUCCESS: submitProjectSelectedTemplatesSuccess,
  SUBMIT_PROJECT_SELECTED_TEMPLATES_ERROR: submitProjectSelectedTemplatesError,

  TOGGLE_PROJECT_NOTIFICATIONS_START: toggleProjectNotificationsStart,
  TOGGLE_PROJECT_NOTIFICATIONS_SUCCESS: toggleProjectNotificationsSuccess,
  TOGGLE_PROJECT_NOTIFICATIONS_ERROR: toggleProjectNotificationsError
});
