import users from './users';

export default {
  action: 'Action',
  add_comment: 'Add comment...',
  additional_email1: 'Additional email #1',
  additional_email2: 'Additional email #2',
  additional_information: 'Enter additional information here...',
  address: 'Address',
  amount: 'Amount',
  android: 'Android',
  aws: 'AWS',
  balance_amount: 'Old Account',
  bill: 'Bill',
  blocked: 'Blocked',
  body_en: 'Body (En)',
  body: 'Body',
  bonus_account: 'Bonus Account',
  category_id: 'Сategory',
  chief_id: 'Master user',
  city: 'City',
  client_status: 'Client status',
  last_client_status_updated_at: 'Client status updated',
  co: 'CO',
  comment: 'Comments',
  commission: 'Commission',
  cost: 'Cost',
  country_id: 'Country',
  coworking: 'Coworking',
  created_at: 'Created',
  created: 'Created',
  credit_account: 'Credit Account',
  reducer_commission: 'Reducer Commission',
  task_commission: 'Project Commission',
  credit_commission: 'Credit commission',
  hosting_commission: 'Hosting commission',
  currency: 'Preferable currency',
  current_cost: 'Current cost',
  current_password: 'Current password',
  danger: 'Danger',
  date_format: 'Date format',
  date: 'Date',
  debit_account: 'Debit Account',
  debt_limit: 'Debt limit',
  debt: 'Debt',
  description: 'Description',
  direction: 'Direction',
  discount: 'Discount',
  discussion_account: 'Task account',
  due_date: 'Due date',
  email_confirmation: 'Confirm email',
  email: 'Email',
  end_date: 'End Date',
  end_time: 'End Time',
  endDatePlaceholderText: ' To',
  enter_estimate_date: 'Enter estimate date',
  enter_estimate_cost: 'Enter estimate cost',
  enter_implementation_date: 'Enter estimate date',
  estimate_cost: 'Estimate cost',
  estimate_date: 'Estimate date',
  implemented_at: 'Implemented',
  facebook: 'Facebook',
  factor: 'Factor',
  file: 'File',
  finance_role_id: 'Finance role',
  finance_role: 'Finance Role',
  finance_tags: 'Finance tags',
  first_name_en: 'First name (En)',
  first_name_uk: 'First name (Uk)',
  first_name: 'First name',
  first_payment_date: 'First Payment Date',
  flagged: 'Flagged',
  followers: 'Followers',
  frontend: 'Frontend',
  full_name: 'Full Name',
  google_plus: 'Google+',
  group_ids: 'Groups',
  groups: 'Groups',
  hold_rate: 'Hold rate',
  id: 'ID',
  image: 'Image',
  implementation_date: 'Estimate date',
  only_in_projects_where_i_am_a_member: 'Only in projects where I am a member',
  only_in_tasks_where_i_am_a_member: 'Only in tasks where I am a member',
  instagram: 'Instagram',
  inTrash: 'In trash',
  itn: 'ITN',
  ios: 'iOS',
  key: 'Key',
  last_payment_date: 'Last Payment Date',
  client_sigma_payments: 'Σ Payments',
  sigma_task_account: 'Σ TA',
  rate_education_point: 'K edp',
  wildly_important_goals_count: 'WIGs',
  reason_of_fail: 'Reason of fail',
  turn_around_time: '∑ TAT',
  paid_turn_around_time: '∑ Paid TAT',
  client_rate_median: 'K med',
  client_rate_average: 'K avr',
  worker_rate_median: 'K med',
  worker_rate_average: 'K avr',
  user_rate_median: 'K med',
  user_rate_average: 'K avr',
  last_name_en: 'Last name (En)',
  last_name_uk: 'Last name (Uk)',
  last_name: 'Last name',
  last_sign_in_at: 'Last sign in',
  leaders: 'Leaders',
  linked_in: 'LinkedIn',
  locale: 'Language',
  members: 'Members',
  multiplier: 'Multiplier',
  name_en: 'Name (En)',
  name_uk: 'Name (Uk)',
  name: 'Name',
  nda: 'NDA',
  negative_reviews: 'Negative reviews',
  new_email: 'New email',
  new_password: 'New password',
  overwatch: 'Overwatch',
  parent_category: 'Parent category',
  passport_first_page: 'Passport (1-st page)',
  passport_second_page: 'Passport (2-nd page)',
  passport_number: 'Passport series and number',
  password_confirmation: 'Password Confirmation',
  password: 'Password',
  pay_time: 'Pay Time',
  payment_type: 'Payment type',
  payment: 'Payment',
  payoneer_id_pe: 'Payoneer ID PE',
  payoneer_id_type: 'Payoneer ID type',
  payoneer_id: 'Payoneer ID',
  permission_type: 'Type',
  permissions_count: 'Permissions',
  phone: 'Phone',
  pinterest: 'Pinterest',
  positive_reviews: 'Positive reviews',
  prepaid_balance_amount: 'Prepaid Account',
  prepayment: 'Prepayment',
  price: 'Price',
  progress: 'Progress',
  project_account: 'Project account',
  project_managers: 'Project managers',
  rating_corrected_manually: 'Use custom rating',
  rating: 'Rating',
  ratio: 'Ratio',
  red: 'Visible for Worker',
  reducer: 'Reducer',
  referer: 'Referrer',
  registration_date: 'Registration date',
  result_sum: 'Result sum',
  role_ids: 'Roles',
  roles: 'Roles',
  search_by_email: 'Search by email',
  search_by_message_body: 'Search by message body',
  search_by_name: 'Search by name',
  search_by_product_name: 'Search by product name',
  search_by_project_name: 'Search by project name',
  search_by_task_name: 'Search by task name',
  search_by_template_name: 'Search by template name',
  search_by_user_name: 'Search by user name',
  search: 'Search...',
  selected: 'Favorite',
  seller_id: 'Sales Manager',
  seller: 'Sales Manager',
  set_date: 'Created',
  site: 'Website',
  size: 'Size',
  skype: 'Skype',
  social_networks: 'Other',
  source_files_count: 'Source files',
  source_id: 'Source',
  start_date: 'Start Date',
  start_time: 'Start Time',
  startDatePlaceholderText: 'From',
  status: 'Status',
  studio: 'Studio',
  subject_en: 'Subject (En)',
  subject: 'Subject',
  sum_for_allocation: 'Sum for allocation',
  surname_en: 'Surname (En)',
  surname: 'Surname',
  tag_ids: 'Tags',
  tags: 'Tags',
  task_account: 'Project account',
  tasks: 'Tasks',
  time_format: 'Time format',
  time_zone: 'Time zone',
  time: 'Time',
  transaction_description: 'Transaction description',
  transaction_id: 'Transaction ID',
  transaction_tag_ids: 'Transaction tags',
  tumblr: 'Tumblr',
  twitter: 'Twitter',
  type: 'Type',
  updated_at: 'Updated',
  updated: 'Updated',
  upsaler_id: 'Client Manager',
  upsaler: 'Client Manager',
  user_balance: 'Balance',
  user_id: 'User ID',
  user_name: 'Name',
  user_rating: 'Ratio',
  user: 'User',
  users_count: 'Users',
  visible_for_client: 'Visible for Client',
  visible_for_clients: 'Visible for Clients',
  with_attachments: 'With attachments',
  your_email: 'Your Email',
  your_phone: 'Your phone',
  code: 'Code',
  value: 'Value',
  ip: 'IP',

  filter_parts: 'Filter parts',
  categories_count: 'Categories',
  order: 'Order',
  pdf_export: 'PDF export',

  expected_payment_date: 'Expected payment date',
  expected_payment_amount: 'Expected payment amount',

  image_id: 'Image',
  source: 'Source',
  sku: 'SKU',

  users
};
