export default {
  add: 'Add user',
  block: 'Block user',
  blocked_singular: 'Blocked user',
  createUser: 'Create user',
  delete_account: 'Delete account',
  deleteUser: 'Delete user',
  editUser: 'Edit user',
  invite: 'Invite user',
  plural: 'Users',
  select_user: 'Select user...',
  selected_users: 'Selected users',
  singular: 'User',
  updateUser: 'Update user',
  user: 'User',
  userCreatedSuccess: 'User successfully created.',
  userDeletedSuccess: 'User successfully deleted.',
  userInfo: 'User Info',
  usersCount: 'Users {count}',
  userUpdatedSuccess: 'User successfully updated.',
  statistics: {
    discussions_role_debt: 'Underpayment',
    turn_around_time: 'Σ TAT',
    paid_turn_around_time: 'Σ Paid TAT',
    average_discussions_mark: 'Average mark',
    payment_bills_amount: 'Unpaid Bills',
    total_amount: 'Total amount',
    client_wasted_seconds: 'Tasks tracked time',
    discussions_account_receivable: 'Tasks AR',
    client_status: 'Client status',
    worker_rate_median: 'K med',
    worker_rate_average: 'K avr',
    client_rate_median: 'K med',
    client_rate_average: 'K avr',
    rate_education_point: 'K edp',
    wildly_important_goals_count: 'WIGs',
    reason_of_fail: 'Reason of fail',
    hold_rate: 'Hold rate'
  },
  statuses: {
    new: 'New',
    active: 'Active',
    paused: 'Paused',
    declined: 'Declined',
    no_payments: 'No payments'
  }
};
