import React, { memo, useState, useCallback } from 'react';
import cl from 'classnames';

import { AdminLinkMenu } from './components/AdminLinkMenu';
import { AdminUserMenu } from './components/AdminUserMenu';
import { PureButton } from '../../../../helpers/ts/PureButton';
import { LinkTo } from '../../../../helpers/ts/LinkTo';
import { PureLinkTo } from '../../../../helpers/ts/PureLinkTo';

interface AdminHeaderProps {
  onToggleSidebarHidden: () => void;
  onToggleSidebarMobile: () => void;
  onLogout: () => void;
}

function AdminHeader({
  onToggleSidebarHidden,
  onToggleSidebarMobile,
  onLogout
}: AdminHeaderProps) {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggleOpen = useCallback(() => setIsOpen(value => !value), []);

  return (
    <div className="navbar navbar-expand-md navbar-dark bg-indigo navbar-static">
      <div className="navbar-brand wmin-0 p-0 mr-2">
        <LinkTo href="" className="d-inline-block" title="Archivizer.com">
          Archivizer.com
          <svg id="av-logo" width="50" height="50">
            <g fillRule="evenodd" clipRule="evenodd" fill="#fff">
              <path d="M11.077,36.786c-0.061,0.396-0.403,0.673-0.799,0.665l-3.546,0 c-0.323,0-0.595-0.245-0.628-0.566c-0.003-0.023-0.005-0.047-0.003-0.07c0-0.066,0.011-0.129,0.03-0.188l5.444-20.388 c0.579-2.168,2.537-3.692,4.789-3.692c2.252,0,4.21,1.524,4.789,3.692l5.443,20.384c0.021,0.063,0.031,0.126,0.031,0.192 c0.002,0.023,0,0.047-0.003,0.07c-0.033,0.321-0.305,0.568-0.629,0.566l-3.543,0c-0.399,0.008-0.741-0.269-0.802-0.665 L16.83,17.491c-0.004-0.012-0.007-0.023-0.012-0.035c-0.003-0.009-0.005-0.017-0.007-0.026c-0.034-0.221-0.224-0.384-0.448-0.384 c-0.223,0-0.414,0.163-0.448,0.384c-0.001,0.009-0.004,0.017-0.007,0.026c-0.004,0.012-0.008,0.023-0.011,0.032L11.077,36.786z" />
              <path d="M28.349,13.214c-0.061-0.396-0.403-0.673-0.799-0.665l-3.546,0 c-0.323,0-0.595,0.245-0.628,0.566c-0.004,0.023-0.005,0.047-0.003,0.07c0,0.066,0.011,0.129,0.03,0.188l5.444,20.388 c0.579,2.168,2.537,3.692,4.789,3.692c2.252,0,4.21-1.524,4.789-3.692l5.443-20.384c0.021-0.063,0.031-0.126,0.031-0.192 c0.002-0.023,0-0.047-0.003-0.07c-0.033-0.321-0.305-0.568-0.629-0.566l-3.543,0c-0.399-0.008-0.741,0.269-0.802,0.665 l-4.821,19.295c-0.004,0.012-0.007,0.023-0.012,0.035c-0.003,0.009-0.005,0.017-0.007,0.026c-0.034,0.221-0.224,0.384-0.448,0.384 c-0.223,0-0.414-0.163-0.448-0.384c-0.001-0.009-0.004-0.017-0.007-0.026c-0.004-0.012-0.008-0.023-0.011-0.032L28.349,13.214z" />
            </g>
          </svg>
        </LinkTo>
      </div>

      <div className="d-md-none">
        <PureButton
          className="navbar-toggler"
          onClick={handleToggleOpen}
          icon="icon-paragraph-justify3"
        />
        <PureButton
          className="navbar-toggler sidebar-mobile-component-toggle"
          onClick={onToggleSidebarMobile}
          icon="icon-unfold"
        />
      </div>

      <div className={cl('collapse navbar-collapse', { show: isOpen })}>
        <AdminLinkMenu />
        <ul className="navbar-nav ml-md-auto">
          <li className="nav-item">
            <PureLinkTo
              pureLink
              href="#"
              className="navbar-nav-link sidebar-control sidebar-component-toggle d-none d-md-block"
              icon="icon-transmission"
              onClick={onToggleSidebarHidden}
            />
          </li>
          <AdminUserMenu onLogout={onLogout} />
        </ul>
      </div>
    </div>
  );
}

export default memo(AdminHeader);
