import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import cl from 'classnames';
import compose from 'recompose/compose';
import pure from 'recompose/pure';
import withHandlers from 'recompose/withHandlers';
import withState from 'recompose/withState';
import trimStart from 'lodash/trimStart';

import { PureLinkTo } from '../../helpers/ts/PureLinkTo';
import { PureButton } from '../../helpers/ts/PureButton';

function LinkMenuNestedDropdown({
  links: menuItems,
  toggle,
  dropdownState,
  collapse,
  pathname,
  linkPrefix
}) {
  return (
    <OutsideClickHandler onOutsideClick={collapse}>
      {menuItems.links && menuItems.links.length ? (
        <div className={cl('dropleft', { show: dropdownState })}>
          <PureButton
            className={cl('dropdown-item dropdown-toggle pr-1', {
              active: new RegExp(
                `^${linkPrefix}(${menuItems.namespace})$`
              ).test(trimStart(pathname, '/'))
            })}
            aria-expanded={dropdownState}
            onClick={toggle}
            i18nText={menuItems.label}
          />
          <div className={cl('dropdown-menu', { show: dropdownState })}>
            {menuItems.links.map(link => (
              <Fragment key={`link-menu-${link.namespace}-item`}>
                <PureLinkTo
                  className={cl('dropdown-item', {
                    active: new RegExp(`^/${linkPrefix}${link.namespace}`).test(
                      pathname
                    )
                  })}
                  href={link.href}
                  icon={link.icon}
                  i18nText={link.label}
                />
              </Fragment>
            ))}
          </div>
        </div>
      ) : null}
    </OutsideClickHandler>
  );
}

LinkMenuNestedDropdown.propTypes = {
  links: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        ref: PropTypes.string,
        namespace: PropTypes.string,
        label: PropTypes.string
      })
    )
  }).isRequired,
  pathname: PropTypes.string.isRequired,
  dropdownState: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  collapse: PropTypes.func.isRequired,
  linkPrefix: PropTypes.string.isRequired
};

export default compose(
  withState('dropdownState', 'changeDropdownState', false),
  withHandlers({
    toggle: ({ dropdownState, changeDropdownState }) => () =>
      changeDropdownState(!dropdownState),
    collapse: ({ dropdownState, changeDropdownState }) => () =>
      dropdownState && changeDropdownState(false)
  }),
  pure
)(LinkMenuNestedDropdown);
