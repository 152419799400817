import { FETCH_BILLS } from '../BillConstants';

// fetchBills

export default function fetchBills(params, cb) {
  return dispatch => dispatch({
    type: FETCH_BILLS,
    ...params,
    cb
  });
}
