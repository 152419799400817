import React from 'react';
import { Route } from 'react-router-dom';

import { LoadableWrapper } from '../../utils/LoadableWrapper';

const DashboardPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "dashboard" */ './pages/DashboardPage/DashboardPage'
    )
});

export default <Route exact path="/" component={DashboardPage} />;
