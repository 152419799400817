import access from './access';
import accounts from './accounts';
import allocations from './allocations';
import attachments from './attachments';
import balances from './balances';
import batch_operations from './batch_operations';
import bills from './bills';
import billRefills from './bill_refills';
import emailTemplates from './email_templates';
import categories from './categories';
import clients from './clients';
import contacts from './contacts';
import currencies from './currencies';
import downloads from './downloads';
import facilities from './facilities';
import files from './files';
import fileAttachments from './file_attachments';
import finance_tags from './finance_tags';
import groups from './groups';
import images from './images';
import imports from './imports';
import linked_accounts from './linked_accounts';
import messages from './messages';
import notifications from './notifications';
import operations from './operations';
import pages from './pages';
import payments from './payments';
import payouts from './payouts';
import pending_payments from './pending_payments';
import permissions from './permissions';
import products from './products';
import project_profiles from './project_profiles';
import projects from './projects';
import promoCodes from './promo_codes';
import refills from './refills';
import reports from './reports';
import results from './results';
import revenue_share_items from './revenue_share_items';
import roles from './roles';
import rooms from './rooms';
import scenes from './scenes';
import selected_facilities from './selected_facilities';
import selected_products from './selected_products';
import selected_scenes from './selected_scenes';
import selected_templates from './selected_templates';
import sections from './sections';
import source_files from './source_files';
import sentMessages from './sent_messages';
import statuses from './statuses';
import tags from './tags';
import tasks from './tasks';
import templates from './templates';
import textures from './textures';
import todoItems from './todoItems';
import transactions from './transactions';
import transactionTags from './transaction_tags';
import users from './users';
import workers from './workers';

export default {
  access,
  accounts,
  allocations,
  attachments,
  balances,
  batch_operations,
  bills,
  bill_refills: billRefills,
  categories,
  clients,
  contacts,
  currencies,
  downloads,
  email_templates: emailTemplates,
  facilities,
  files,
  file_attachments: fileAttachments,
  finance_tags,
  groups,
  images,
  imports,
  linked_accounts,
  messages,
  notifications,
  operations,
  pages,
  payments,
  payouts,
  pending_payments,
  permissions,
  products,
  project_profiles,
  projects,
  promo_codes: promoCodes,
  refills,
  reports,
  results,
  revenue_share_items,
  roles,
  rooms,
  scenes,
  selected_facilities,
  selected_products,
  selected_scenes,
  selected_templates,
  sections,
  source_files,
  sent_messages: sentMessages,
  statuses,
  tags,
  tasks,
  templates,
  textures,
  todoItems,
  transactions,
  transaction_tags: transactionTags,
  users,
  workers
};
