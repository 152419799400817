import { connect } from 'react-redux';
import { compose, lifecycle, withHandlers } from 'recompose';
import pluralize from 'pluralize';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';

import { withCurrentUser } from '../index';
import { filterKeys, provideActions, provideLifecycle, provideProps } from './index';

function baseHOC(defaultOptions = {}, actions = {}, additionalActions = {}, additionalProps = {}) {
  const scope = get(defaultOptions, 'scope.plural') || defaultOptions.scope;
  const singularScope = get(defaultOptions, 'scope.singular') || pluralize.singular(defaultOptions.scope);
  const { namespace } = defaultOptions;

  const options = {
    ...defaultOptions,
    scope,
    singularScope,
    items: namespace ? `${namespace}${upperFirst(scope)}` : scope,
    item: namespace ? `${namespace}${upperFirst(singularScope)}` : singularScope,
    itemsMeta: namespace ? `${namespace}ItemsMeta` : `${scope}Meta`,
    fetchItems: `fetch${upperFirst(scope)}`,
    loadMoreItems: `loadMore${upperFirst(scope)}`,
    changeItemsFilters: `change${upperFirst(scope)}Filters`,
    clearItemsFilters: `clear${upperFirst(scope)}Filters`,
    clearModalValues: `clear${upperFirst(singularScope)}ModalValues`,
    fetchItem: `fetch${upperFirst(singularScope)}`,
    createItem: `create${upperFirst(singularScope)}`,
    updateItem: `update${upperFirst(singularScope)}`,
    removeItem: `remove${upperFirst(singularScope)}`,
    uploadItems: `upload${upperFirst(scope)}`,
    subscribeToItemsChannel: `subscribeTo${upperFirst(scope)}Channel`,
    unsubscribeFromItemsChannel: `unsubscribeFrom${upperFirst(scope)}Channel`
  };

  return compose(
    connect(state => provideProps(state, options, additionalProps), filterKeys(options.actions, actions)),
    withCurrentUser(),
    withHandlers(provideActions(options, additionalActions)),
    lifecycle(provideLifecycle(options))
  );
}

export default baseHOC;
