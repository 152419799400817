import React, { useCallback } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { PureLinkTo } from '../../../helpers/ts/PureLinkTo';
import { httpCodeToStatusText } from './utils/httpCodeToStatusText';

interface ErrorPageProps extends RouteComponentProps {
  statusCode: number;
  statusText?: string;
}

function ErrorPage({ history, statusCode, statusText }: ErrorPageProps) {
  const errorData = httpCodeToStatusText(statusCode);

  const handleGoHome = useCallback(() => history.push('/'), [history]);

  return (
    <div className="content d-flex justify-content-center align-items-center">
      <div className="flex-fill">
        <div className="text-center mb-4 mb-md-5">
          <h1 className="error-title mb-2">{statusCode || 500}</h1>
          <h5>{errorData.errorText}</h5>
          <p>{statusText || errorData.statusText}</p>
        </div>
        <div className="d-flex justify-content-center align-items-baseline">
          <PureLinkTo
            onClick={handleGoHome}
            className="btn btn-light mt-3 mt-sm-0"
            icon="icon-home4 mr-2"
            i18nText="words.homepage"
          />
        </div>
      </div>
    </div>
  );
}

export default withRouter(ErrorPage);
