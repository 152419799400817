import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError,
  fetchItemStart, fetchItemSuccess, fetchItemError,
  createItemStart, createItemSuccess, createItemError,
  updateItemStart, updateItemSuccess, updateItemError,
  clearItemsFiltersStart, clearItemsFiltersSuccess,
  itemsChannelUpdated, locationChange
} from '../../common/reducers';

import { initTasksFilters } from './common';
import { toggleSelectedTaskError, toggleSelectedTaskStart, toggleSelectedTaskSuccess } from './toggleSelectedTask';
import { createTasksError, createTasksStart, createTasksSuccess } from './createTasks';
import { createTaskBillsError, createTaskBillsStart, createTaskBillsSuccess } from './createTaskBills';
import { createTasksBillsError, createTasksBillsStart, createTasksBillsSuccess } from './createTasksBills';
import { updateTasksAmountsError, updateTasksAmountsStart, updateTasksAmountsSuccess } from './updateTasksAmounts';
import { updateTasksEstimateDatesStart, updateTasksEstimateDatesSuccess, updateTasksEstimateDatesError } from './updateTasksEstimateDates';
import { inviteUsersToTaskError, inviteUsersToTaskStart, inviteUsersToTaskSuccess } from './inviteUsersToTask';
import { inviteUsersToTasksError, inviteUsersToTasksStart, inviteUsersToTasksSuccess } from './inviteUsersToTasks';
import { allocateFundsToTasksError, allocateFundsToTasksStart, allocateFundsToTasksSuccess } from './allocateFundsToTasks';
import { updateTaskProjectEstimateCostError, updateTaskProjectEstimateCostStart, updateTaskProjectEstimateCostSuccess } from './updateTaskProjectEstimateCost';
import { changeTasksVisibilityError, changeTasksVisibilityStart, changeTasksVisibilitySuccess } from './changeTasksVisibility';
import { changeTasksStatusError, changeTasksStatusStart, changeTasksStatusSuccess } from './changeTasksStatus';
import { changeTasksTagsError, changeTasksTagsStart, changeTasksTagsSuccess } from './changeTasksTags';
import { duplicateTasksError, duplicateTasksStart, duplicateTasksSuccess } from './duplicateTasks';
import { acceptInviteToTaskStart, acceptInviteToTaskSuccess, acceptInviteToTaskError } from './acceptInviteToTask';
import { removeUsersFromTaskError, removeUsersFromTaskStart, removeUsersFromTaskSuccess } from './removeUsersFromTask';

import {
  acceptTaskResultsStart,
  acceptTaskResultsSuccess,
  acceptTaskResultsError
} from './acceptTaskResults';

import {
  acceptTasksResultsStart,
  acceptTasksResultsSuccess,
  acceptTasksResultsError
} from './acceptTasksResults';

import {
  approveTaskResultsStart,
  approveTaskResultsSuccess,
  approveTaskResultsError
} from './approveTaskResults';

import {
  revisionTaskStart,
  revisionTaskSuccess,
  revisionTaskError
} from './revisionTask';

import { clearTaskModalValues } from './clearTaskModalValues';

import {
  updateTaskFileAttachmentsError,
  updateTaskFileAttachmentsStart,
  updateTaskFileAttachmentsSuccess
} from './updateTaskFileAttachments';

import {
  removeTaskFileAttachmentError,
  removeTaskFileAttachmentStart,
  removeTaskFileAttachmentSuccess
} from './removeTaskFileAttachment';

import {
  removeTaskFileAttachmentsError,
  removeTaskFileAttachmentsStart,
  removeTaskFileAttachmentsSuccess
} from './removeTaskFileAttachments';

import {
  removeTaskResultsError,
  removeTaskResultsStart,
  removeTaskResultsSuccess
} from './removeTaskResults';

import {
  removeTaskSelectedProductsStart,
  removeTaskSelectedProductsSuccess,
  removeTaskSelectedProductsError
} from './removeTaskSelectedProducts';

import {
  downloadTaskSelectedProductsStart,
  downloadTaskSelectedProductsSuccess,
  downloadTaskSelectedProductsError
} from './downloadTaskSelectedProducts';

import {
  removeTaskSelectedTemplatesStart,
  removeTaskSelectedTemplatesSuccess,
  removeTaskSelectedTemplatesError
} from './removeTaskSelectedTemplates';

import {
  removeTaskSourceFilesStart,
  removeTaskSourceFilesSuccess,
  removeTaskSourceFilesError
} from './removeTaskSourceFiles';

import {
  updateTaskSelectedProductsError,
  updateTaskSelectedProductsStart,
  updateTaskSelectedProductsSuccess
} from './updateTaskSelectedProducts';

import {
  removeTaskSelectedProductError,
  removeTaskSelectedProductStart,
  removeTaskSelectedProductSuccess
} from './removeTaskSelectedProduct';

import {
  updateTaskSelectedTemplatesError,
  updateTaskSelectedTemplatesStart,
  updateTaskSelectedTemplatesSuccess
} from './updateTaskSelectedTemplates';

import {
  removeTaskSelectedTemplateError,
  removeTaskSelectedTemplateStart,
  removeTaskSelectedTemplateSuccess
} from './removeTaskSelectedTemplate';

import {
  removeTaskSourceFileError,
  removeTaskSourceFileStart,
  removeTaskSourceFileSuccess
} from './removeTaskSourceFile';

import {
  removeTaskSelectedAttachmentTextureFilesStart,
  removeTaskSelectedAttachmentTextureFilesSuccess,
  removeTaskSelectedAttachmentTextureFilesError
} from './removeTaskSelectedAttachmentTextureFiles';

import {
  sendTaskResultsToEmailStart,
  sendTaskResultsToEmailSuccess,
  sendTaskResultsToEmailError
} from './sendTaskResultsToEmail';

import {
  submitTaskSelectedProductsStart,
  submitTaskSelectedProductsSuccess,
  submitTaskSelectedProductsError
} from './submitTaskSelectedProducts';

import {
  submitTaskSelectedTemplatesStart,
  submitTaskSelectedTemplatesSuccess,
  submitTaskSelectedTemplatesError
} from './submitTaskSelectedTemplates';

import {
  submitTaskResultsStart,
  submitTaskResultsSuccess,
  submitTaskResultsError
} from './submitTaskResults';

import {
  submitTaskFileAttachmentsStart,
  submitTaskFileAttachmentsSuccess,
  submitTaskFileAttachmentsError
} from './submitTaskFileAttachments';

import {
  submitTaskSourceFilesStart,
  submitTaskSourceFilesSuccess,
  submitTaskSourceFilesError
} from './submitTaskSourceFiles';

import {
  trackTaskTimeStart,
  trackTaskTimeSuccess,
  trackTaskTimeError
} from './trackTaskTime';

import {
  trackTasksTimeStart,
  trackTasksTimeSuccess,
  trackTasksTimeError
} from './trackTasksTime';

import {
  acceptTaskStart,
  acceptTaskSuccess,
  acceptTaskError
} from './acceptTask';

import {
  cancelTaskStart,
  cancelTaskSuccess,
  cancelTaskError
} from './cancelTask';

import {
  leaveTaskStart,
  leaveTaskSuccess,
  leaveTaskError
} from './leaveTask';

import {
  toggleTaskNotificationsStart,
  toggleTaskNotificationsSuccess,
  toggleTaskNotificationsError
} from './toggleTaskNotifications';

const defaultTasksState = {
  ...defaultState,

  statuses: [
    'definition',
    'implementation',
    'qa',
    'incomplete_specification',
    // 'payment_waiting',
    // 'paid',
    'acceptance',
    'performed',
    'canceled'
  ],

  billTypes: [
    'task',
    'cancel_task'
  ]
};

export default createReducer(defaultTasksState, {
  '@@router/LOCATION_CHANGE': locationChange(defaultTasksState),
  AUTH_USER: initTasksFilters,

  FETCH_TASKS_START: fetchItemsStart,
  FETCH_TASKS_SUCCESS: fetchItemsSuccess,
  FETCH_TASKS_ERROR: fetchItemsError,

  FETCH_TASK_START: fetchItemStart,
  FETCH_TASK_SUCCESS: fetchItemSuccess,
  FETCH_TASK_ERROR: fetchItemError,

  CREATE_TASK_START: createItemStart,
  CREATE_TASK_SUCCESS: createItemSuccess,
  CREATE_TASK_ERROR: createItemError,

  CREATE_TASKS_START: createTasksStart,
  CREATE_TASKS_SUCCESS: createTasksSuccess,
  CREATE_TASKS_ERROR: createTasksError,

  UPDATE_TASK_START: updateItemStart,
  UPDATE_TASK_SUCCESS: updateItemSuccess,
  UPDATE_TASK_ERROR: updateItemError,

  CLEAR_TASK_FILTERS_START: clearItemsFiltersStart,
  CLEAR_TASK_FILTERS_SUCCESS: clearItemsFiltersSuccess,

  INVITE_USERS_TO_TASK_START: inviteUsersToTaskStart,
  INVITE_USERS_TO_TASK_SUCCESS: inviteUsersToTaskSuccess,
  INVITE_USERS_TO_TASK_ERROR: inviteUsersToTaskError,

  INVITE_USERS_TO_TASKS_START: inviteUsersToTasksStart,
  INVITE_USERS_TO_TASKS_SUCCESS: inviteUsersToTasksSuccess,
  INVITE_USERS_TO_TASKS_ERROR: inviteUsersToTasksError,

  ACCEPT_INVITE_TO_TASK_START: acceptInviteToTaskStart,
  ACCEPT_INVITE_TO_TASK_SUCCESS: acceptInviteToTaskSuccess,
  ACCEPT_INVITE_TO_TASK_ERROR: acceptInviteToTaskError,

  ALLOCATE_FUNDS_TO_TASKS_START: allocateFundsToTasksStart,
  ALLOCATE_FUNDS_TO_TASKS_SUCCESS: allocateFundsToTasksSuccess,
  ALLOCATE_FUNDS_TO_TASKS_ERROR: allocateFundsToTasksError,

  UPDATE_TASK_PROJECT_ESTIMATE_COST_START: updateTaskProjectEstimateCostStart,
  UPDATE_TASK_PROJECT_ESTIMATE_COST_SUCCESS: updateTaskProjectEstimateCostSuccess,
  UPDATE_TASK_PROJECT_ESTIMATE_COST_ERROR: updateTaskProjectEstimateCostError,

  CHANGE_TASKS_VISIBILITY_START: changeTasksVisibilityStart,
  CHANGE_TASKS_VISIBILITY_SUCCESS: changeTasksVisibilitySuccess,
  CHANGE_TASKS_VISIBILITY_ERROR: changeTasksVisibilityError,

  CHANGE_TASKS_TAGS_START: changeTasksTagsStart,
  CHANGE_TASKS_TAGS_SUCCESS: changeTasksTagsSuccess,
  CHANGE_TASKS_TAGS_ERROR: changeTasksTagsError,

  CHANGE_TASKS_STATUS_START: changeTasksStatusStart,
  CHANGE_TASKS_STATUS_SUCCESS: changeTasksStatusSuccess,
  CHANGE_TASKS_STATUS_ERROR: changeTasksStatusError,

  DUPLICATE_TASKS_START: duplicateTasksStart,
  DUPLICATE_TASKS_SUCCESS: duplicateTasksSuccess,
  DUPLICATE_TASKS_ERROR: duplicateTasksError,

  REMOVE_USERS_FROM_TASK_START: removeUsersFromTaskStart,
  REMOVE_USERS_FROM_TASK_SUCCESS: removeUsersFromTaskSuccess,
  REMOVE_USERS_FROM_TASK_ERROR: removeUsersFromTaskError,

  TOGGLE_SELECTED_TASK_START: toggleSelectedTaskStart,
  TOGGLE_SELECTED_TASK_SUCCESS: toggleSelectedTaskSuccess,
  TOGGLE_SELECTED_TASK_ERROR: toggleSelectedTaskError,

  CREATE_TASK_BILLS_START: createTaskBillsStart,
  CREATE_TASK_BILLS_SUCCESS: createTaskBillsSuccess,
  CREATE_TASK_BILLS_ERROR: createTaskBillsError,

  CREATE_TASKS_BILLS_START: createTasksBillsStart,
  CREATE_TASKS_BILLS_SUCCESS: createTasksBillsSuccess,
  CREATE_TASKS_BILLS_ERROR: createTasksBillsError,

  UPDATE_TASKS_AMOUNTS_START: updateTasksAmountsStart,
  UPDATE_TASKS_AMOUNTS_SUCCESS: updateTasksAmountsSuccess,
  UPDATE_TASKS_AMOUNTS_ERROR: updateTasksAmountsError,

  UPDATE_TASKS_ESTIMATE_DATES_START: updateTasksEstimateDatesStart,
  UPDATE_TASKS_ESTIMATE_DATES_SUCCESS: updateTasksEstimateDatesSuccess,
  UPDATE_TASKS_ESTIMATE_DATES_ERROR: updateTasksEstimateDatesError,

  TASKS_CHANNEL_UPDATED: itemsChannelUpdated,

  ACCEPT_TASK_RESULTS_START: acceptTaskResultsStart,
  ACCEPT_TASK_RESULTS_SUCCESS: acceptTaskResultsSuccess,
  ACCEPT_TASK_RESULTS_ERROR: acceptTaskResultsError,

  ACCEPT_TASKS_RESULTS_START: acceptTasksResultsStart,
  ACCEPT_TASKS_RESULTS_SUCCESS: acceptTasksResultsSuccess,
  ACCEPT_TASKS_RESULTS_ERROR: acceptTasksResultsError,

  APPROVE_TASK_RESULTS_START: approveTaskResultsStart,
  APPROVE_TASK_RESULTS_SUCCESS: approveTaskResultsSuccess,
  APPROVE_TASK_RESULTS_ERROR: approveTaskResultsError,

  CLEAR_TASK_MODAL_VALUES: clearTaskModalValues,

  REVISION_TASK_START: revisionTaskStart,
  REVISION_TASK_SUCCESS: revisionTaskSuccess,
  REVISION_TASK_ERROR: revisionTaskError,

  UPDATE_FILE_ATTACHMENT_START: updateTaskFileAttachmentsStart,
  UPDATE_FILE_ATTACHMENT_SUCCESS: updateTaskFileAttachmentsSuccess,
  UPDATE_FILE_ATTACHMENT_ERROR: updateTaskFileAttachmentsError,

  REMOVE_FILE_ATTACHMENT_START: removeTaskFileAttachmentStart,
  REMOVE_FILE_ATTACHMENT_SUCCESS: removeTaskFileAttachmentSuccess,
  REMOVE_FILE_ATTACHMENT_ERROR: removeTaskFileAttachmentError,

  REMOVE_TASK_FILE_ATTACHMENTS_START: removeTaskFileAttachmentsStart,
  REMOVE_TASK_FILE_ATTACHMENTS_SUCCESS: removeTaskFileAttachmentsSuccess,
  REMOVE_TASK_FILE_ATTACHMENTS_ERROR: removeTaskFileAttachmentsError,

  REMOVE_TASK_RESULTS_START: removeTaskResultsStart,
  REMOVE_TASK_RESULTS_SUCCESS: removeTaskResultsSuccess,
  REMOVE_TASK_RESULTS_ERROR: removeTaskResultsError,

  REMOVE_TASK_SELECTED_PRODUCTS_START: removeTaskSelectedProductsStart,
  REMOVE_TASK_SELECTED_PRODUCTS_SUCCESS: removeTaskSelectedProductsSuccess,
  REMOVE_TASK_SELECTED_PRODUCTS_ERROR: removeTaskSelectedProductsError,

  DOWNLOAD_TASK_SELECTED_PRODUCTS_START: downloadTaskSelectedProductsStart,
  DOWNLOAD_TASK_SELECTED_PRODUCTS_SUCCESS: downloadTaskSelectedProductsSuccess,
  DOWNLOAD_TASK_SELECTED_PRODUCTS_ERROR: downloadTaskSelectedProductsError,

  REMOVE_TASK_SELECTED_TEMPLATES_START: removeTaskSelectedTemplatesStart,
  REMOVE_TASK_SELECTED_TEMPLATES_SUCCESS: removeTaskSelectedTemplatesSuccess,
  REMOVE_TASK_SELECTED_TEMPLATES_ERROR: removeTaskSelectedTemplatesError,

  REMOVE_TASK_SOURCE_FILES_START: removeTaskSourceFilesStart,
  REMOVE_TASK_SOURCE_FILES_SUCCESS: removeTaskSourceFilesSuccess,
  REMOVE_TASK_SOURCE_FILES_ERROR: removeTaskSourceFilesError,

  UPDATE_SELECTED_PRODUCT_START: updateTaskSelectedProductsStart,
  UPDATE_SELECTED_PRODUCT_SUCCESS: updateTaskSelectedProductsSuccess,
  UPDATE_SELECTED_PRODUCT_ERROR: updateTaskSelectedProductsError,

  REMOVE_SELECTED_PRODUCT_START: removeTaskSelectedProductStart,
  REMOVE_SELECTED_PRODUCT_SUCCESS: removeTaskSelectedProductSuccess,
  REMOVE_SELECTED_PRODUCT_ERROR: removeTaskSelectedProductError,

  UPDATE_SELECTED_TEMPLATE_START: updateTaskSelectedTemplatesStart,
  UPDATE_SELECTED_TEMPLATE_SUCCESS: updateTaskSelectedTemplatesSuccess,
  UPDATE_SELECTED_TEMPLATE_ERROR: updateTaskSelectedTemplatesError,

  REMOVE_SELECTED_TEMPLATE_START: removeTaskSelectedTemplateStart,
  REMOVE_SELECTED_TEMPLATE_SUCCESS: removeTaskSelectedTemplateSuccess,
  REMOVE_SELECTED_TEMPLATE_ERROR: removeTaskSelectedTemplateError,

  REMOVE_SOURCE_FILE_START: removeTaskSourceFileStart,
  REMOVE_SOURCE_FILE_SUCCESS: removeTaskSourceFileSuccess,
  REMOVE_SOURCE_FILE_ERROR: removeTaskSourceFileError,

  REMOVE_TEXTURE_FILE_START: removeTaskSelectedAttachmentTextureFilesStart,
  REMOVE_TEXTURE_FILE_SUCCESS: removeTaskSelectedAttachmentTextureFilesSuccess,
  REMOVE_TEXTURE_FILE_ERROR: removeTaskSelectedAttachmentTextureFilesError,

  SEND_TASK_RESULTS_TO_EMAIL_START: sendTaskResultsToEmailStart,
  SEND_TASK_RESULTS_TO_EMAIL_SUCCESS: sendTaskResultsToEmailSuccess,
  SEND_TASK_RESULTS_TO_EMAIL_ERROR: sendTaskResultsToEmailError,

  SUBMIT_TASK_FILE_ATTACHMENTS_START: submitTaskFileAttachmentsStart,
  SUBMIT_TASK_FILE_ATTACHMENTS_SUCCESS: submitTaskFileAttachmentsSuccess,
  SUBMIT_TASK_FILE_ATTACHMENTS_ERROR: submitTaskFileAttachmentsError,

  SUBMIT_TASK_RESULTS_START: submitTaskResultsStart,
  SUBMIT_TASK_RESULTS_SUCCESS: submitTaskResultsSuccess,
  SUBMIT_TASK_RESULTS_ERROR: submitTaskResultsError,

  SUBMIT_TASK_SELECTED_PRODUCTS_START: submitTaskSelectedProductsStart,
  SUBMIT_TASK_SELECTED_PRODUCTS_SUCCESS: submitTaskSelectedProductsSuccess,
  SUBMIT_TASK_SELECTED_PRODUCTS_ERROR: submitTaskSelectedProductsError,

  SUBMIT_TASK_SELECTED_TEMPLATES_START: submitTaskSelectedTemplatesStart,
  SUBMIT_TASK_SELECTED_TEMPLATES_SUCCESS: submitTaskSelectedTemplatesSuccess,
  SUBMIT_TASK_SELECTED_TEMPLATES_ERROR: submitTaskSelectedTemplatesError,

  SUBMIT_TASK_SOURCE_FILES_START: submitTaskSourceFilesStart,
  SUBMIT_TASK_SOURCE_FILES_SUCCESS: submitTaskSourceFilesSuccess,
  SUBMIT_TASK_SOURCE_FILES_ERROR: submitTaskSourceFilesError,

  TRACK_TASK_TIME_START: trackTaskTimeStart,
  TRACK_TASK_TIME_SUCCESS: trackTaskTimeSuccess,
  TRACK_TASK_TIME_ERROR: trackTaskTimeError,

  TRACK_TASKS_TIME_START: trackTasksTimeStart,
  TRACK_TASKS_TIME_SUCCESS: trackTasksTimeSuccess,
  TRACK_TASKS_TIME_ERROR: trackTasksTimeError,

  ACCEPT_TASK_START: acceptTaskStart,
  ACCEPT_TASK_SUCCESS: acceptTaskSuccess,
  ACCEPT_TASK_ERROR: acceptTaskError,

  CANCEL_TASK_START: cancelTaskStart,
  CANCEL_TASK_SUCCESS: cancelTaskSuccess,
  CANCEL_TASK_ERROR: cancelTaskError,

  LEAVE_TASK_START: leaveTaskStart,
  LEAVE_TASK_SUCCESS: leaveTaskSuccess,
  LEAVE_TASK_ERROR: leaveTaskError,

  TOGGLE_TASK_NOTIFICATIONS_START: toggleTaskNotificationsStart,
  TOGGLE_TASK_NOTIFICATIONS_SUCCESS: toggleTaskNotificationsSuccess,
  TOGGLE_TASK_NOTIFICATIONS_ERROR: toggleTaskNotificationsError
});
