import { STRIPE_PAYMENT } from '../BillConstants';

// stripePayment

export default function stripePayment(id, values, options = {}, cb) {
  return dispatch => dispatch({
    type: STRIPE_PAYMENT,
    id,
    values,
    options,
    cb
  });
}
