import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import { LoadableWrapper } from '../../utils/LoadableWrapper';

const ProjectNewPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "projects" */'./pages/ProjectNewPage/ProjectNewPage') });
const ProjectShowPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "projects" */'./pages/ProjectShowPage/ProjectShowPage') });
const ProjectAcceptPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "projects" */'./pages/ProjectAcceptPage/ProjectAcceptPage') });
const ProjectDescriptionPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "projects" */'./pages/ProjectDescriptionPage/ProjectDescriptionPage') });
const ProjectMessagesPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "projects" */'./pages/ProjectMessagesPage/ProjectMessagesPage') });
const ProjectFilesPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "projects" */'./pages/ProjectFilesPage/ProjectFilesPage') });
const ProjectImagesPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "projects" */'./pages/ProjectImagesPage/ProjectImagesPage') });
const ProjectProductsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "projects" */'./pages/ProjectProductsPage/ProjectProductsPage') });
const ProjectTemplatesPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "projects" */'./pages/ProjectTemplatesPage/ProjectTemplatesPage') });
const ProjectSourceFilesPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "projects" */'./pages/ProjectSourceFilesPage/ProjectSourceFilesPage') });
const ProjectResultsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "projects" */'./pages/ProjectResultsPage/ProjectResultsPage') });
const ProjectTimelinePage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "projects" */'./pages/ProjectTimelinePage/ProjectTimelinePage') });
const ProjectTransactionsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "projects" */'./pages/ProjectTransactionsPage/ProjectTransactionsPage') });
const ProjectNotificationsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "projects" */'./pages/ProjectNotificationsPage/ProjectNotificationsPage') });
const ProjectsIndexPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "projects" */'./pages/ProjectsIndexPage/ProjectsIndexPage') });
const ProjectBatchImportTasksPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "projectBatchImport" */'./pages/ProjectBatchImportTasksPage/ProjectBatchImportTasksPage') });
const ProjectBatchCreateTasksPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "projects" */'./pages/ProjectBatchCreateTasksPage/ProjectBatchCreateTasksPage') });
const ProjectBatchOperationsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "projects" */'./pages/ProjectBatchOperationsPage/ProjectBatchOperationsPage') });

export default (
  <Fragment>
    <Route exact path="/projects/new" component={ProjectNewPage} />
    <Route exact path="/projects/:projectId(\d+)/accept" component={ProjectAcceptPage} />
    <Route exact path="/projects/:projectId(\d+)/messages" component={ProjectMessagesPage} />
    <Route exact path="/projects/:projectId(\d+)/description" component={ProjectDescriptionPage} />
    <Route exact path="/projects/:projectId(\d+)/files" component={ProjectFilesPage} />
    <Route exact path="/projects/:projectId(\d+)/images" component={ProjectImagesPage} />
    <Route exact path="/projects/:projectId(\d+)/products" component={ProjectProductsPage} />
    <Route exact path="/projects/:projectId(\d+)/templates" component={ProjectTemplatesPage} />
    <Route exact path="/projects/:projectId(\d+)/source-files" component={ProjectSourceFilesPage} />
    <Route exact path="/projects/:projectId(\d+)/results" component={ProjectResultsPage} />
    <Route exact path="/projects/:projectId(\d+)/timeline" component={ProjectTimelinePage} />
    <Route exact path="/projects/:projectId(\d+)/transactions" component={ProjectTransactionsPage} />
    <Route exact path="/projects/:projectId(\d+)/notifications" component={ProjectNotificationsPage} />
    <Route exact path="/projects/:projectId(\d+)/batch-import-tasks" component={ProjectBatchImportTasksPage} />
    <Route exact path="/projects/:projectId(\d+)/batch-create-tasks" component={ProjectBatchCreateTasksPage} />
    <Route exact path="/projects/:projectId(\d+)/batch_operations" component={ProjectBatchOperationsPage} />
    <Route exact path="/projects/:projectId(\d+)" component={ProjectShowPage} />
    <Route exact path="/projects" component={ProjectsIndexPage} />
  </Fragment>
);
