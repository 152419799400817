import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers, withState, pure } from 'recompose';
import get from 'lodash/get';

import { withBills } from '../../HOCs/withBills';

import { AlertMessage, Button } from '../../../../helpers';
import { renderAmount } from '../../../../utils/renderAmount';
import { generateHtmlForm } from '../../../../utils/ts/generateHtmlForm';
import { translate as t } from '../../../../utils';
import { analyticsSetBillCheckout } from '../../../ts/bills/utils/analyticsSetBillCheckout';

function TwoCheckoutButton({
  className, addClass, icon, bill, isLoading, disabled, handlePaymentProcess,
  twoCheckoutModalItemLoading, twoCheckoutModalItemErrorMessage,
  errorMessage
}) {
  return (
    <Fragment>
      <AlertMessage>{twoCheckoutModalItemErrorMessage || errorMessage}</AlertMessage>
      <Button
        color="primary"
        className={className}
        addClass={addClass}
        disabled={disabled || isLoading || twoCheckoutModalItemLoading}
        onClick={handlePaymentProcess}
      >
        <b className="mr-2">
          <i className={icon} />
        </b>
        {isLoading || twoCheckoutModalItemLoading ? t('words.processing') : (
          <Fragment>
            {t('words.pay')}
            &nbsp;
            {renderAmount(get(bill, 'amount'), false)}
            &nbsp;
            {get(bill, 'currency')}
          </Fragment>
        )}
      </Button>
      <span className="sp-hidden" dangerouslySetInnerHTML={{ __html: generateHtmlForm(get(bill, 'payment_form')) }} />
    </Fragment>
  );
}

TwoCheckoutButton.propTypes = {
  bill: PropTypes.shape({ id: PropTypes.number }).isRequired,
  disabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  twoCheckoutModalItemLoading: PropTypes.bool.isRequired,
  twoCheckoutModalItemErrorMessage: PropTypes.node,
  errorMessage: PropTypes.node,
  icon: PropTypes.string,
  className: PropTypes.string,
  addClass: PropTypes.string,
  handlePaymentProcess: PropTypes.func.isRequired
};

TwoCheckoutButton.defaultProps = {
  icon: null,
  className: null,
  addClass: null,
  twoCheckoutModalItemErrorMessage: null,
  errorMessage: null
};

export default compose(
  withState('errorMessage', 'setErrorMessage', null),
  withBills({
    scope: 'bills',
    fields: ['twoCheckoutModalItem', 'twoCheckoutModalItemLoading', 'twoCheckoutModalItemErrorMessage'],
    namespace: 'twoCheckoutModal',
    actions: ['fetchBill', 'setBillPaying']
  }),
  withHandlers({
    handlePaymentProcess: ({ bill, fetchBill, setErrorMessage }) => () => fetchBill(bill.id, {}, ({ item: updatedBill }) => {
      if (updatedBill.payable) {
        // setBillPaying(bill);
        analyticsSetBillCheckout({ bill, step: 'click to pay' });
        return document.getElementById('2checkout').submit();
      }

      if (updatedBill.canceled) {
        return setErrorMessage(t('models.bills.errors.canceled'));
      }

      return setErrorMessage(t('models.bills.errors.already_paying'));
    })
  }),
  pure
)(TwoCheckoutButton);
