export const currentUserSelector = state => state.auth.currentUser;
export const authFetchedSelector = state => state.auth.authFetched;
export const isAuthenticatedSelector = state => state.auth.isAuthenticated;
export const websocketsFetchedSelector = state => state.auth.websocketsFetched;
export const currentUserProjectsFiltersSelector = state => state.auth.currentUserProjectsFilters;
export const currentUserTasksFiltersSelector = state => state.auth.currentUserTasksFilters;
export const currentUserResultsFiltersSelector = state => state.auth.currentUserResultsFilters;
export const currentUserMessagesFiltersSelector = state => state.auth.currentUserMessagesFilters;
export const currentUserProductsFiltersSelector = state => state.auth.currentUserProductsFilters;
export const currentUserTemplatesFiltersSelector = state => state.auth.currentUserTemplatesFilters;
export const currentUserAccountsFiltersSelector = state => state.auth.currentUserAccountsFilters;
export const currentUserWorkersFiltersSelector = state => state.auth.currentUserWorkersFilters;
export const currentUserClientsFiltersSelector = state => state.auth.currentUserClientsFilters;
export const currentUserUsersFiltersSelector = state => state.auth.currentUserUsersFilters;
