import React, { useState, useCallback, memo } from 'react';
import cl from 'classnames';

import { Button } from '../../../../helpers/ts/Button';
import { ContactModalButton } from '../../../../main/contacts/components/buttons/ContactModalButton';
import { TermsModalButton } from '../../../../main/common/components/buttons/TermsModalButton';

function Footer() {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggleOpen = useCallback(() => setIsOpen(value => !value), []);

  return (
    <div className="navbar navbar-expand-lg navbar-light">
      <div className="text-center d-lg-none w-100">
        <Button
          className="navbar-toggler dropdown-toggle"
          onClick={handleToggleOpen}
          icon="icon-unfold"
        >
          Footer
        </Button>
      </div>

      <div className={cl('collapse navbar-collapse', { show: isOpen })}>
        <div className="text-center">
          <span className="navbar-text">
            © 2012 - {new Date().getFullYear()}. Archivizer CRM
          </span>
        </div>
        <ul className="navbar-nav ml-lg-auto">
          <li>
            <TermsModalButton />
          </li>
          <li>
            <ContactModalButton />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default memo(Footer);
