import { PageType } from '../types';

import { useShowRequest, ShowHookOptions } from '../../base/useShowRequest';

const url = 'pages';

function usePage(options: ShowHookOptions) {
  const {
    item,
    itemFetched,
    itemErrorMessage,
    itemLoading,
    fetchItem,
    refetch,
    cancel
  } = useShowRequest<PageType>({ url, ...options });

  return {
    page: item,
    pageFetched: itemFetched,
    pageErrorMessage: itemErrorMessage,
    pageLoading: itemLoading,
    fetchPage: fetchItem,
    refetch,
    cancel
  };
}

export default usePage;
