import get from 'lodash/get';

import { updateState, replaceById, removeById } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function removeTaskFileAttachmentStart(state, { id, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, task => ({
      ...task,
      file_attachments: replaceById(get(task, 'file_attachments', []), id, fileAttachment => ({
        ...fileAttachment,
        error: null,
        loading: true
      }))
    }))
  }, options);
}

export function removeTaskFileAttachmentSuccess(state, { id, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, task => ({
      ...task,
      file_attachments: removeById(get(task, 'file_attachments', []), id)
    }))
  }, options);
}

export function removeTaskFileAttachmentError(state, { id, errorMessage, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_id, task => ({
      ...task,
      file_attachments: replaceById(get(task, 'file_attachments', []), id, fileAttachment => ({
        ...fileAttachment,
        error: errorMessage,
        loading: false
      }))
    }))
  }, options);
}
