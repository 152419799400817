import React, { Fragment } from 'react';

import dashboard from './dashboard/routes';
import bills from './bills/routes';
import products from './products/routes';
import projects from './projects/routes';
import tasks from './tasks/routes';
import templates from './templates/routes';
import messages from './messages/routes';
import users from './users/routes';
import results from './results/routes';

export default (
  <Fragment>
    {dashboard}
    {bills}
    {products}
    {projects}
    {tasks}
    {templates}
    {messages}
    {users}
    {results}
  </Fragment>
);
