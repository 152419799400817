import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { submit as submitAction } from 'redux-form';
import pickBy from 'lodash/pickBy';
import get from 'lodash/get';

import { LinkTo, Button } from '../../helpers';
import { translate as t } from '../../utils';

class ModalButton extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    addClass: PropTypes.string,
    link: PropTypes.bool,
    pureLink: PropTypes.bool,
    buttonText: PropTypes.node,
    cancelI18nText: PropTypes.string,
    cancelText: PropTypes.node,
    icon: PropTypes.string,
    color: PropTypes.string,
    modalTitle: PropTypes.node,
    modalIcon: PropTypes.string,
    modalSize: PropTypes.string,
    size: PropTypes.string,
    form: PropTypes.string,
    withoutFooter: PropTypes.bool,
    submitDisabled: PropTypes.bool,
    submitColor: PropTypes.string,
    submitText: PropTypes.node,
    disabled: PropTypes.bool,
    fetched: PropTypes.bool,
    isLoading: PropTypes.bool,
    autoFocus: PropTypes.bool,
    data: PropTypes.shape(),
    tooltip: PropTypes.shape({
      target: PropTypes.string.isRequired,
      value: PropTypes.node,
      i18nText: PropTypes.node,
      placement: PropTypes.string
    }),
    func: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    onToggle: PropTypes.func,
    submit: PropTypes.func,
    renderSubmit: PropTypes.func
  };

  static defaultProps = {
    className: null,
    addClass: null,
    link: null,
    pureLink: null,
    buttonText: null,
    cancelI18nText: null,
    cancelText: null,
    icon: null,
    color: null,
    modalTitle: null,
    modalIcon: null,
    modalSize: 'sm',
    size: 'sm',
    disabled: false,
    fetched: true,
    isLoading: false,
    autoFocus: false,
    form: null,
    withoutFooter: false,
    submitDisabled: null,
    submitColor: 'primary',
    submitText: null,
    data: {},
    func: false,
    onOpen: null,
    onClose: null,
    onToggle: null,
    submit: null,
    renderSubmit: null,
    tooltip: null
  };

  state = {
    opened: false,
    backdrop: true
  };

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (nextProps.hiddenModal) {
  //     this.hideModal();
  //   }
  // }

  handleButtonClick = () => this.setState({ opened: true }, () => {
    this.props.onToggle && this.props.onToggle(this.state.opened, this.props.data);
    this.props.onOpen && this.props.onOpen(this.props.data);
  });

  toggleBackdrop = () => this.setState(prevState => ({ backdrop: !prevState.backdrop }));

  toggleOpened = () => this.setState(prevState => ({ opened: !prevState.opened }), () => {
    this.props.onToggle && this.props.onToggle(this.state.opened, this.props.data);

    if (this.state.opened && this.props.onOpen) {
      return this.props.onOpen(this.props.data);
    }

    return this.props.onClose && this.props.onClose(this.props.data);
  });

  hideModal = () => this.setState({ opened: false }, () => {
    this.props.onToggle && this.props.onToggle(this.state.opened, this.props.data);
    this.props.onClose && this.props.onClose(this.props.data);
  });

  handleSubmit = () => this.props.submit(this.props.form);

  render() {
    const {
      addClass, className, link, pureLink, icon, color, buttonText, modalTitle, modalIcon, modalSize, disabled, fetched,
      isLoading, autoFocus, form, withoutFooter, cancelAddClass, submitAddClass, submitDisabled, submitColor, submitText,
      cancelI18nText, cancelText, submitIcon, size, onSubmit, children, submitTooltip, tooltip, func, renderSubmit
    } = this.props;

    const C = link ? LinkTo : Button;

    return (
      <Fragment>
        <C
          id={get(tooltip, 'target')}
          addClass={addClass}
          className={className}
          icon={icon}
          tooltip={tooltip}
          color={color}
          size={link ? null : size}
          disabled={disabled}
          onClick={this.handleButtonClick}
          {...pickBy({ href: (link ? '#' : null), pureLink: (link || pureLink) })}
        >
          {buttonText}
        </C>
        <Modal backdrop={this.state.backdrop} isOpen={this.state.opened} size={modalSize} toggle={this.toggleOpened} autoFocus={autoFocus}>
          <ModalHeader toggle={this.toggleOpened}>
            <div className="modal-title text-muted font-size-base">
              <i className={modalIcon} />
              {modalTitle}
            </div>
          </ModalHeader>
          <ModalBody>
            {fetched && (func ? children({ hideModal: this.hideModal, toggleBackdrop: this.toggleBackdrop }) : children)}
          </ModalBody>
          {renderSubmit ? (
            <ModalFooter className="card-footer py-2 text-right border-top">
              <Button addClass={cancelAddClass} disabled={disabled} size="sm" onClick={this.toggleOpened}>
                {(cancelI18nText && t(cancelI18nText)) || cancelText || t('words.cancel')}
              </Button>
              {renderSubmit({ hideModal: this.hideModal })}
            </ModalFooter>
          ) : null}
          {fetched && form ? (
            <ModalFooter className="card-footer py-2 text-right border-top">
              <Button addClass={cancelAddClass} disabled={disabled} size="sm" onClick={this.toggleOpened}>
                {(cancelI18nText && t(cancelI18nText)) || cancelText || t('words.cancel')}
              </Button>
              <Button
                id={get(submitTooltip, 'target')}
                addClass={submitAddClass}
                disabled={disabled}
                size="sm"
                color={submitColor}
                icon={submitIcon}
                tooltip={submitTooltip}
                onClick={this.handleSubmit}
              >
                {isLoading ? t('words.processing') : submitText || t('words.save')}
              </Button>
            </ModalFooter>
          ) : null}
          {withoutFooter ? null : (
            <ModalFooter className="bg-light py-2 text-right border-top">
              <Button addClass={cancelAddClass} disabled={submitDisabled} size="sm" onClick={this.toggleOpened}>
                {(cancelI18nText && t(cancelI18nText)) || cancelText || t('words.cancel')}
              </Button>
              <Button
                id={get(submitTooltip, 'target')}
                addClass={submitAddClass}
                disabled={submitDisabled}
                size="sm"
                color={submitColor}
                icon={submitIcon}
                tooltip={submitTooltip}
                onClick={onSubmit({ hideModal: this.hideModal })}
              >
                {isLoading ? t('words.processing') : submitText || t('words.save')}
              </Button>
            </ModalFooter>
          )}
        </Modal>
      </Fragment>
    );
  }
}

export default connect(null, { submit: submitAction })(ModalButton);
