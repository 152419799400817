import FileSaver from 'file-saver';

export default function downloadFile(file, name = null, options = { withLink: false }) {
  if (options.withLink) {
    const link = document.createElement('a');
    link.href = file;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return file;
  }

  return FileSaver.saveAs(file, name);
}
