import React, { Fragment, useCallback, memo, ReactNode } from 'react';
import cl from 'classnames';
import isString from 'lodash/isString';

import { Item } from '../../../types';

import { Tooltip, TooltipProps } from '../../../helpers/ts/Tooltip';
import { Translate } from '../../../helpers/ts/Translate';

export interface PureButtonProps {
  item?: Item;
  id?: string;
  className?: string;
  addClass?: string | Array<{}> | Array<string>;
  color?: string;
  size?: string;
  outline?: boolean;
  rounded?: boolean;
  block?: boolean;
  icon?: string | ReactNode;
  afterIcon?: string | ReactNode;
  submit?: boolean;
  disabled?: boolean;
  onClick?: (arg: Item | React.MouseEvent<HTMLButtonElement>) => void;
  style?: {};
  prefix?: string | number;
  i18nText?: string;
}

function PureButton({
  id,
  item,
  className,
  addClass,
  color,
  size,
  outline,
  rounded,
  block,
  icon,
  afterIcon,
  disabled,
  submit,
  style,
  tooltipTarget,
  tooltipPlacement,
  tooltipI18nValue,
  tooltipDelay,
  prefix,
  i18nText,
  onClick
}: PureButtonProps & TooltipProps) {
  const buttonClass =
    className ||
    cl(
      'btn',
      {
        [`btn-${color}`]: !!color,
        [`btn-${size}`]: !!size,
        'btn-outline': outline,
        'btn-rounded': rounded,
        'btn-block': block
      },
      addClass
    );

  const buttonIcon =
    icon && (isString(icon) ? <i className={cl(icon)} /> : icon);

  const buttonAfterIcon =
    afterIcon &&
    (isString(afterIcon) ? <i className={cl(afterIcon)} /> : afterIcon);

  const handleClick = useCallback(
    e => {
      if (!submit) {
        e.preventDefault();
      }

      if (onClick) {
        onClick(item || e);
      }
    },
    [item, submit, onClick]
  );

  return (
    <Fragment>
      <button
        id={id || tooltipTarget}
        type={submit ? 'submit' : 'button'}
        className={buttonClass}
        disabled={disabled}
        style={style}
        onClick={handleClick}
      >
        {prefix}
        {prefix ? ' ' : null}
        {buttonIcon}
        {buttonIcon && i18nText ? ' ' : null}
        {i18nText ? <Translate id={i18nText} /> : null}
        {buttonAfterIcon && i18nText ? ' ' : null}
        {buttonAfterIcon}
      </button>
      {tooltipTarget ? (
        <Tooltip
          placement={tooltipPlacement}
          target={tooltipTarget}
          delay={tooltipDelay}
          i18nText={tooltipI18nValue}
        />
      ) : null}
    </Fragment>
  );
}

export default memo(PureButton);
