export default {
  select: 'Select status...',
  change: 'Change status',
  types: {
    acceptance: 'Ready for acceptance',
    canceled: 'Canceled',
    checking: 'Checking task',
    definition: 'Setting task',
    discussion: 'Discussion',
    distribution: 'Distribution',
    implementation: 'In progress',
    incomplete_specification: 'Pending more info',
    paid: 'Paid',
    payment_waiting: 'Pending payment',
    performed: 'Done',
    qa: 'Quality control'
  }
};
