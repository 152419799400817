import React from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';
import Progress from 'reactstrap/lib/Progress';
import OutsideClickHandler from 'react-outside-click-handler';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withHandlers from 'recompose/withHandlers';
import size from 'lodash/size';

import { withDownloads } from '../../main/common/HOCs/withDownloads';
import { withCurrentUser } from '../../main/common/HOCs/withCurrentUser';

import {
  AlertMessage,
  Button,
  LinkTo,
  Loading,
  MoreButton,
  PureLinkTo
} from '../../helpers';
import { translate as t } from '../../utils';

function DownloadsMenu({
  currentUser,
  downloadsBoxOpened,
  collapse,
  toggleIsOpen,
  downloads,
  downloadsMeta,
  downloadsFetched,
  downloadsLoading,
  downloadsErrorMessage,
  loadMoreDownloads,
  removeDownload,
  checkDownload,
  addTotalBadgeClassName
}) {
  if (downloadsMeta.total === 0) {
    return null;
  }

  return (
    <li className="nav-item dropdown">
      <OutsideClickHandler onOutsideClick={collapse}>
        <LinkTo
          pureLink
          className={cl('navbar-nav-link', 'position-relative', {
            show: downloadsBoxOpened
          })}
          data-toggle="dropdown"
          onClick={toggleIsOpen}
        >
          <i className="icon-download4" />
          {downloadsMeta.total > 0 ? (
            <span
              className={cl(
                'badge badge-warning badge-pill badge-float mt-1 mr-1',
                addTotalBadgeClassName
              )}
            >
              {downloadsMeta.total}
            </span>
          ) : null}
        </LinkTo>

        {downloadsBoxOpened ? (
          <div className="dropdown-menu dropdown-menu-right dropdown-content wmin-md-350 wmax-450 show">
            <Loading loaded={downloadsFetched}>
              <div className="dropdown-content-body dropdown-scrollable pb-2">
                <AlertMessage>{downloadsErrorMessage}</AlertMessage>
                {size(downloads) === 0 ? (
                  <div className="text-center my-5">
                    <span>{t('models.downloads.no')}</span>
                  </div>
                ) : null}
                {size(downloads) > 0 ? (
                  <ul className="list-inline mb-0">
                    {downloads.map(download => (
                      <li key={download.id} className="list-inline-item w-100">
                        <div className="bg-light p-2 my-1 border rounded">
                          <div className="media align-items-center">
                            <div className="mr-3 align-self-center">
                              <i className="icon-file-zip icon-2x text-muted" />
                            </div>
                            <div className="flex-1">
                              <div className="d-flex justify-content-between">
                                <ul className="font-size-sm list-inline list-inline-condensed mb-0 text-size-sm">
                                  <li className="list-inline-item font-weight-semibold">
                                    {currentUser.hasPermissions(
                                      'admin_read_any_id'
                                    )
                                      ? `#${download.id} `
                                      : null}
                                    {download.name}
                                  </li>
                                </ul>
                                <ul className="font-size-sm list-inline list-inline-condensed mb-0 text-size-sm">
                                  {download.status === 'completed' ? (
                                    <li className="list-inline-item">
                                      <PureLinkTo
                                        pureLink
                                        addClass="bg-transparent btn-light cursor-pointer p-0 text-blue-600"
                                        icon="font-size-xs icon-download text-muted"
                                        disabled={download.loading}
                                        href={download.file}
                                        target="_blank"
                                      />
                                    </li>
                                  ) : null}
                                  {download.status === 'failed' ? (
                                    <li className="list-inline-item">
                                      <Button
                                        addClass="bg-transparent btn-light cursor-pointer p-0 text-blue-600"
                                        icon="font-size-xs icon-reload-alt text-muted"
                                        disabled={download.loading}
                                        onClick={checkDownload(download)}
                                      />
                                    </li>
                                  ) : null}
                                  <li className="list-inline-item">
                                    <Button
                                      addClass="bg-transparent btn-light cursor-pointer p-0 text-blue-600"
                                      icon="font-size-xs icon-trash text-muted"
                                      disabled={download.loading}
                                      onClick={removeDownload(download)}
                                    />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <AlertMessage>{download.errorMessage}</AlertMessage>
                          {download.status === 'completed' ? null : (
                            <Progress
                              animated={download.status === 'processing'}
                              color={
                                download.status === 'failed'
                                  ? 'danger'
                                  : 'success'
                              }
                              value={download.progress}
                            />
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : null}
                <MoreButton
                  meta={downloadsMeta}
                  isLoading={downloadsLoading}
                  onClick={loadMoreDownloads}
                />
              </div>
            </Loading>
          </div>
        ) : null}
      </OutsideClickHandler>
    </li>
  );
}

DownloadsMenu.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    hasPermissions: PropTypes.func
  }).isRequired,
  downloadsBoxOpened: PropTypes.bool,
  collapse: PropTypes.func.isRequired,
  toggleIsOpen: PropTypes.func.isRequired,
  downloads: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number }))
    .isRequired,
  downloadsMeta: PropTypes.shape({ page: PropTypes.number }).isRequired,
  downloadsFetched: PropTypes.bool.isRequired,
  downloadsLoading: PropTypes.bool.isRequired,
  downloadsErrorMessage: PropTypes.string,
  loadMoreDownloads: PropTypes.func.isRequired,
  removeDownload: PropTypes.func.isRequired,
  checkDownload: PropTypes.func.isRequired,
  addTotalBadgeClassName: PropTypes.string
};

DownloadsMenu.defaultProps = {
  downloadsBoxOpened: false,
  downloadsErrorMessage: null
};

export default compose(
  withCurrentUser(),
  withDownloads({
    scope: 'downloads',
    fetchType: 'index',
    filters: { progress: { lt: 100 }, self: true },
    subscriptions: ['created', 'updated', 'destroyed'],
    fields: [
      'downloads',
      'downloadsMeta',
      'downloadsLoading',
      'downloadsFetched',
      'downloadsBoxOpened'
    ],
    actions: [
      'fetchDownloads',
      'loadMoreDownloads',
      'updateDownload',
      'removeDownload',
      'checkDownload',
      'setDownloadsBoxOpened',
      'subscribeToDownloadsChannel',
      'unsubscribeFromDownloadsChannel',
      'subscribeToDropdownDownloadsChannel',
      'unsubscribeFromDropdownDownloadsChannel'
    ]
  }),
  // withProps(({ downloads }) => ({
  //   downloads: filter(downloads, download => download.progress < 100)
  // })),
  withHandlers({
    toggleIsOpen: ({ downloadsBoxOpened, setDownloadsBoxOpened }) => () =>
      setDownloadsBoxOpened(!downloadsBoxOpened),
    collapse: ({ downloadsBoxOpened, setDownloadsBoxOpened }) => () =>
      downloadsBoxOpened && setDownloadsBoxOpened(false)
  }),
  lifecycle({
    componentDidMount() {
      this.props.subscribeToDropdownDownloadsChannel(
        'created',
        this.props.currentUser.get('id')
      );
    },
    componentWillUnmount() {
      this.props.unsubscribeFromDropdownDownloadsChannel(
        'created',
        this.props.currentUser.get('id')
      );
    }
  })
)(DownloadsMenu);
