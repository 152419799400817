import React from 'react';
import PropTypes from 'prop-types';

import { LinkMenu } from '../../../../../helpers/LinkMenu';

const links = [
  { type: 'link', namespace: 'dashboard', href: '', label: 'words.dashboard' },
  { type: 'link', namespace: 'projects', href: 'projects', label: 'models.projects.plural' },
  { type: 'link', namespace: 'tasks', href: 'tasks', label: 'models.tasks.plural' },
  { type: 'link', namespace: 'messages', href: 'messages', label: 'models.messages.plural' },
  { type: 'link', namespace: 'results', href: 'results', label: 'words.results' },
  { type: 'dropdown',
    namespace: 'products|templates',
    label: 'words.library',
    links: [
      { type: 'link', namespace: 'products', href: 'products', label: 'models.products.plural', icon: 'icon-cube4' },
      { type: 'link', namespace: 'templates', href: 'templates', label: 'models.templates.plural', icon: 'icon-design' }
    ]
  },
  { type: 'dropdown',
    namespace: 'accounts|workers|clients',
    label: 'models.users.plural',
    links: [
      { type: 'link', namespace: 'accounts', href: 'accounts', label: 'models.accounts.plural', icon: 'icon-user' },
      { type: 'link', namespace: 'workers', href: 'workers', label: 'models.workers.plural', icon: 'icon-user' },
      { type: 'link', namespace: 'clients', href: 'clients', label: 'models.clients.plural', icon: 'icon-user' }
    ]
  },
  { type: 'link', namespace: 'admin', href: 'admin', label: 'words.admin' }
];

function MainLinkMenu({ currentUser, pathname }) {
  return (
    <LinkMenu links={links} currentUser={currentUser} pathname={pathname} />
  );
}

MainLinkMenu.propTypes = {
  currentUser: PropTypes.shape({ id: PropTypes.number }).isRequired,
  pathname: PropTypes.string.isRequired
};

export default React.memo(MainLinkMenu);
