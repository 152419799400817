import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateIntl as updateIntlAction } from 'react-intl-redux';
import { compose, withState, withHandlers, lifecycle, pure } from 'recompose';
import ScrollToTop from 'react-scroll-up';
import cl from 'classnames';
import get from 'lodash/get';
import Favicon from 'react-favicon';

import { withCurrentUser } from '../../../main/common/HOCs/withCurrentUser';

import { Header } from '../../components';
import { Footer } from '../../components/ts/Footer';
import { CozyHeader } from '../../components/CozyHeader';

import { Loading } from '../../../helpers';
import { pushToAction } from '../../../utils/pushToAction';
import { loadAllLocales } from '../../../utils/ts/loadAllLocales';
import { profileLocaleInStorage } from '../../../utils/ts/profileLocaleInStorage';
import { LoadableWrapper } from '../../../utils/LoadableWrapper';
import { isUserCozy } from '../../../utils/ts/isUserCozy';
import { CozyStorage } from '../../../utils/ts/CozyStorage';

import { main } from '../../routes/main';
import { scrollToTopStyle } from '../../AppConstants';
import { COZY_FAVICON_URL, MAIN_FAVICON_URL } from '../../../config';

const ErrorPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "errors" */'../../components/ErrorPage/ErrorPage') });

function MainAppContainer({ pathname, authFetched, websocketsFetched, isSidebarHidden, isSidebarMobile, toggleSidebarHidden, toggleSidebarMobile, onLogout, currentUser }) {
  return (
    <Fragment>
      {isUserCozy({ currentUser }) ? (
        <Fragment>
          <Favicon url={COZY_FAVICON_URL} />
          <CozyHeader
            pathname={pathname}
            authFetched={authFetched && websocketsFetched}
            onToggleSidebarHidden={toggleSidebarHidden}
            onToggleSidebarMobile={toggleSidebarMobile}
            onLogout={onLogout}
          />
        </Fragment>
      ) : (
        <Fragment>
          <Favicon url={MAIN_FAVICON_URL} />
          <Header
            pathname={pathname}
            authFetched={authFetched && websocketsFetched}
            onToggleSidebarHidden={toggleSidebarHidden}
            onToggleSidebarMobile={toggleSidebarMobile}
            onLogout={onLogout}
          />
        </Fragment>
      )}
      <div
        className={cl('page-content', {
          'sidebar-hidden': isSidebarHidden,
          'sidebar-mobile': isSidebarMobile
        })}
      >
        <div className="content-wrapper">
          <div className="content content-boxed">
            <Loading loaded={authFetched && websocketsFetched}>
              <Switch>
                {main}
                <Route key="error" render={props => <ErrorPage statusCode={404} {...props} />} />
              </Switch>
              <ScrollToTop showUnder={160} style={scrollToTopStyle}>
                <span className="note-icon-arrow-circle-up icon-2x" />
              </ScrollToTop>
            </Loading>
          </div>
          <Footer />
        </div>
      </div>
    </Fragment>
  );
}

MainAppContainer.propTypes = {
  currentUser: PropTypes.shape({ get: PropTypes.func }).isRequired,
  pathname: PropTypes.string.isRequired,
  authFetched: PropTypes.bool.isRequired,
  websocketsFetched: PropTypes.bool.isRequired,
  isSidebarHidden: PropTypes.bool.isRequired,
  isSidebarMobile: PropTypes.bool.isRequired,
  toggleSidebarHidden: PropTypes.func.isRequired,
  toggleSidebarMobile: PropTypes.func.isRequired,
  updateIntl: PropTypes.func.isRequired,
  validateToken: PropTypes.func.isRequired,
  initWebsockets: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  pushTo: PropTypes.func.isRequired
};

export default compose(
  connect(({ app: { pathname } }) => ({ pathname }), {
    updateIntl: updateIntlAction,
    pushTo: pushToAction
  }),
  withCurrentUser({
    fields: ['authFetched', 'websocketsFetched'],
    actions: ['validateToken', 'initWebsockets', 'logoutUser']
  }),
  lifecycle({
    componentDidMount() {
      const { validateToken, initWebsockets, logoutUser, updateIntl, pushTo } = this.props;

      // document.querySelector('body').classList.add('sidebar-xs');

      validateToken().then(({ currentUser }) => {
        if (currentUser) {
          initWebsockets();

          CozyStorage.setIsUserCozy(isUserCozy({ plainUserData: currentUser }));

          const locale = get(currentUser, 'locale') || 'en';

          return loadAllLocales(locale).then(messages => {
            updateIntl({
              locale,
              messages
            });

            profileLocaleInStorage.setLocale(locale);
          });
        }

        logoutUser();
        return pushTo('auth/login');
      });
    }
  }),
  withState('isSidebarHidden', 'setIsSidebarHidden', false),
  withState('isSidebarMobile', 'setIsSidebarMobile', false),
  withHandlers({
    toggleSidebarHidden: ({ isSidebarHidden, setIsSidebarHidden }) => () => setIsSidebarHidden(!isSidebarHidden),
    toggleSidebarMobile: ({ isSidebarMobile, setIsSidebarMobile }) => () => setIsSidebarMobile(!isSidebarMobile),
    onLogout: ({ logoutUser, pushTo }) => () => {
      logoutUser();
      pushTo('auth/login');
    }
  }),
  pure
)(MainAppContainer);
