import { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';

import {
  LinkMenuItems,
  DropdownMoreMenuItem,
  DropdownMoreMenuItems
} from '../../types';

import { useOnViewportChange } from '../../../../utils/hooks/useOnViewportChange';

const START_WRAP_VIEWPORT_WIDTH = 768;
const AVARAGE_ITEM_WIDTH = 120;
const SPACE_AROUND = 340;

// *** Helpers ===>

// Return new links array if wrap nedeed
// Else just return links array
function _wrapRestInMore(
  links: LinkMenuItems,
  maxItemsAmount: number
): DropdownMoreMenuItems {
  // Wrap unneded if maxItemsAmount lager than links amount
  const isNeedWrap = links.length <= maxItemsAmount;
  if (isNeedWrap) return links;

  // Index to begin wrap
  const wrapFromIndex = maxItemsAmount - 1;

  // Links to remain
  const unwrapLinks = links.slice(0, wrapFromIndex);

  // Links for wrap
  const linksForWrap = links.slice(wrapFromIndex);
  const moreWrap: DropdownMoreMenuItem = {
    type: 'dropdown',
    // Unite namespaces of all wrapped links
    namespace: linksForWrap.map(link => link.namespace).join('|'),
    label: 'words.more',
    // Set wrapped links for wrap
    links: linksForWrap
  };

  return [...unwrapLinks, moreWrap];
}

function _calcItemsAmount(): number {
  const windowWidth = window.innerWidth;

  if (windowWidth < START_WRAP_VIEWPORT_WIDTH) {
    return 99; // Just return big amount if mobile menu applied
  }

  const avalibaleSpace = windowWidth - SPACE_AROUND;

  const itemsAmount = Math.floor(avalibaleSpace / AVARAGE_ITEM_WIDTH);

  return itemsAmount;
}
// <=== Helpers ***

// *** Hook ===>
function useLinksWrapMore(links: LinkMenuItems): [DropdownMoreMenuItems] {
  const [linksWithMore, setLinksWithMore] = useState<DropdownMoreMenuItems>([]);
  const [maxItemsAmount, setMaxItemsAmount] = useState<number>(
    _calcItemsAmount()
  );

  const updateMaxItemsAmount = useCallback(
    debounce(() => {
      setMaxItemsAmount(_calcItemsAmount());
    }, 200),
    []
  );

  // Update items amoun on window change
  useOnViewportChange(updateMaxItemsAmount);

  // Initial max items amount calc
  useEffect(() => {
    updateMaxItemsAmount();
  }, [updateMaxItemsAmount]);

  // Reorganize links array if initial links or maxItemsAmount changed
  useEffect(() => {
    setLinksWithMore(_wrapRestInMore(links, maxItemsAmount));
  }, [links, maxItemsAmount]);

  return [linksWithMore];
}
// <=== Hook ***

export default useLinksWrapMore;
