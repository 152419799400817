export const FETCH_MESSAGES = 'FETCH_MESSAGES';
export const FETCH_MESSAGES_START = 'FETCH_MESSAGES_START';
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'FETCH_MESSAGES_ERROR';

export const FETCH_MESSAGE = 'FETCH_MESSAGE';
export const FETCH_MESSAGE_START = 'FETCH_MESSAGE_START';
export const FETCH_MESSAGE_SUCCESS = 'FETCH_MESSAGE_SUCCESS';
export const FETCH_MESSAGE_ERROR = 'FETCH_MESSAGE_ERROR';

export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export const CREATE_MESSAGE_START = 'CREATE_MESSAGE_START';
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS';
export const CREATE_MESSAGE_ERROR = 'CREATE_MESSAGE_ERROR';

export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const UPDATE_MESSAGE_START = 'UPDATE_MESSAGE_START';
export const UPDATE_MESSAGE_SUCCESS = 'UPDATE_MESSAGE_SUCCESS';
export const UPDATE_MESSAGE_ERROR = 'UPDATE_MESSAGE_ERROR';

export const TOGGLE_SELECTED_MESSAGE = 'TOGGLE_SELECTED_MESSAGE';
export const TOGGLE_SELECTED_MESSAGE_START = 'TOGGLE_SELECTED_MESSAGE_START';
export const TOGGLE_SELECTED_MESSAGE_SUCCESS = 'TOGGLE_SELECTED_MESSAGE_SUCCESS';
export const TOGGLE_SELECTED_MESSAGE_ERROR = 'TOGGLE_SELECTED_MESSAGE_ERROR';

export const READ_MESSAGE = 'READ_MESSAGE';
export const READ_MESSAGE_START = 'READ_MESSAGE_START';
export const READ_MESSAGE_SUCCESS = 'READ_MESSAGE_SUCCESS';
export const READ_MESSAGE_ERROR = 'READ_MESSAGE_ERROR';

export const FORWARD_MESSAGE = 'FORWARD_MESSAGE';
export const FORWARD_MESSAGE_START = 'FORWARD_MESSAGE_START';
export const FORWARD_MESSAGE_SUCCESS = 'FORWARD_MESSAGE_SUCCESS';
export const FORWARD_MESSAGE_ERROR = 'FORWARD_MESSAGE_ERROR';

export const UNREAD_MESSAGE = 'UNREAD_MESSAGE';
export const UNREAD_MESSAGE_START = 'UNREAD_MESSAGE_START';
export const UNREAD_MESSAGE_SUCCESS = 'UNREAD_MESSAGE_SUCCESS';
export const UNREAD_MESSAGE_ERROR = 'UNREAD_MESSAGE_ERROR';

export const READ_ALL_MESSAGES = 'READ_ALL_MESSAGES';
export const READ_ALL_MESSAGES_START = 'READ_ALL_MESSAGES_START';
export const READ_ALL_MESSAGES_SUCCESS = 'READ_ALL_MESSAGES_SUCCESS';
export const READ_ALL_MESSAGES_ERROR = 'READ_ALL_MESSAGES_ERROR';

export const DOWNLOAD_MESSAGES_XLS = 'DOWNLOAD_MESSAGES_XLS';
export const DOWNLOAD_MESSAGES_XLS_START = 'DOWNLOAD_MESSAGES_XLS_START';
export const DOWNLOAD_MESSAGES_XLS_SUCCESS = 'DOWNLOAD_MESSAGES_XLS_SUCCESS';
export const DOWNLOAD_MESSAGES_XLS_ERROR = 'DOWNLOAD_MESSAGES_XLS_ERROR';

export const DOWNLOAD_MESSAGE_FILE_ATTACHMENTS = 'DOWNLOAD_MESSAGE_FILE_ATTACHMENTS';
export const DOWNLOAD_MESSAGE_FILE_ATTACHMENTS_START = 'DOWNLOAD_MESSAGE_FILE_ATTACHMENTS_START';
export const DOWNLOAD_MESSAGE_FILE_ATTACHMENTS_SUCCESS = 'DOWNLOAD_MESSAGE_FILE_ATTACHMENTS_SUCCESS';
export const DOWNLOAD_MESSAGE_FILE_ATTACHMENTS_ERROR = 'DOWNLOAD_MESSAGE_FILE_ATTACHMENTS_ERROR';

export const DOWNLOAD_MESSAGE_SELECTED_PRODUCTS = 'DOWNLOAD_MESSAGE_SELECTED_PRODUCTS';
export const DOWNLOAD_MESSAGE_SELECTED_PRODUCTS_START = 'DOWNLOAD_MESSAGE_SELECTED_PRODUCTS_START';
export const DOWNLOAD_MESSAGE_SELECTED_PRODUCTS_SUCCESS = 'DOWNLOAD_MESSAGE_SELECTED_PRODUCTS_SUCCESS';
export const DOWNLOAD_MESSAGE_SELECTED_PRODUCTS_ERROR = 'DOWNLOAD_MESSAGE_SELECTED_PRODUCTS_ERROR';

export const DOWNLOAD_MESSAGE_SELECTED_TEMPLATES = 'DOWNLOAD_MESSAGE_SELECTED_TEMPLATES';
export const DOWNLOAD_MESSAGE_SELECTED_TEMPLATES_START = 'DOWNLOAD_MESSAGE_SELECTED_TEMPLATES_START';
export const DOWNLOAD_MESSAGE_SELECTED_TEMPLATES_SUCCESS = 'DOWNLOAD_MESSAGE_SELECTED_TEMPLATES_SUCCESS';
export const DOWNLOAD_MESSAGE_SELECTED_TEMPLATES_ERROR = 'DOWNLOAD_MESSAGE_SELECTED_TEMPLATES_ERROR';

export const SUBMIT_MESSAGE_FILE_ATTACHMENTS = 'SUBMIT_MESSAGE_FILE_ATTACHMENTS';
export const SUBMIT_MESSAGE_FILE_ATTACHMENTS_START = 'SUBMIT_MESSAGE_FILE_ATTACHMENTS_START';
export const SUBMIT_MESSAGE_FILE_ATTACHMENTS_SUCCESS = 'SUBMIT_MESSAGE_FILE_ATTACHMENTS_SUCCESS';
export const SUBMIT_MESSAGE_FILE_ATTACHMENTS_ERROR = 'SUBMIT_MESSAGE_FILE_ATTACHMENTS_ERROR';

export const SUBMIT_MESSAGE_SELECTED_PRODUCTS = 'SUBMIT_MESSAGE_SELECTED_PRODUCTS';
export const SUBMIT_MESSAGE_SELECTED_PRODUCTS_START = 'SUBMIT_MESSAGE_SELECTED_PRODUCTS_START';
export const SUBMIT_MESSAGE_SELECTED_PRODUCTS_SUCCESS = 'SUBMIT_MESSAGE_SELECTED_PRODUCTS_SUCCESS';
export const SUBMIT_MESSAGE_SELECTED_PRODUCTS_ERROR = 'SUBMIT_MESSAGE_SELECTED_PRODUCTS_ERROR';

export const SUBMIT_MESSAGE_SELECTED_TEMPLATES = 'SUBMIT_MESSAGE_SELECTED_TEMPLATES';
export const SUBMIT_MESSAGE_SELECTED_TEMPLATES_START = 'SUBMIT_MESSAGE_SELECTED_TEMPLATES_START';
export const SUBMIT_MESSAGE_SELECTED_TEMPLATES_SUCCESS = 'SUBMIT_MESSAGE_SELECTED_TEMPLATES_SUCCESS';
export const SUBMIT_MESSAGE_SELECTED_TEMPLATES_ERROR = 'SUBMIT_MESSAGE_SELECTED_TEMPLATES_ERROR';

export const CLEAR_MESSAGE_FILTERS_START = 'CLEAR_MESSAGE_FILTERS_START';
export const CLEAR_MESSAGE_FILTERS_SUCCESS = 'CLEAR_MESSAGE_FILTERS_SUCCESS';

export const CLEAR_MESSAGE_MODAL_VALUES = 'CLEAR_MESSAGE_MODAL_VALUES';

export const SOCKET_SUBSCRIBE_TO_MESSAGES_CHANNEL = 'SOCKET_SUBSCRIBE_TO_MESSAGES_CHANNEL';
export const SOCKET_UNSUBSCRIBE_FROM_MESSAGES_CHANNEL = 'SOCKET_UNSUBSCRIBE_FROM_MESSAGES_CHANNEL';

export const SOCKET_SUBSCRIBE_TO_DROPDOWN_MESSAGES_CHANNEL = 'SOCKET_SUBSCRIBE_TO_DROPDOWN_MESSAGES_CHANNEL';
export const SOCKET_UNSUBSCRIBE_FROM_DROPDOWN_MESSAGES_CHANNEL = 'SOCKET_UNSUBSCRIBE_FROM_DROPDOWN_MESSAGES_CHANNEL';

export const DROPDOWN_MESSAGES_CHANNEL_CREATED = 'DROPDOWN_MESSAGES_CHANNEL_CREATED';

export const SELECT_MESSAGE_AUTHOR_FORM = 'SELECT_MESSAGE_AUTHOR_FORM';
export const UPDATE_MESSAGE_SELECTED_PRODUCT = 'UPDATE_MESSAGE_SELECTED_PRODUCT';
export const UPDATE_MESSAGE_SELECTED_TEMPLATE = 'UPDATE_MESSAGE_SELECTED_TEMPLATE';
export const SUBMIT_FILE_ATTACHMENTS_MESSAGE_FORM = 'SUBMIT_FILE_ATTACHMENTS_MESSAGE_FORM';
export const FORWARD_MESSAGE_FORM = 'FORWARD_MESSAGE_FORM';
