import get from 'lodash/get';
import isObject from 'lodash/isObject';
import isFunction from 'lodash/isFunction';

import { fetchIndexData, addSubscriptions, removeSubscriptions, filterKeys } from './index';

function provideActions(options, actions = {}) {
  return filterKeys(options.actions, {
    [options.fetchItems]: props => () => fetchIndexData(props, options, {
      page: options.page || get(props[options.itemsMeta], 'page'),
      pageSize: options.pageSize || props.pageSize,
      withoutPagination: options.withoutPagination || props.withoutPagination,
      withoutTotal: options.withoutTotal || props.withoutTotal,
      filters: options.filters || { ...props.filters, ...props.initialFilters, ...options.initialFilters },
      persistFilters: false,
      namespace: options.namespace,
      serializer: options.serializer || props.serializer,
      params: props.params,
      loadMore: false
    }),
    [options.loadMoreItems]: props => () => fetchIndexData(props, options, {
      page: get(props[options.itemsMeta], 'page') + 1,
      pageSize: options.pageSize || props.pageSize,
      withoutPagination: options.withoutPagination || props.withoutPagination,
      withoutTotal: options.withoutTotal || props.withoutTotal,
      filters: options.filters || { ...get(props[options.itemsMeta], 'filters'), ...props.initialFilters, ...options.initialFilters },
      persistFilters: false,
      namespace: options.namespace,
      serializer: options.serializer || props.serializer,
      params: props.params,
      loadMore: true
    }),
    [options.changeItemsFilters]: props => (values, opts = { overrideFilters: false }, cb = null) => {
      removeSubscriptions(props, options, props[options.items]);
      fetchIndexData(props, options, {
        page: 1,
        pageSize: options.pageSize || props.pageSize,
        withoutPagination: options.withoutPagination || props.withoutPagination,
        withoutTotal: options.withoutTotal || props.withoutTotal,
        namespace: options.namespace,
        serializer: options.serializer || props.serializer,
        filters: options.filters || (get(opts, 'overrideFilters', false) ? values : { ...get(props[options.itemsMeta], 'filters'), ...values, ...props.initialFilters, ...options.initialFilters }),
        persistFilters: options.persistFilters
      }, cb);
    },
    [options.clearItemsFilters]: props => () => {
      props[options.clearItemsFilters]({ namespace: options.namespace });
      removeSubscriptions(props, options, props[options.items]);
      fetchIndexData(props, options, {
        page: 1,
        pageSize: options.pageSize || props.pageSize,
        withoutPagination: options.withoutPagination || props.withoutPagination,
        withoutTotal: options.withoutTotal || props.withoutTotal,
        filters: options.initialFilters || props.initialFilters || {},
        namespace: options.namespace,
        serializer: options.serializer || props.serializer,
        persistFilters: options.persistFilters
      });
    },
    [options.clearModalValues]: props => () => props[options.clearModalValues]({ namespace: options.namespace }),
    [options.fetchItem]: props => (itemOrId, opts = {}, cb) => props[options.fetchItem](isObject(itemOrId) ? itemOrId.id : itemOrId, {
      ...props.fetchOptions,
      ...options.fetchOptions,
      ...(props.params ? { params: props.params } : {}),
      ...(options.namespace ? { namespace: options.namespace } : {}),
      ...({ serializer: options.serializer || props.serializer }),
      ...opts
    }, cb),
    [options.createItem]: props => (values, cb, form) => vals => props[options.createItem](options.mergeCreateValues ? {
      ...(values || {}),
      ...(vals || {})
    } : (values || vals), {
      ...props.createOptions,
      ...options.createOptions,
      ...(options.namespace ? { namespace: options.namespace } : {}),
      ...(props.createNamespace ? { namespace: props.createNamespace } : {}),
      ...(props.serializer ? { serializer: props.serializer } : {}),
      ...(form ? { form } : {})
    }, item => {
      addSubscriptions(props, options, [item]);
      cb && cb(item);
      props.afterCreate && props.afterCreate(item);
    }),
    [options.updateItem]: props => (itemOrId, valuesOrCallback, callback) => (vals, cb, form) => props[options.updateItem](
      isObject(itemOrId) ? itemOrId.id : itemOrId,
      (isFunction(valuesOrCallback) ? null : valuesOrCallback) || vals,
      {
        ...props.updateOptions,
        ...options.updateOptions,
        ...(options.namespace ? { namespace: options.namespace } : {}),
        ...(props.updateNamespace ? { namespace: props.updateNamespace } : {}),
        ...(props.serializer ? { serializer: props.serializer } : {}),
        ...(options.serializer ? { serializer: options.serializer } : {}),
        form
      }, item => {
        (callback && isFunction(callback)) && callback(item);
        (valuesOrCallback && isFunction(valuesOrCallback)) && valuesOrCallback(item);
        cb && cb(item);
        props.afterUpdate && props.afterUpdate(item);
      }
    ),
    [options.removeItem]: props => (itemOrId, cb, opts = {}) => itm => props[options.removeItem](((isObject(itemOrId) ? itemOrId : { id: itemOrId }) || itm).id, {
      ...opts,
      ...props.removeOptions,
      ...options.removeOptions,
      ...(options.namespace ? { namespace: options.namespace } : {}),
      ...(props.removeNamespace ? { namespace: props.removeNamespace } : {})
    }, () => {
      cb && cb();
      props.afterRemove && props.afterRemove();
    }),
    [options.uploadItems]: props => (items, opts = {}, cb = null) => props[options.uploadItems](items, {
      ...opts,
      ...props.uploadOptions,
      ...options.uploadOptions,
      ...(options.namespace ? { namespace: options.namespace } : {}),
      ...(props.uploadNamespace ? { namespace: props.uploadNamespace } : {})
    }, () => {
      cb && cb();
      props.afterUpload && props.afterUpload();
    }),
    // [options.subscribeToItemsChannel]: props => items => items.map(item => props[options.subscribeToItemsChannel]('updated', item.id)),
    // [options.unsubscribeFromItemsChannel]: props => items => items.map(item => props[options.unsubscribeFromItemsChannel]('updated', item.id)),
    ...actions
  });
}

export default provideActions;
