import { FETCH_BILL } from '../BillConstants';

// fetchBill

export default function fetchBill(id, options = {}, cb) {
  return dispatch => dispatch({
    type: FETCH_BILL,
    id,
    options,
    cb
  });
}
