import React, { Fragment } from 'react';

// import dashboard from './dashboard/routes';
// import allocations from './allocations/routes';
// import bills from './bills/routes';
// import billRefills from './billRefills/routes';
// import refills from './refills/routes';
// import tags from './tags/routes';
// import transactions from './transactions/routes';
// import transactionTags from './transactionTags/routes';
// import contacts from './contacts/routes';
// import emailTemplates from './emailTemplates/routes';
// import sentMessages from './sentMessages/routes';
// import downloads from './downloads/routes';
// import reports from './reports/routes';
// import pages from './pages/routes';
// import roles from './roles/routes';
// import permissions from './permissions/routes';

import allocations from './ts/allocations/routes';
import batchOperations from './ts/batchOperations/routes';
import bills from './ts/bills/routes';
import billRefills from './ts/billRefills/routes';
import categories from './ts/categories/routes';
import contacts from './ts/contacts/routes';
import dashboard from './ts/dashboard/routes';
import downloads from './ts/downloads/routes';
import emailTemplates from './ts/emailTemplates/routes';
import imports from './ts/imports/routes';
import pages from './ts/pages/routes';
import roles from './ts/roles/routes';
import permissions from './ts/permissions/routes';
import promoCodes from './ts/promoCodes/routes';
import refills from './ts/refills/routes';
import reports from './ts/reports/routes';
import sections from './ts/sections/routes';
import sentMessages from './ts/sentMessages/routes';
import tags from './ts/tags/routes';
import transactions from './ts/transactions/routes';
import transactionTags from './ts/transactionTags/routes';

export default (
  <Fragment>
    {dashboard}
    {allocations}
    {batchOperations}
    {bills}
    {categories}
    {billRefills}
    {refills}
    {tags}
    {transactions}
    {transactionTags}
    {contacts}
    {emailTemplates}
    {sentMessages}
    {downloads}
    {reports}
    {pages}
    {roles}
    {permissions}
    {sections}
    {imports}
    {promoCodes}
  </Fragment>
);
