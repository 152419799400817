import get from 'lodash/get';

import { User } from './models';

import {
  AUTH_LOADING,
  AUTH_STOP_LOADING,
  AUTH_USER,
  UPDATE_CURRENT_USER,
  LOGOUT_USER,
  AUTH_ERROR,
  AUTH_SUCCESS_MESSAGE,
  SET_SUCCESS_MESSAGE,
  SET_ERROR_MESSAGE,
  // INIT_WEBSOCKETS_SUCCESS,
  INIT_ACTION_CABLE_SUCCESS
} from './AuthConstants';

import { FETCH_PROJECTS_SUCCESS, CLEAR_PROJECT_FILTERS_SUCCESS } from '../main/projects/ProjectConstants';
import { FETCH_TASKS_SUCCESS, CLEAR_TASK_FILTERS_SUCCESS } from '../main/tasks/TaskConstants';
import { DROPDOWN_MESSAGES_CHANNEL_CREATED, FETCH_MESSAGES_SUCCESS, CLEAR_MESSAGE_FILTERS_SUCCESS } from '../main/messages/MessageConstants';
import { FETCH_PRODUCTS_SUCCESS, CLEAR_PRODUCT_FILTERS_SUCCESS } from '../main/products/ProductConstants';
import { FETCH_TEMPLATES_SUCCESS, CLEAR_TEMPLATE_FILTERS_SUCCESS } from '../main/templates/TemplateConstants';
import { FETCH_USERS_SUCCESS, CLEAR_USER_FILTERS_SUCCESS } from '../main/users/UserConstants';

const defaultState = {
  authFetched: false,
  isAuthenticated: false,
  successMessage: null,
  errorMessage: null,
  currentUser: new User(),
  isLoading: false,

  currentUserProjectsFilters: {},
  currentUserTasksFilters: {},
  currentUserResultsFilters: {},
  currentUserMessagesFilters: {},
  currentUserProductsFilters: {},
  currentUserTemplatesFilters: {},
  currentUserAccountsFilters: {},
  currentUserWorkersFilters: {},
  currentUserClientsFilters: {},
  currentUserUsersFilters: {},

  socket$: null,
  websocketsFetched: false
};

export default function AuthReducer(state = defaultState, { type, currentUser, errorMessage, successMessage, actionCable, meta, options, data, ...props }) {
  switch (type) {
    case AUTH_LOADING:
      return { ...state, isLoading: true };
    case AUTH_STOP_LOADING:
      return { ...state, isLoading: false };
    case AUTH_USER:
      return {
        ...state,
        authFetched: true,
        isAuthenticated: true,
        isLoading: false,
        errorMessage: null,
        currentUser: new User(currentUser),

        currentUserProjectsFilters: get(currentUser, 'task_search.data') || {},
        currentUserTasksFilters: get(currentUser, 'discussion_search.data') || {},
        currentUserResultsFilters: get(currentUser, 'result_search.data') || {},
        currentUserMessagesFilters: get(currentUser, 'discussion_message_search.data') || {},
        currentUserProductsFilters: get(currentUser, 'facility_search.data') || {},
        currentUserTemplatesFilters: get(currentUser, 'scene_search.data') || {},
        currentUserAccountsFilters: get(currentUser, 'account_search.data') || {},
        currentUserWorkersFilters: get(currentUser, 'worker_search.data') || {},
        currentUserClientsFilters: get(currentUser, 'client_search.data') || {},
        currentUserUsersFilters: get(currentUser, 'user_search.data') || {}
      };
    case UPDATE_CURRENT_USER:
      return { ...state, currentUser: new User({ ...state.currentUser.currentUser, ...currentUser }) };
    case LOGOUT_USER:
      return { ...defaultState, authFetched: true, isAuthenticated: false };
    case AUTH_ERROR:
      return { ...state, isLoading: false, errorMessage, currentUser: new User({}), authFetched: true };
    case AUTH_SUCCESS_MESSAGE:
      return { ...state, successMessage, currentUser: new User({}) };
    case SET_SUCCESS_MESSAGE:
      return { ...state, successMessage, errorMessage: null };
    case SET_ERROR_MESSAGE:
      return { ...state, errorMessage, successMessage: null };
    // case INIT_WEBSOCKETS_SUCCESS:
    //   return { ...state, websocketsFetched: true, socket$ };
    case INIT_ACTION_CABLE_SUCCESS:
      return { ...state, websocketsFetched: true, actionCable };
    case DROPDOWN_MESSAGES_CHANNEL_CREATED:
      return { ...state, currentUser: new User({ ...state.currentUser.currentUser, users_unread_discussion_messages_count: get(data, 'count') }) };

    case FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        ...(get(options, 'persistFilters', false) ? {
          // currentUser: state.currentUser.updateFilter('task_search', get(meta, 'filters', {})),
          currentUserProjectsFilters: get(meta, 'filters', {})
        } : {})
      };

    case CLEAR_PROJECT_FILTERS_SUCCESS:
      return {
        ...state,
        ...(get(options, 'persistFilters', false) ? {
          // currentUser: state.currentUser.updateFilter('task_search', get(meta, 'filters', {})),
          currentUserProjectsFilters: {}
        } : {})
      };

    case FETCH_TASKS_SUCCESS:
      return {
        ...state,
        ...(get(options, 'persistFilters', false) ? {
          // currentUser: state.currentUser.updateFilter('discussion_search', get(meta, 'filters', {})),
          [options.serializer === 'task_results' ? 'currentUserResultsFilters' : 'currentUserTasksFilters']: get(meta, 'filters', {})
        } : {})
      };

    case CLEAR_TASK_FILTERS_SUCCESS:
      return {
        ...state,
        ...(get(options, 'persistFilters', false) ? {
          // currentUser: state.currentUser.updateFilter('discussion_search', get(meta, 'filters', {}))
          [options.serializer === 'task_results' ? 'currentUserResultsFilters' : 'currentUserTasksFilters']: {}
        } : {})
      };

    case FETCH_MESSAGES_SUCCESS:
      return {
        ...state,
        ...(get(options, 'persistFilters', false) ? {
          // currentUser: state.currentUser.updateFilter('discussion_message_search', get(meta, 'filters', {}))
          currentUserMessagesFilters: get(meta, 'filters', {})
        } : {})
      };

    case CLEAR_MESSAGE_FILTERS_SUCCESS:
      return {
        ...state,
        ...(get(options, 'persistFilters', false) ? {
          // currentUser: state.currentUser.updateFilter('discussion_message_search', get(meta, 'filters', {})),
          currentUserMessagesFilters: {}
        } : {})
      };

    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        ...(get(options, 'persistFilters', false) ? {
          // currentUser: state.currentUser.updateFilter('facility_search', get(meta, 'filters', {})),
          currentUserProductsFilters: get(meta, 'filters', {})
        } : {})
      };

    case CLEAR_PRODUCT_FILTERS_SUCCESS:
      return {
        ...state,
        ...(get(options, 'persistFilters', false) ? {
          // currentUser: state.currentUser.updateFilter('facility_search', get(meta, 'filters', {})),
          currentUserProductsFilters: {}
        } : {})
      };

    case FETCH_TEMPLATES_SUCCESS:
      return {
        ...state,
        ...(get(options, 'persistFilters', false) ? {
          // currentUser: state.currentUser.updateFilter('scene_search', get(meta, 'filters', {})),
          currentUserTemplatesFilters: get(meta, 'filters', {})
        } : {})
      };

    case CLEAR_TEMPLATE_FILTERS_SUCCESS:
      return {
        ...state,
        ...(get(options, 'persistFilters', false) ? {
          // currentUser: state.currentUser.updateFilter('scene_search', get(meta, 'filters', {}))
          currentUserTemplatesFilters: {}
        } : {})
      };


    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        ...(get(options, 'persistFilters', false) ? {
          // currentUser: state.currentUser.updateFilter('scene_search', get(meta, 'filters', {})),
          [
          (options.serializer === 'account' ? 'currentUserAccountsFilters' : null)
          || (options.serializer === 'worker' ? 'currentUserWorkersFilters' : null)
          || (options.serializer === 'client' ? 'currentUserClientsFilters' : null)
          || 'currentUserUsersFilters'
          ]: get(meta, 'filters', {})
        } : {})
      };

    case CLEAR_USER_FILTERS_SUCCESS:
      return {
        ...state,
        ...(get(options, 'persistFilters', false) ? {
          // currentUser: state.currentUser.updateFilter('scene_search', get(meta, 'filters', {}))
          [
          (options.serializer === 'account' ? 'currentUserAccountsFilters' : null)
          || (options.serializer === 'worker' ? 'currentUserClientsFilters' : null)
          || 'currentUserUsersFilters'
          ]: {}
        } : {})
      };

    default:
      return state;
  }
}
