import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import { LoadableWrapper } from '../../utils/LoadableWrapper';

const CheckoutSuccessPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "bills" */ './pages/CheckoutSuccessPage/CheckoutSuccessPage'
    )
});
const PaymentsSuccessPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "bills" */ './pages/PaymentsSuccessPage/PaymentsSuccessPage'
    )
});
const EasyPaySuccessPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "bills" */ './pages/EasyPaySuccessPage/EasyPaySuccessPage'
    )
});
const EasyPayErrorPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "bills" */ './pages/EasyPayErrorPage/EasyPayErrorPage'
    )
});
const FondySuccessPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "bills" */ './pages/FondySuccessPage/FondySuccessPage'
    )
});
const FondyErrorPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "bills" */ './pages/FondyErrorPage/FondyErrorPage'
    )
});
const BillShowPage = LoadableWrapper({
  loader: () =>
    import(/* webpackChunkName: "bills" */ './pages/BillShowPage/BillShowPage')
});
const ClientAccountBillShowPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "bills" */ './pages/ClientAccountBillShowPage/ClientAccountBillShowPage'
    )
});
// const BillsIndexPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "bills" */'./pages/BillsIndexPage/BillsIndexPage') });

export default (
  <Fragment>
    <Route exact path="/2checkout/success" component={CheckoutSuccessPage} />
    <Route exact path="/payments/success" component={PaymentsSuccessPage} />
    <Route
      exact
      path="/easypay/:billId(\d+)/success"
      component={EasyPaySuccessPage}
    />
    <Route
      exact
      path="/easypay/:billId(\d+)/error"
      component={EasyPayErrorPage}
    />
    <Route exact path="/fondy/success" component={FondySuccessPage} />
    <Route exact path="/fondy/error" component={FondyErrorPage} />
    <Route
      exact
      path="/client-account-bills/:billId(\d+)"
      component={ClientAccountBillShowPage}
    />
    <Route exact path="/bills/:billId(\d+)" component={BillShowPage} />
    {/* <Route exact path="/bills" component={BillsIndexPage} /> */}
  </Fragment>
);
