import React, { Fragment, ReactNode, useCallback, useState } from 'react';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import cl from 'classnames';
import isString from 'lodash/isString';

import { Button } from '../Button';
import { PureButton } from '../PureButton';
import { PureLinkTo, PureLinkToProps } from '../PureLinkTo';
import { TooltipProps } from '../Tooltip';

import { translate as t } from '../../../utils/ts/translate';

export interface ModalLinkProps extends PureLinkToProps, TooltipProps {
  disabled?: boolean;
  isLoading?: boolean;
  modalIcon?: string;
  modalTitle?: ReactNode;
  modalSize?: string;
  i18nModalTitle?: string;
  cancelI18nText?: string;
  cancelColor?: string;
  cancelDisabled?: boolean;
  i18nLinkText?: string;
  submitColor?: string;
  i18nSubmitText?: string;
  submitAddClass?: string | Array<{}> | Array<string>;
  submitDisabled?: boolean;
  submitIcon?: string | ReactNode;
  submitSize?: string;
  submitText?: string | ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
  onToggle?: (isOpen: boolean) => void;
  onSubmit?: () => Promise<void>;
  afterSubmit?: () => void | Promise<void>;
  children: ReactNode;
  beforeLink?: ReactNode;
  afterLink?: ReactNode;
  withoutCancel?: boolean;
}

function ModalLink({
  modalIcon,
  modalSize,
  modalTitle,
  i18nModalTitle,
  isLoading,
  disabled,
  cancelColor = 'white',
  cancelI18nText = 'words.cancel',
  cancelDisabled,
  i18nLinkText,
  submitColor = 'primary',
  i18nSubmitText = 'words.save',
  submitAddClass,
  submitDisabled,
  submitIcon,
  submitSize = 'sm',
  submitText,
  onOpen,
  onClose,
  onToggle,
  onSubmit,
  afterSubmit,
  children,
  beforeLink,
  afterLink,
  withoutCancel,
  ...linkProps
}: ModalLinkProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const showModal = useCallback(() => {
    setIsOpen(true);
    onOpen && onOpen();
    onToggle && onToggle(true);
  }, [setIsOpen, onOpen, onToggle]);

  const hideModal = useCallback(() => {
    setIsOpen(false);
    onClose && onClose();
    onToggle && onToggle(false);
  }, [setIsOpen, onClose, onToggle]);

  const handleSubmit = useCallback(() => {
    if (onSubmit) {
      onSubmit().then(() => {
        hideModal();
        afterSubmit && afterSubmit();
      });
    } else {
      hideModal();
      afterSubmit && afterSubmit();
    }
  }, [hideModal, onSubmit, afterSubmit]);

  return (
    <Fragment>
      {beforeLink}
      <PureLinkTo onClick={showModal} i18nText={i18nLinkText} {...linkProps} />
      {afterLink}

      <Modal isOpen={isOpen} toggle={hideModal} size={modalSize}>
        <ModalHeader toggle={hideModal}>
          <div className="modal-title text-muted font-size-base">
            <i className={cl(modalIcon, 'mr-2')} />
            {i18nModalTitle ? t(i18nModalTitle) : modalTitle}
          </div>
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter className="card-footer py-2 justify-content-between border-top">
          <div />
          <div className="btn-group">
            {!withoutCancel && (
              <PureButton
                disabled={isLoading || disabled || cancelDisabled}
                color={cancelColor}
                icon="times-circle"
                i18nText={cancelI18nText}
                size={submitSize}
                onClick={hideModal}
              />
            )}
            {submitText ? (
              <Button
                addClass={submitAddClass}
                disabled={isLoading || disabled || submitDisabled}
                color={submitColor}
                icon={
                  isString(submitIcon) ? (
                    <i className={submitIcon} />
                  ) : (
                    submitIcon
                  )
                }
                size={submitSize}
                onClick={handleSubmit}
              >
                {isLoading ? t('messages.processing') : submitText}
              </Button>
            ) : (
              <PureButton
                addClass={submitAddClass}
                disabled={isLoading || disabled || submitDisabled}
                color={submitColor}
                icon={
                  isString(submitIcon) ? (
                    <i className={submitIcon} />
                  ) : (
                    submitIcon
                  )
                }
                size={submitSize}
                i18nText={isLoading ? 'messages.processing' : i18nSubmitText}
                onClick={handleSubmit}
              />
            )}
          </div>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}

export default ModalLink;
