export const FETCH_FILE_ATTACHMENTS = 'FETCH_FILE_ATTACHMENTS';
export const FETCH_FILE_ATTACHMENTS_START = 'FETCH_FILE_ATTACHMENTS_START';
export const FETCH_FILE_ATTACHMENTS_SUCCESS = 'FETCH_FILE_ATTACHMENTS_SUCCESS';
export const FETCH_FILE_ATTACHMENTS_ERROR = 'FETCH_FILE_ATTACHMENTS_ERROR';

export const SET_FILE_ATTACHMENTS_FETCHED = 'SET_FILE_ATTACHMENTS_FETCHED';

export const UPDATE_FILE_ATTACHMENT = 'UPDATE_FILE_ATTACHMENT';
export const UPDATE_FILE_ATTACHMENT_START = 'UPDATE_FILE_ATTACHMENT_START';
export const UPDATE_FILE_ATTACHMENT_SUCCESS = 'UPDATE_FILE_ATTACHMENT_SUCCESS';
export const UPDATE_FILE_ATTACHMENT_ERROR = 'UPDATE_FILE_ATTACHMENT_ERROR';

// export const DOWNLOAD_FILE_ATTACHMENT = 'DOWNLOAD_FILE_ATTACHMENT';
// export const DOWNLOAD_FILE_ATTACHMENT_START = 'DOWNLOAD_FILE_ATTACHMENT_START';
// export const DOWNLOAD_FILE_ATTACHMENT_SUCCESS = 'DOWNLOAD_FILE_ATTACHMENT_SUCCESS';
// export const DOWNLOAD_FILE_ATTACHMENT_ERROR = 'DOWNLOAD_FILE_ATTACHMENT_ERROR';

export const UPLOAD_FILE_ATTACHMENTS = 'UPLOAD_FILE_ATTACHMENTS';
export const UPLOAD_FILE_ATTACHMENTS_START = 'UPLOAD_FILE_ATTACHMENTS_START';
export const UPLOAD_FILE_ATTACHMENTS_SUCCESS = 'UPLOAD_FILE_ATTACHMENTS_SUCCESS';
export const UPLOAD_FILE_ATTACHMENTS_ERROR = 'UPLOAD_FILE_ATTACHMENTS_ERROR';
export const UPLOAD_FILE_ATTACHMENT_START = 'UPLOAD_FILE_ATTACHMENT_START';
export const UPLOAD_FILE_ATTACHMENT_SUCCESS = 'UPLOAD_FILE_ATTACHMENT_SUCCESS';
export const UPLOAD_FILE_ATTACHMENT_ERROR = 'UPLOAD_FILE_ATTACHMENT_ERROR';

export const REMOVE_FILE_ATTACHMENT = 'REMOVE_FILE_ATTACHMENT';
export const REMOVE_FILE_ATTACHMENT_START = 'REMOVE_FILE_ATTACHMENT_START';
export const REMOVE_FILE_ATTACHMENT_SUCCESS = 'REMOVE_FILE_ATTACHMENT_SUCCESS';
export const REMOVE_FILE_ATTACHMENT_ERROR = 'REMOVE_FILE_ATTACHMENT_ERROR';

export const ATTACH_FILE_ATTACHMENTS = 'ATTACH_FILE_ATTACHMENTS';
export const ATTACH_FILE_ATTACHMENTS_START = 'ATTACH_FILE_ATTACHMENTS_START';
export const ATTACH_FILE_ATTACHMENTS_SUCCESS = 'ATTACH_FILE_ATTACHMENTS_SUCCESS';
export const ATTACH_FILE_ATTACHMENTS_ERROR = 'ATTACH_FILE_ATTACHMENTS_ERROR';

export const CLEAR_FILE_ATTACHMENT_MODAL_VALUES = 'CLEAR_FILE_ATTACHMENT_MODAL_VALUES';

export const CLEAR_FILE_ATTACHMENTS_FILTERS_START = 'CLEAR_FILE_ATTACHMENTS_FILTERS_START';
export const CLEAR_FILE_ATTACHMENTS_FILTERS_SUCCESS = 'CLEAR_FILE_ATTACHMENTS_FILTERS_SUCCESS';

export const SOCKET_SUBSCRIBE_TO_FILE_ATTACHMENTS_CHANNEL = 'SOCKET_SUBSCRIBE_TO_FILE_ATTACHMENTS_CHANNEL';
export const SOCKET_UNSUBSCRIBE_FROM_FILE_ATTACHMENTS_CHANNEL = 'SOCKET_UNSUBSCRIBE_FROM_FILE_ATTACHMENTS_CHANNEL';
