import React, { memo } from 'react';
import get from 'lodash/get';

import { ID } from '../../../../../types';

import { usePage } from '../../../../common/hooks/pages/usePage';
import { Loading } from '../../../../../helpers/ts/Loading';
import { AlertMessage } from '../../../../../helpers/ts/AlertMessage';

interface PageRenderProps {
  id: ID;
}

function PageRender({ id }: PageRenderProps) {
  const { page, pageFetched, pageErrorMessage } = usePage({ id });

  const body = get(page, 'localized_body') || get(page, 'body');

  return (
    <Loading loaded={pageFetched}>
      <AlertMessage message={pageErrorMessage} />
      {body ? <div dangerouslySetInnerHTML={{ __html: body }} /> : null}
    </Loading>
  );
}

export default memo(PageRender);
