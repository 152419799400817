import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';

import { PureLinkTo } from '../../helpers/ts/PureLinkTo';

function LinkMenuItem({ link, pathname, linkPrefix }) {
  const { href, namespace, label } = link;

  return (
    <Fragment>
      <PureLinkTo
        className={cl('navbar-nav-link', {
          active:
            new RegExp(`^/${linkPrefix}${namespace}`).test(pathname) ||
            (pathname === '/' && namespace === 'dashboard')
        })}
        href={href}
        i18nText={label}
      />
    </Fragment>
  );
}

LinkMenuItem.propTypes = {
  pathname: PropTypes.string.isRequired,
  link: PropTypes.shape({
    href: PropTypes.string,
    namespace: PropTypes.string,
    label: PropTypes.string
  }).isRequired,
  linkPrefix: PropTypes.string.isRequired
};

export default memo(LinkMenuItem);
