export default {
  plural: 'Files',
  plural_lower_case: 'files',
  singular: 'File',
  singular_lower_case: 'file',
  add: 'Add File',
  select: 'Select file',
  source: 'Source files',
  no: 'No files',
  upload: 'Upload new file'
};
