import { takeEvery } from 'redux-saga/effects';

import { DROPDOWN_DOWNLOADS_CHANNEL_CREATED } from '../DownloadConstants';

import { downloadFile } from '../../../utils/downloadFile';

function* dropdownDownloadsChannelCreated({ data }) {
  if (window.downloadNanoId === data.device_nano_id) {
    yield downloadFile(data.file, data.name, { withLink: true });
  }
}

export default function* watchDropdownDownloadsChannelCreated() {
  yield takeEvery(
    DROPDOWN_DOWNLOADS_CHANNEL_CREATED,
    dropdownDownloadsChannelCreated
  );
}
