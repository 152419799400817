export const FETCH_PROJECTS = 'FETCH_PROJECTS';
export const FETCH_PROJECTS_START = 'FETCH_PROJECTS_START';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_ERROR = 'FETCH_PROJECTS_ERROR';

export const FETCH_PROJECT = 'FETCH_PROJECT';
export const FETCH_PROJECT_START = 'FETCH_PROJECT_START';
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS';
export const FETCH_PROJECT_ERROR = 'FETCH_PROJECT_ERROR';

export const CREATE_PROJECT = 'CREATE_PROJECT';
export const CREATE_PROJECT_START = 'CREATE_PROJECT_START';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_ERROR = 'CREATE_PROJECT_ERROR';
export const CREATE_PROJECT_FORM = 'CREATE_PROJECT_FORM';

export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const UPDATE_PROJECT_START = 'UPDATE_PROJECT_START';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_ERROR = 'UPDATE_PROJECT_ERROR';

export const TOGGLE_SELECTED_PROJECT = 'TOGGLE_SELECTED_PROJECT';
export const TOGGLE_SELECTED_PROJECT_START = 'TOGGLE_SELECTED_PROJECT_START';
export const TOGGLE_SELECTED_PROJECT_SUCCESS = 'TOGGLE_SELECTED_PROJECT_SUCCESS';
export const TOGGLE_SELECTED_PROJECT_ERROR = 'TOGGLE_SELECTED_PROJECT_ERROR';

export const ACCEPT_INVITE_TO_PROJECT = 'ACCEPT_INVITE_TO_PROJECT';
export const ACCEPT_INVITE_TO_PROJECT_START = 'ACCEPT_INVITE_TO_PROJECT_START';
export const ACCEPT_INVITE_TO_PROJECT_SUCCESS = 'ACCEPT_INVITE_TO_PROJECT_SUCCESS';
export const ACCEPT_INVITE_TO_PROJECT_ERROR = 'ACCEPT_INVITE_TO_PROJECT_ERROR';

export const INVITE_USERS_TO_PROJECT = 'INVITE_USERS_TO_PROJECT';
export const INVITE_USERS_TO_PROJECT_START = 'INVITE_USERS_TO_PROJECT_START';
export const INVITE_USERS_TO_PROJECT_SUCCESS = 'INVITE_USERS_TO_PROJECT_SUCCESS';
export const INVITE_USERS_TO_PROJECT_ERROR = 'INVITE_USERS_TO_PROJECT_ERROR';

export const REMOVE_USERS_FROM_PROJECT = 'REMOVE_USERS_FROM_PROJECT';
export const REMOVE_USERS_FROM_PROJECT_START = 'REMOVE_USERS_FROM_PROJECT_START';
export const REMOVE_USERS_FROM_PROJECT_SUCCESS = 'REMOVE_USERS_FROM_PROJECT_SUCCESS';
export const REMOVE_USERS_FROM_PROJECT_ERROR = 'REMOVE_USERS_FROM_PROJECT_ERROR';

export const DOWNLOAD_PROJECT_RESULTS = 'DOWNLOAD_PROJECT_RESULTS';
export const DOWNLOAD_PROJECT_RESULTS_START = 'DOWNLOAD_PROJECT_RESULTS_START';
export const DOWNLOAD_PROJECT_RESULTS_SUCCESS = 'DOWNLOAD_PROJECT_RESULTS_SUCCESS';
export const DOWNLOAD_PROJECT_RESULTS_ERROR = 'DOWNLOAD_PROJECT_RESULTS_ERROR';

export const DOWNLOAD_PROJECT_FILE_ATTACHMENTS = 'DOWNLOAD_PROJECT_FILE_ATTACHMENTS';
export const DOWNLOAD_PROJECT_FILE_ATTACHMENTS_START = 'DOWNLOAD_PROJECT_FILE_ATTACHMENTS_START';
export const DOWNLOAD_PROJECT_FILE_ATTACHMENTS_SUCCESS = 'DOWNLOAD_PROJECT_FILE_ATTACHMENTS_SUCCESS';
export const DOWNLOAD_PROJECT_FILE_ATTACHMENTS_ERROR = 'DOWNLOAD_PROJECT_FILE_ATTACHMENTS_ERROR';

export const DOWNLOAD_PROJECT_SELECTED_PRODUCTS = 'DOWNLOAD_PROJECT_SELECTED_PRODUCTS';
export const DOWNLOAD_PROJECT_SELECTED_PRODUCTS_START = 'DOWNLOAD_PROJECT_SELECTED_PRODUCTS_START';
export const DOWNLOAD_PROJECT_SELECTED_PRODUCTS_SUCCESS = 'DOWNLOAD_PROJECT_SELECTED_PRODUCTS_SUCCESS';
export const DOWNLOAD_PROJECT_SELECTED_PRODUCTS_ERROR = 'DOWNLOAD_PROJECT_SELECTED_PRODUCTS_ERROR';

export const DOWNLOAD_PROJECT_SELECTED_TEMPLATES = 'DOWNLOAD_PROJECT_SELECTED_TEMPLATES';
export const DOWNLOAD_PROJECT_SELECTED_TEMPLATES_START = 'DOWNLOAD_PROJECT_SELECTED_TEMPLATES_START';
export const DOWNLOAD_PROJECT_SELECTED_TEMPLATES_SUCCESS = 'DOWNLOAD_PROJECT_SELECTED_TEMPLATES_SUCCESS';
export const DOWNLOAD_PROJECT_SELECTED_TEMPLATES_ERROR = 'DOWNLOAD_PROJECT_SELECTED_TEMPLATES_ERROR';

export const DOWNLOAD_PROJECT_SOURCE_FILES = 'DOWNLOAD_PROJECT_SOURCE_FILES';
export const DOWNLOAD_PROJECT_SOURCE_FILES_START = 'DOWNLOAD_PROJECT_SOURCE_FILES_START';
export const DOWNLOAD_PROJECT_SOURCE_FILES_SUCCESS = 'DOWNLOAD_PROJECT_SOURCE_FILES_SUCCESS';
export const DOWNLOAD_PROJECT_SOURCE_FILES_ERROR = 'DOWNLOAD_PROJECT_SOURCE_FILES_ERROR';

export const SUBMIT_PROJECT_FILE_ATTACHMENTS = 'SUBMIT_PROJECT_FILE_ATTACHMENTS';
export const SUBMIT_PROJECT_FILE_ATTACHMENTS_START = 'SUBMIT_PROJECT_FILE_ATTACHMENTS_START';
export const SUBMIT_PROJECT_FILE_ATTACHMENTS_SUCCESS = 'SUBMIT_PROJECT_FILE_ATTACHMENTS_SUCCESS';
export const SUBMIT_PROJECT_FILE_ATTACHMENTS_ERROR = 'SUBMIT_PROJECT_FILE_ATTACHMENTS_ERROR';

export const SUBMIT_PROJECT_SELECTED_PRODUCTS = 'SUBMIT_PROJECT_SELECTED_PRODUCTS';
export const SUBMIT_PROJECT_SELECTED_PRODUCTS_START = 'SUBMIT_PROJECT_SELECTED_PRODUCTS_START';
export const SUBMIT_PROJECT_SELECTED_PRODUCTS_SUCCESS = 'SUBMIT_PROJECT_SELECTED_PRODUCTS_SUCCESS';
export const SUBMIT_PROJECT_SELECTED_PRODUCTS_ERROR = 'SUBMIT_PROJECT_SELECTED_PRODUCTS_ERROR';

export const SUBMIT_PROJECT_SELECTED_TEMPLATES = 'SUBMIT_PROJECT_SELECTED_TEMPLATES';
export const SUBMIT_PROJECT_SELECTED_TEMPLATES_START = 'SUBMIT_PROJECT_SELECTED_TEMPLATES_START';
export const SUBMIT_PROJECT_SELECTED_TEMPLATES_SUCCESS = 'SUBMIT_PROJECT_SELECTED_TEMPLATES_SUCCESS';
export const SUBMIT_PROJECT_SELECTED_TEMPLATES_ERROR = 'SUBMIT_PROJECT_SELECTED_TEMPLATES_ERROR';

export const CLEAR_PROJECT_FILTERS_START = 'CLEAR_PROJECT_FILTERS_START';
export const CLEAR_PROJECT_FILTERS_SUCCESS = 'CLEAR_PROJECT_FILTERS_SUCCESS';

export const CLEAR_PROJECT_MODAL_VALUES = 'CLEAR_PROJECT_MODAL_VALUES';

export const CREATE_PROJECT_BILLS = 'CREATE_PROJECT_BILLS';
export const CREATE_PROJECT_BILLS_START = 'CREATE_PROJECT_BILLS_START';
export const CREATE_PROJECT_BILLS_SUCCESS = 'CREATE_PROJECT_BILLS_SUCCESS';
export const CREATE_PROJECT_BILLS_ERROR = 'CREATE_PROJECT_BILLS_ERROR';

export const CREATE_PROJECTS_BILLS = 'CREATE_PROJECTS_BILLS';
export const CREATE_PROJECTS_BILLS_START = 'CREATE_PROJECTS_BILLS_START';
export const CREATE_PROJECTS_BILLS_SUCCESS = 'CREATE_PROJECTS_BILLS_SUCCESS';
export const CREATE_PROJECTS_BILLS_ERROR = 'CREATE_PROJECTS_BILLS_ERROR';

export const UPDATE_PROJECTS_AMOUNTS = 'UPDATE_PROJECTS_AMOUNTS';
export const UPDATE_PROJECTS_AMOUNTS_START = 'UPDATE_PROJECTS_AMOUNTS_START';
export const UPDATE_PROJECTS_AMOUNTS_SUCCESS = 'UPDATE_PROJECTS_AMOUNTS_SUCCESS';
export const UPDATE_PROJECTS_AMOUNTS_ERROR = 'UPDATE_PROJECTS_AMOUNTS_ERROR';

export const ASSIGN_PROJECT_TO_USER = 'ASSIGN_PROJECT_TO_USER';
export const ASSIGN_PROJECT_TO_USER_START = 'ASSIGN_PROJECT_TO_USER_START';
export const ASSIGN_PROJECT_TO_USER_SUCCESS = 'ASSIGN_PROJECT_TO_USER_SUCCESS';
export const ASSIGN_PROJECT_TO_USER_ERROR = 'ASSIGN_PROJECT_TO_USER_ERROR';

export const RECALCULATE_PROJECT_ACCOUNT = 'RECALCULATE_PROJECT_ACCOUNT';
export const RECALCULATE_PROJECT_ACCOUNT_START = 'RECALCULATE_PROJECT_ACCOUNT_START';
export const RECALCULATE_PROJECT_ACCOUNT_SUCCESS = 'RECALCULATE_PROJECT_ACCOUNT_SUCCESS';
export const RECALCULATE_PROJECT_ACCOUNT_ERROR = 'RECALCULATE_PROJECT_ACCOUNT_ERROR';

export const TOGGLE_PROJECT_NOTIFICATIONS = 'TOGGLE_PROJECT_NOTIFICATIONS';
export const TOGGLE_PROJECT_NOTIFICATIONS_START = 'TOGGLE_PROJECT_NOTIFICATIONS_START';
export const TOGGLE_PROJECT_NOTIFICATIONS_SUCCESS = 'TOGGLE_PROJECT_NOTIFICATIONS_SUCCESS';
export const TOGGLE_PROJECT_NOTIFICATIONS_ERROR = 'TOGGLE_PROJECT_NOTIFICATIONS_ERROR';

export const DOWNLOAD_PROJECTS_XLS = 'DOWNLOAD_PROJECTS_XLS';
export const DOWNLOAD_PROJECTS_XLS_START = 'DOWNLOAD_PROJECTS_XLS_START';
export const DOWNLOAD_PROJECTS_XLS_SUCCESS = 'DOWNLOAD_PROJECTS_XLS_SUCCESS';
export const DOWNLOAD_PROJECTS_XLS_ERROR = 'DOWNLOAD_PROJECTS_XLS_ERROR';

export const SOCKET_SUBSCRIBE_TO_PROJECTS_CHANNEL = 'SOCKET_SUBSCRIBE_TO_PROJECTS_CHANNEL';
export const SOCKET_UNSUBSCRIBE_FROM_PROJECTS_CHANNEL = 'SOCKET_UNSUBSCRIBE_FROM_PROJECTS_CHANNEL';

export const PROJECT_DETAILS_FORM = 'PROJECT_DETAILS_FORM';
export const PROJECT_OVERVIEW_FORM = 'PROJECT_OVERVIEW_FORM';
export const SELECT_PROJECT_TEAM_MEMBER_FORM = 'SELECT_PROJECT_TEAM_MEMBER_FORM';
export const PROJECT_LIST_TABLE_FORM = 'PROJECT_LIST_TABLE_FORM';
export const SEND_PROJECT_INVOICE_FORM = 'SEND_PROJECT_INVOICE_FORM';
export const PROJECT_UPDATE_COST_FORM = 'PROJECT_UPDATE_COST_FORM';
export const PROJECT_TASKS_FILE_ATTACHMENTS_FORM = 'PROJECT_TASKS_FILE_ATTACHMENTS_FORM';
export const PROJECT_TASKS_SELECTED_PRODUCTS_FORM = 'PROJECT_TASKS_SELECTED_PRODUCTS_FORM';
export const PROJECT_TASKS_SELECTED_PRODUCT_UPDATE_FORM = 'PROJECT_TASKS_SELECTED_PRODUCT_UPDATE_FORM';
export const PROJECT_TASKS_SELECTED_TEMPLATES_FORM = 'PROJECT_TASKS_SELECTED_TEMPLATES_FORM';
export const PROJECT_TASKS_SELECTED_TEMPLATE_UPDATE_FORM = 'PROJECT_TASKS_SELECTED_TEMPLATE_UPDATE_FORM';
export const PROJECT_TASKS_SOURCE_FILES_FORM = 'PROJECT_TASKS_SOURCE_FILES_FORM';
export const PROJECT_TASKS_RESULTS_FORM = 'PROJECT_TASKS_RESULTS_FORM';
export const PROJECT_CREATE_MESSAGE_FORM = 'PROJECT_CREATE_MESSAGE_FORM';
export const SUBMIT_PROJECT_FILE_ATTACHMENTS_FORM = 'SUBMIT_PROJECT_FILE_ATTACHMENTS_FORM';

export const PROJECT_DUPLICATE_SELECTED_SOURCE_FILES_FORM = 'PROJECT_DUPLICATE_SELECTED_SOURCE_FILES_FORM';
export const PROJECT_DUPLICATE_SELECTED_FILES_FORM = 'PROJECT_DUPLICATE_SELECTED_FILES_FORM';
export const PROJECT_DUPLICATE_SELECTED_IMAGES_FORM = 'PROJECT_DUPLICATE_SELECTED_IMAGES_FORM';
export const PROJECT_DUPLICATE_SELECTED_PRODUCTS_FORM = 'PROJECT_DUPLICATE_SELECTED_PRODUCTS_FORM';
export const PROJECT_DUPLICATE_SELECTED_TEMPLATES_FORM = 'PROJECT_DUPLICATE_SELECTED_TEMPLATES_FORM';
