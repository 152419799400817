import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { LoadableWrapper } from '../../utils/LoadableWrapper';

const UserProfilePage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "profile" */'./pages/UserProfilePage/UserProfilePage') });
const UserShowPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users" */'./pages/UserShowPage/UserShowPage') });
const UserProfileFinancePage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "profile" */'./pages/UserProfileFinancePage/UserProfileFinancePage') });
const UserProfileProjectProfilePage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "profile" */'./pages/UserProfileProjectProfilePage/UserProfileProjectProfilePage') });
const UserProfileSettingsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "profile" */'./pages/UserProfileSettingsPage/UserProfileSettingsPage') });
const UserProfileAcceptInvitePage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "profile" */'./pages/UserProfileAcceptInvitePage/UserProfileAcceptInvitePage') });
const UserProfileAcceptRequestPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "profile" */'./pages/UserProfileAcceptRequestPage/UserProfileAcceptRequestPage') });
const UserShowProjectProfilePage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users" */'./pages/UserShowProjectProfilePage/UserShowProjectProfilePage') });
const UserShowFinancePage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users" */'./pages/UserShowFinancePage/UserShowFinancePage') });
const UserShowSettingsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users" */'./pages/UserShowSettingsPage/UserShowSettingsPage') });
const UserProfileDebtPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "profile" */'./pages/UserProfileDebtPage/UserProfileDebtPage') });
const UserProfileBillsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "profile" */'./pages/UserProfileBillsPage/UserProfileBillsPage') });
const UserProfileRefillsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "profile" */'./pages/UserProfileRefillsPage/UserProfileRefillsPage') });
const UserProfilePendingPaymentsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "profile" */'./pages/UserProfilePendingPaymentsPage/UserProfilePendingPaymentsPage') });
const UserProfileRevenueSharePage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "profile" */'./pages/UserProfileRevenueSharePage/UserProfileRevenueSharePage') });
const UserProfileAccessPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "profile" */'./pages/UserProfileAccessPage/UserProfileAccessPage') });
const UserShowDebtPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users" */'./pages/UserShowDebtPage/UserShowDebtPage') });
const UserShowBillsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users" */'./pages/UserShowBillsPage/UserShowBillsPage') });
const UserShowRefillsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users" */'./pages/UserShowRefillsPage/UserShowRefillsPage') });
const UserShowPendingPaymentsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users" */'./pages/UserShowPendingPaymentsPage/UserShowPendingPaymentsPage') });
const UserProfileRecordsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "profile" */'./pages/UserProfileRecordsPage/UserProfileRecordsPage') });
const UserShowRecordsPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users" */'./pages/UserShowRecordsPage/UserShowRecordsPage') });
const UserShowRevenueSharePage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users" */'./pages/UserShowRevenueSharePage/UserShowRevenueSharePage') });
const UserShowAccessPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users" */'./pages/UserShowAccessPage/UserShowAccessPage') });
const AccountsIndexPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users" */'./pages/AccountsIndexPage/AccountsIndexPage') });
const WorkersIndexPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users" */'./pages/WorkersIndexPage/WorkersIndexPage') });
const UserRegisterWorkerPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users" */'./pages/UserRegisterWorkerPage/UserRegisterWorkerPage') });
const ClientsIndexPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "users" */'./pages/ClientsIndexPage/ClientsIndexPage') });

export default (
  <Fragment>
    <Route exact path="/profile/records" component={UserProfileRecordsPage} />
    <Route exact path="/profile/pending-payments/:tab(bad-debt|debt|underpayment)?" component={UserProfilePendingPaymentsPage} />
    <Route exact path="/profile/debt" component={UserProfileDebtPage} />
    <Route exact path="/profile/bills" component={UserProfileBillsPage} />
    <Route exact path="/profile/refills" component={UserProfileRefillsPage} />
    <Route exact path="/profile/revenue-share/:tab(worker)?" component={UserProfileRevenueSharePage} />
    <Route
      exact
      path="/profile/project-profile/:tab(products|templates|files|description)?"
      render={({ location: { pathname } }) => (
        <Redirect to={pathname.replace('project-profile', 'client-profile')} />
      )}
    />
    <Route exact path="/profile/client-profile/:tab(products|templates|files|description)?" component={UserProfileProjectProfilePage} />
    <Route exact path="/profile/finance/:tab(debit|credit|bonus|project|task|amount|prepaid)?" component={UserProfileFinancePage} />
    <Route exact path="/profile/settings/:tab(notifications|email|password|system|block|delete)?" component={UserProfileSettingsPage} />
    <Route exact path="/profile/access/:tab(received|team)?" component={UserProfileAccessPage} />
    <Route exact path="/profile/:linkedAccountInviteId/accept-invite" component={UserProfileAcceptInvitePage} />
    <Route exact path="/profile/:linkedAccountRequestId/accept-request" component={UserProfileAcceptRequestPage} />
    <Route exact path="/profile/:tab(description|fields|payoneer)?" component={UserProfilePage} />
    <Route exact path="/users/:userId(\d+)/records" component={UserShowRecordsPage} />
    <Route exact path="/users/:userId(\d+)/pending-payments/:tab(bad-debt|debt|underpayment)?" component={UserShowPendingPaymentsPage} />
    <Route exact path="/users/:userId(\d+)/debt" component={UserShowDebtPage} />
    <Route exact path="/users/:userId(\d+)/bills" component={UserShowBillsPage} />
    <Route exact path="/users/:userId(\d+)/refills" component={UserShowRefillsPage} />
    <Route exact path="/users/:userId(\d+)/revenue-share/:tab(worker)?" component={UserShowRevenueSharePage} />
    <Route
      exact
      path="/users/:userId(\d+)/project-profile/:tab(products|templates|files|description)?"
      render={({ location: { pathname } }) => (
        <Redirect to={pathname.replace('project-profile', 'client-profile')} />
      )}
    />
    <Route exact path="/users/:userId(\d+)/client-profile/:tab(products|templates|files|description)?" component={UserShowProjectProfilePage} />
    <Route exact path="/users/:userId(\d+)/finance/:tab(debit|credit|bonus|project|task|amount|prepaid)?" component={UserShowFinancePage} />
    <Route exact path="/users/:userId(\d+)/settings/:tab(notifications|email|password|system|block|delete)?" component={UserShowSettingsPage} />
    <Route exact path="/users/:userId(\d+)/access/:tab(received|team)?" component={UserShowAccessPage} />
    <Route exact path="/users/:userId(\d+)/:tab(description|fields|payoneer)?" component={UserShowPage} />
    <Route exact path="/accounts" component={AccountsIndexPage} />
    <Route exact path="/workers" component={WorkersIndexPage} />
    <Route exact path="/workers/register" component={UserRegisterWorkerPage} />
    <Route exact path="/clients" component={ClientsIndexPage} />
  </Fragment>
);
