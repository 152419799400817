import React, { memo } from 'react';

import { CurrentUser } from '../../../../../types';

import { LinkMenu } from '../../../../../helpers/LinkMenu';

const links = [
  {
    type: 'link',
    namespace: 'makeover',
    href: 'makeover',
    label: 'words.new_design'
  },
  // {
  //   type: 'link',
  //   namespace: 'projects',
  //   href: 'projects',
  //   label: 'models.projects.plural'
  // },
  {
    type: 'link',
    namespace: 'tasks',
    href: 'tasks',
    label: 'models.rooms.plural'
  }
];

interface CozyLinkMenuProps {
  currentUser: CurrentUser;
  pathname: string;
}

function CozyLinkMenu({ currentUser, pathname }: CozyLinkMenuProps) {
  return (
    <LinkMenu links={links} currentUser={currentUser} pathname={pathname} />
  );
}

export default memo(CozyLinkMenu);
