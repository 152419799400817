export default {
  siteName: 'CGI',
  youAreAboutToDelete: 'You are about to delete: ',
  you_are_about_to_download_product_with_nda: 'You are about to download product with NDA: ',
  you_are_about_to_download_template_with_nda: 'You are about to download template with NDA: ',
  areYouSure: 'Are you sure?',
  yesDelete: 'Yes, Delete',
  processing: 'Processing...',
  entriesCount: 'Showing {from} to {to} of {of} entries',

  welcomeToSite: 'Welcome to CGICRM',
  fullSiteName: 'CGICRM',
  loginInToSeeItInAction: 'Login in. To see it in action.',
  forgotPassword: 'Forgot password?',
  doNotHaveAnAccount: 'Do not have an account?',
  createAnAccount: 'Create an account',

  startTestDrive: 'Start Test Drive',
  createAccountToSeeItInAction: 'Create account to see it in action.',
  alreadyHaveAnAccount: 'Already have an account?',

  enterYourEmailAddressAndYourPasswordWillBeResetAndEmailedToYou: 'Enter your email address and your password will be reset and emailed to you.',
  notYetRegisteredOrAlreadyHaveAnAccount: 'Not yet registered or already have an account?',

  editPassword: 'Edit password',
  enterYourNewPasswordAtLeast6CharactersAndItWillBeResetAndEmailedToYou: 'Enter your new password (at least 6 characters) and it will be reset and emailed to you.',
  drop_files_to_upload: 'Drop files to upload',
  or_click: 'or CLICK',
  you_are_about_to_remove_following: 'You are about to remove following',
  you_are_about_to_remove_all: 'You are about to remove all',
  this_action_cannot_be_undone: 'This action cannot be undone.',
  have_a_question: 'Have a question?',
  online_ready_to_help: 'We\'re online 08.00 AM - 05.00 PM GMT Monday-Friday. At that time we\'re ready to help and answer any questions.',

  total_time_tracked_today: 'Total time tracked today:',
  are_you_sure_you_want_to_activate_selected_users: 'Are you sure you want to activate selected users',
  are_you_sure_you_want_to_block_selected_users: 'Are you sure you want to block selected users',
  are_you_sure_you_want_to_remove: 'Are you sure you want to remove',
  are_you_sure_you_want_to_remove_results_from: 'Are you sure you want to remove results from',
  are_you_sure_you_want_to_send_results: 'Are you sure you want to send results?',
  are_you_sure_you_want_to_send_results_to: 'Are you sure you want to send results to',
  are_you_sure_you_want_to_email_results_to_client: 'Are you sure you want to email results to client?',
  account_balance_is_less_than_transfer_amount_negative_balance_will_be_reached: 'Account balance is less than transfer amount. Negative balance will be reached.',
  enter_the_email_address_of_the_user_you_want_to_share_your_tasks_and_projects_with: 'Enter the email address of the user you want to share your tasks and projects with',
  enter_the_users_email_to_request_access_to_his_or_her_tasks_and_projects: 'Enter the user\'s email to request access to his or her tasks and projects',
  all_users_from_the_list_below_have_access_to_all_tasks_in_which_participate: 'All users from the list below have access to all tasks in which participate',
  has_access_to_all_tasks_in_which_participate_users_from_the_list_below: 'has access to all tasks in which participate users from the list below.',
  inappropriate_file_type: 'Inappropriate file type',
  your_payment_will_be_processed_in_15_minutes: 'Your payment will be processed in 15 - 30 minutes',
  you_will_be_redirected_to_the_payments_page_in: 'You will be redirected to the payments page in',
  are_you_sure_you_want_to_leave_the_task: 'Are you sure you want to leave the task?',
  are_you_sure_you_want_to_cancel_the_task: 'Are you sure you want to cancel the task?',
  if_you_leave_the_task_you_will_no_longer_receive_notifications_and_will_not_be_payed_for_this_task_when_it_is_done: 'If you leave the task you will no longer receive notifications and will not be payed for this task when it is done.',
  task_cancel_alert: 'Canceling this task will cause irreversible changes.\n'
    + '1. Task Account funds will be returned to the Project Account.\n'
    + '2. Following data will be lost:\n'
    + '- ​​Estimate cost, Prepayment, Start date, Estimate date\n'
    + '- Task members and their tracked work time\n'
    + 'Nevertheless, the following data will be preserved:\n'
    + '- ​​Task Name and Description\n'
    + '- All messages\n'
    + '- Task timeline',
  please_confirm_or_change_estimate_date: 'Please, confirm or change estimate date',
  results_have_been_sent: 'Results have been sent',
  please_check_your_email: 'Please, check your email',
  it_may_take_up_to_10_minutes_to_deliver_files_do_not_try_to_send_results_again_during_this_time: 'It may take up to 10 minutes to deliver files. Do not try to send results again during this time.',
  the_task_thread_is_archived_please_use: 'The task thread is archived. Please use ',
  to_contact_the_team: ' to contact the team.',
  project_discussion_thread: 'project discussion thread',
  remove_from_share: 'Remove from share',
  please_consider_creating_a_new_task_with_further_instructions_or_contact_us_at_project_thread_directly: 'Please consider creating a new task with further instructions or contact us at {projectMessagesLink} directly.',
  project_thread: 'project thread',
  this_task_is_closed: 'This task is closed',
  so_we_might_not_see_your_message: 'so we might not see your message. Please create a new task to communicate with the manager.',
  current_bill_is_canceled: 'Current bill is canceled.',
  please_request_a_new_one_if_needed: 'Please, request a new one if needed.',
  at_least_one_task_must_be_entered: 'At least one task must be entered.',
  file_was_added_to_processing: 'File was added to processing',
  tasks_were_added_to_processing: 'Tasks were added to processing',
  there_are_no_valid_tasks: 'There are no valid tasks.',
  there_are_no_non_valid_tasks: 'There are no non-valid tasks.',
  total_discussions_amount_must_be_less_or_equal_to_project_estimate_cost: 'Total discussions amount must be less or equal to project estimate cost',
  please_wait_your_download_will_be_ready_in_several_minutes: 'Please, wait. Your download will be ready in several minutes.',
  please_wait: 'Please, wait.',
  upload_photos_of_the_room_and_a_plan: 'Upload photos of the room and a plan with dimensions/scan of a hand-drawn plan. If you have no plan, please upload enough photos for us to check the geometry of all the walls, windows, and doors.',
  please_fill_out_the_fields_below_the_fields_asking_for_dimensions: 'Please fill out the fields below. If the space is not rectangular, please attach a space plan. In that case, you can skip the dimension fields but for the height.',
  we_ll_do_our_best_to_deliver_a_perfect_solution: 'We\'ll do our best to deliver a perfect solution even if you don\'t provide us with the space plans and dimensions. However, without this information we won\'t be able to guarantee that the design will fit your space.',
  your_comments_about_existing_room: 'Your comments about existing room',
  your_comments_about_new_design: 'Your comments about new design',
  tell_us_about_your_room_details: 'Tell us about the details of your room configuration that can\'t be seen or aren\'t obvious on the photos and floor plan. For example, this wall is to be demolished, or the ceiling is at the angle X. Please don’t include any information on the future design - we’ll discuss it on the next step.',
  please_specify_details_of_kitchen_style_you_prefer: 'Please specify details of kitchen style you prefer.',
  let_us_know_what_kind_of_kitchen_you_would_like: 'Let us know what kind of kitchen you would like.',
  leave_your_message_to_our_designer: 'Leave your message to our designer. You may also add links to the moodboard, collection of related images on Pinterest, Google Drive, Dropbox etc.',
  leave_your_message_to_designer: 'Leave your message to our designer',
  upload_your_images_as_a_reference: 'Upload your images as a reference. Our designer will use these images as a guidance.',
  upload_images_of_up_to_three_pieces_of_furniture: 'Upload images of up to three pieces of furniture you want to be used in the design. Our 3D artist will model it and include into the final design.',
  basic_price_plan_is_not_available_for_kitchens_and_studios: 'Basic price plan is not available for kitchens and studios',
  select_template_from_our_library: 'Select a template from our library. Our 3D artist will adopt the selected design to your room.',
  we_will_create_floor_plan_of_your_room_with_all_furniture_positioning_and_sizes: 'We will create a floor plan of your room with all furniture positioning and sizes.',
  our_designer_will_create_extra_design_for_your_room: 'Our designer will create an extra design concept for your room.',
  per_design_concept_per_view: 'per design concept per view',
  we_do_4_renders_for_each_design_by_default: 'We do 4 renders for each design by default. But if you want we will provide you with extra renders.',
  basic_plan: 'Basic plan',
  will_allow_you_to_develop_your_new_design_without_even_noticing_it: 'will allow you to develop your new design without even noticing it. With our collection of templates, you can find a solution to your liking, and the designer will tweak and adjust it to you needs.',
  custom_plan: 'Custom plan',
  is_for_you_if_you_want_a_truly_special_unique_design: 'is for you if you want a truly special, unique design. Just send us references and/or moodboards, and our designers will read your mind and bring your dream to life.',
  extended_plan: 'Extended plan',
  allows_you_to_include_a_set_of_your_furniture_to_the_design: 'allows you to include a set of your furniture to the design. You want your smashing new chairs fit in the new room? We will create 3D models of the pieces and incorporate them into the imagery.',
  please_fill_all_required_fields_in_previous_section: 'Please, fill all required fields in previous section(s)',
  living: 'Living',
  category_includes_a_variety_of_rooms_such_as_a_home_office: 'category includes a variety of rooms such as a home office, library, living room, balcony, cinema, billiard and games room. You will get such a cozy and functional design that you will wonder why you didn’t order it years ago.',
  kitchen: 'Kitchen',
  category_allows_ordering_any_kind_of_kitchen_you_dreamed_of: 'category allows ordering any kind of kitchen you dreamed of. L- and U-shaped, straight, with and without island, with cupboards or open storage - anything you like. And it will look like a million buck.',
  studio: 'Studio',
  design_may_include_a_kitchen_plus_a_dining_living_area: 'design may include a kitchen plus a dining/ living area. We will help you make the most of your space and deliver a practical and Instagram-worthy design that will exceed your wildest expectations. This category is only available for custom and extended price plans.',
  bedroom: 'Bedroom',
  design_is_the_category_where_you_can_choose_the_main_guest_bedroom: 'design is the category where you can choose the main/guest bedroom, child room, nursery. We’ll create for you a cozy and resting space that will wrap you around with its comfort.',
  bathroom: 'Bathroom',
  category_allows_you_to_order_design_for_different_sanitary_utilities: 'category allows you to order design for different sanitary utilities like WCs, saunas, bathrooms and swimming pools. We will create designs so functional and stylish you will never want to look back at the previous ones.',
  utility: 'Utility',
  category_allows_ordering_design_for_walk_in: 'category allows ordering design for walk-in wardrobes, dressing rooms, pantries, and laundries. We will make sure that your utility spaces are super stylish, have clever storage, look chic and are a joy to use.',
  are_you_sure_you_want_to_overwatch_selected_users: 'Are you sure you want to overwatch selected users',
  are_you_sure_you_want_to_cancel_overwatch_selected_users: 'Are you sure you want to cancel overwatch selected users',
  your_password_has_been_changed_successfully: 'Your password has been changed successfully.',
  your_avatar_has_been_changed_successfully: 'Your avatar has been changed successfully.',
  how_to_take_photos_of_a_room: 'How to take photos of a room?',
  open_the_curtains_and_switch_on_the_light: 'Open the curtains and switch on the light. Make sure windows are seen entirely.',
  take_photos_of_each_corner: 'Take photos of each corner. To photograph a corner, stand in the opposite one.',
  take_9_16_photos_like_in_the_image_the_more_the_better: 'Take 9-16 photos, like in the image. The more, the better.',
  based_on_1_of_our_3507_templates: 'Based on 1 of our 3507 templates',
  cannot_be_based_on_references_or_moodboards: 'Cannot be based on references or moodboards',
  cannot_include_kitchen_or_studio_design: 'Сannot include kitchen or studio design',
  does_not_include_3D_models_of_a_set_of_furniture: 'Does not include 3D models of a set of furniture',
  two_rounds_of_updates_with_a_designer: '2 rounds of corrections',
  three_rounds_of_updates_with_a_designer: '3 rounds of updates with a designer',
  unlimited_corrections: 'Unlimited corrections',
  shopping_list_included: 'Shopping list included',
  communication_via_our_online_platform: 'Communication via our Online Platform',
  personal_support: 'Personal Support',
  based_on_templates_from_our_library: 'Based on templates from our library',
  based_on_references_and_moodboards: 'Based on references and moodboards',
  may_include_kitchen_or_studio_design: 'May include kitchen or studio design',
  includes_3D_models_of_a_set_of_furniture: 'Includes 3D models of a set of furniture',
  cannot_include_a_set_of_your_furniture: 'Cannot include a set of your furniture',
  may_include_a_set_of_your_furniture: 'May include a set of your furniture',
  share_designs_that_inspire_you: 'Share designs that inspire you',
  after_the_payment: 'After the payment, you\'ll be redirected to your project where you\'ll meet your personal manager. They will guide and advise you throughout the project, and make sure you get your perfect design super-fast.',
  apply_promo_code: 'Apply Promo Code',
  what_will_the_next_step_be: 'What will the next step be?',
  this_promo_code_gives_you: 'This promo code gives you',
  this_is_cosymakeover_project: 'This is cosymakeover project.',
  please_write_your_messages_into_projects_tasks_directly: 'Please, write your messages into projects tasks directly.'
};
