import { useEffect, useCallback, useReducer, useState } from 'react';

import { Item } from '../../../../../types';

import {
  initialState,
  showRequestReducer,
  ShowRequestReducerType
} from './reducers/showRequestReducer';

import { sendShowRequest } from './utils/sendShowRequest';

interface ShowRequestOptions {
  id: string | number;
  url: string;
  serializer?: string;
  initFetch?: boolean;
}

export type ShowHookOptions = Omit<ShowRequestOptions, 'url'>;

interface ShowRequestConfig {
  serializer?: string;
}

export function useShowRequest<ItemType extends Item>({
  id,
  url,
  serializer,
  initFetch = true
}: ShowRequestOptions) {
  const [
    { item, itemErrorMessage, itemFetched, itemLoading },
    dispatch
  ] = useReducer<ShowRequestReducerType<ItemType>>(
    showRequestReducer,
    initialState
  );
  const [requestConfig] = useState<ShowRequestConfig>({
    serializer
  });

  const handleInitFetch = useCallback(
    () => sendShowRequest(`${url}/${id}`, requestConfig, dispatch),
    [url, id, requestConfig, dispatch]
  );

  useEffect(() => {
    if (initFetch) {
      handleInitFetch();
    }
  }, [initFetch, handleInitFetch]);

  return {
    item,
    itemFetched,
    itemErrorMessage,
    itemLoading,
    fetchItem: useCallback(
      (params = {}) =>
        sendShowRequest<ItemType>(
          `${url}/${id}`,
          {
            ...requestConfig,
            ...params
          },
          dispatch
        ),
      [url, id, requestConfig, dispatch]
    ),
    refetch: useCallback(
      (meta = {}) =>
        sendShowRequest<ItemType>(url, { ...requestConfig, ...meta }, dispatch),
      [url, requestConfig]
    ),
    cancel: useCallback(() => console.log('cancel'), [])
  };
}
