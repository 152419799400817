import {
  CLEAR_BILLS_FILTERS_START,
  CLEAR_BILLS_FILTERS_SUCCESS
} from '../BillConstants';

export function clearBillsFiltersStart(options = {}) {
  return {
    type: CLEAR_BILLS_FILTERS_START,
    options
  };
}

export function clearBillsFiltersSuccess(options = {}) {
  return {
    type: CLEAR_BILLS_FILTERS_SUCCESS,
    options
  };
}

export default function clearBillsFilters(options = {}) {
  return dispatch => {
    dispatch(clearBillsFiltersStart(options));
    setTimeout(() => dispatch(clearBillsFiltersSuccess(options)), 500);
  };
}
