import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  fetchBills as fetchBillsAction,
  fetchBill as fetchBillAction,
  payBillFromAccount as payBillFromAccountAction,
  stripePayment as stripePaymentAction
} from '../../../bills/BillActions';

import { BaseContainer } from '../../containers/BaseContainer';
import { providePropsToChildren, providePropsToContainer } from '../../../../utils';

const scope = 'bills';

class BillsContainer extends PureComponent {
  static propTypes = {
    fetchBills: PropTypes.func.isRequired,
    fetchBill: PropTypes.func.isRequired,
    payBillFromAccount: PropTypes.func.isRequired,
    stripePayment: PropTypes.func.isRequired
  };

  handlePayBillFromAccount = (bill, cb) => this.props.payBillFromAccount(bill.id, {}, cb);

  handleStripePayment = (bill, cb) => token => this.props.stripePayment(bill.id, { source: token.id }, { pure: true }, cb);

  renderChildren = () => containerProps => this.props.children(providePropsToChildren(scope, containerProps, this.props, {
    payBillFromAccount: this.handlePayBillFromAccount,
    stripePayment: this.handleStripePayment
  }));

  render() {
    return (
      <BaseContainer
        {...providePropsToContainer(scope, this.props)}
        fetchItems={this.props.fetchBills}
        fetchItem={this.props.fetchBill}
      >
        {this.renderChildren()}
      </BaseContainer>
    );
  }
}

export default connect(({ auth: { currentUser }, bills }) => ({ currentUser, ...bills }), {
  fetchBills: fetchBillsAction,
  fetchBill: fetchBillAction,
  payBillFromAccount: payBillFromAccountAction,
  stripePayment: stripePaymentAction
})(BillsContainer);
