import { createReducer } from 'redux-create-reducer';
import { clearModalValues, defaultState } from '../../common/reducers';

import {
  createIncomingPaymentsStart,
  createIncomingPaymentsSuccess,
  createIncomingPaymentsError
} from './createIncomingPayments';

import {
  createOutgoingPaymentsStart,
  createOutgoingPaymentsSuccess,
  createOutgoingPaymentsError
} from './createOutgoingPayments';

import {
  createIncomingTransfersStart,
  createIncomingTransfersSuccess,
  createIncomingTransfersError
} from './createIncomingTransfers';

import {
  createOutgoingTransfersStart,
  createOutgoingTransfersSuccess,
  createOutgoingTransfersError
} from './createOutgoingTransfers';

import {
  createInternalTransfersStart,
  createInternalTransfersSuccess,
  createInternalTransfersError
} from './createInternalTransfers';

const defaultBalanceTypes = [
  'debit_account',
  'credit_account',
  'bonus_account',
  'prepaid_balance_amount',
  'balance_amount'
];

const defaultPaymentsState = {
  ...defaultState,

  paymentsBalanceTypes: defaultBalanceTypes,

  transfersBalanceTypes: defaultBalanceTypes,

  incomingPaymentTypes: {
    transaction: defaultBalanceTypes,
    swift: ['debit_account']
    // other: defaultBalanceTypes
  },

  outgoingPaymentTypes: {
    transaction: defaultBalanceTypes,
    charge_back: ['debit_account']
    // payout: ['debit_account']
    // other: defaultBalanceTypes
  }
};

export default createReducer(defaultPaymentsState, {
  CREATE_INCOMING_PAYMENTS_START: createIncomingPaymentsStart,
  CREATE_INCOMING_PAYMENTS_SUCCESS: createIncomingPaymentsSuccess,
  CREATE_INCOMING_PAYMENTS_ERROR: createIncomingPaymentsError,

  CREATE_OUTGOING_PAYMENTS_START: createOutgoingPaymentsStart,
  CREATE_OUTGOING_PAYMENTS_SUCCESS: createOutgoingPaymentsSuccess,
  CREATE_OUTGOING_PAYMENTS_ERROR: createOutgoingPaymentsError,

  CREATE_INCOMING_TRANSFERS_START: createIncomingTransfersStart,
  CREATE_INCOMING_TRANSFERS_SUCCESS: createIncomingTransfersSuccess,
  CREATE_INCOMING_TRANSFERS_ERROR: createIncomingTransfersError,

  CREATE_OUTGOING_TRANSFERS_START: createOutgoingTransfersStart,
  CREATE_OUTGOING_TRANSFERS_SUCCESS: createOutgoingTransfersSuccess,
  CREATE_OUTGOING_TRANSFERS_ERROR: createOutgoingTransfersError,

  CREATE_INTERNAL_TRANSFERS_START: createInternalTransfersStart,
  CREATE_INTERNAL_TRANSFERS_SUCCESS: createInternalTransfersSuccess,
  CREATE_INTERNAL_TRANSFERS_ERROR: createInternalTransfersError,

  CLEAR_PAYMENT_MODAL_VALUES: clearModalValues
});
