import React, { Fragment, useState, useCallback } from 'react';
import cl from 'classnames';

import { withCurrentUser } from '../../../main/common/HOCs/withCurrentUser';

import { CurrentUser } from '../../../types';

import { CozyUserMenu } from './components/CozyUserMenu';
import { CozyLinkMenu } from './components/CozyLinkMenu';
// import { DownloadsMenu } from '../../../helpers/DownloadsMenu';
// import { MessagesMenu } from '../../../helpers/MessagesMenu';

import { LinkTo } from '../../../helpers/ts/LinkTo';
// import { PureLinkTo } from '../../../helpers/ts/PureLinkTo';
import { PureButton } from '../../../helpers/ts/PureButton';

import cozyLogo from '../../../styles/base/images/icons/cozymakeover-logo-av.png';

interface CozyHeaderHocsProps {
  currentUser: CurrentUser;
}

interface CozyHeaderProps {
  auth?: boolean;
  authFetched?: boolean;
  pathname: string;
  onToggleSidebarHidden: () => void;
  onToggleSidebarMobile: () => void;
  onLogout: () => void;
}

function CozyHeader({
  auth,
  currentUser,
  authFetched,
  pathname,
  // onToggleSidebarHidden,
  onToggleSidebarMobile,
  onLogout
}: CozyHeaderProps & CozyHeaderHocsProps) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen]);

  return (
    <div className="navbar navbar-expand-md navbar-light shadow navbar-static">
      <div className="align-items-center d-flex mr-2 p-0 wmin-0">
        <LinkTo href="" className="d-inline-block" title="Archivizer.com">
          <img
            id="cozy-logo"
            src={cozyLogo}
            alt="Archivizer.com"
            className="py-2"
            height="50"
          />
        </LinkTo>
      </div>

      {auth ? null : (
        <Fragment>
          <div className="d-md-none">
            <PureButton
              className="navbar-toggler"
              icon="icon-paragraph-justify3"
              onClick={toggle}
            />
            <PureButton
              className={cl('navbar-toggler sidebar-mobile-component-toggle', {
                collapsed: !isOpen
              })}
              icon="icon-unfold"
              onClick={onToggleSidebarMobile}
            />
          </div>
          {authFetched ? (
            <div
              className={cl(
                'collapse',
                'navbar-collapse',
                isOpen ? 'show' : null
              )}
              id="navbar-mobile"
            >
              <CozyLinkMenu currentUser={currentUser} pathname={pathname} />
              <ul className="navbar-nav ml-md-auto">
                {/* <li className="nav-item">
                  <PureLinkTo
                    pureLink
                    href="#"
                    className="navbar-nav-link sidebar-control sidebar-component-toggle d-none d-md-block"
                    icon="icon-transmission"
                    onClick={onToggleSidebarHidden}
                  />
                </li>
                {currentUser.hasPermissions('io_dropdown_downloads_created') ? (
                  <DownloadsMenu addTotalBadgeClassName="bg-pink" />
                ) : null}
                <MessagesMenu addTotalBadgeClassName="bg-pink" /> */}
                <CozyUserMenu onLogout={onLogout} />
              </ul>
            </div>
          ) : null}
        </Fragment>
      )}
    </div>
  );
}

export default withCurrentUser()(CozyHeader);
