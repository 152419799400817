import React from 'react';
import PropTypes from 'prop-types';

import { withContacts } from '../../../../common/HOCs/withContacts';

import { ContactModal } from '../../modals/ContactModal';

import { translate as t } from '../../../../../utils';

function ContactModalButton({ contactCreateErrorMessage, contactLoading, createContact, clearContactModalValues }) {
  return (
    <ContactModal
      modalTitle={t('messages.have_a_question')}
      modalSize="lg"
      addClass="btn-link text-secondary"
      modalIcon="icon-envelope mr-2"
      submitText={t('models.messages.write')}
      buttonText={t('words.contact')}
      buttonSize="sm"
      errorMessage={contactCreateErrorMessage}
      isLoading={contactLoading}
      onSubmit={createContact}
      onClose={clearContactModalValues}
    />
  );
}

ContactModalButton.propTypes = {
  clearContactModalValues: PropTypes.func.isRequired,
  createContact: PropTypes.func.isRequired,
  contactCreateErrorMessage: PropTypes.string,
  contactLoading: PropTypes.bool
};

ContactModalButton.defaultProps = {
  contactCreateErrorMessage: null,
  contactLoading: false
};

export default withContacts({
  scope: 'contacts',
  fields: ['contactCreateSuccessMessage', 'contactCreateErrorMessage', 'contactLoading'],
  actions: ['createContact', 'clearContactModalValues']
})(ContactModalButton);
