import { LinkMenuItems } from '../../../../helpers/LinkMenu/types';

export const links: LinkMenuItems = [
  {
    type: 'dropdown',
    namespace: 'reports|downloads|sent_messages|batch_operations',
    label: 'models.reports.plural',
    links: [
      {
        type: 'link',
        namespace: 'reports',
        href: 'reports',
        label: 'models.reports.general'
      },
      {
        type: 'link',
        namespace: 'downloads',
        href: 'downloads',
        label: 'models.downloads.plural'
      },
      {
        type: 'link',
        namespace: 'sent_messages',
        href: 'sent_messages',
        label: 'models.sent_messages.plural'
      },
      {
        type: 'link',
        namespace: 'batch_operations',
        href: 'batch_operations',
        label: 'models.batch_operations.plural'
      }
    ]
  },
  {
    type: 'dropdown',
    namespace: 'transactions|allocations|bills|refills|bill_refills',
    label: 'words.finance',
    links: [
      {
        type: 'link',
        namespace: 'transactions',
        href: 'transactions',
        label: 'models.transactions.plural'
      },
      {
        type: 'link',
        namespace: 'allocations',
        href: 'allocations',
        label: 'models.allocations.plural'
      },
      {
        type: 'link',
        namespace: 'bills',
        href: 'bills',
        label: 'models.bills.plural'
      },
      {
        type: 'link',
        namespace: 'refills',
        href: 'refills',
        label: 'models.refills.plural'
      },
      {
        type: 'link',
        namespace: 'bill_refills',
        href: 'bill_refills',
        label: 'models.bill_refills.plural'
      },
      {
        type: 'link',
        namespace: 'promo_codes',
        href: 'promo_codes',
        label: 'models.promo_codes.plural'
      }
    ]
  },
  {
    type: 'dropdown',
    namespace: 'roles|permissions',
    label: 'words.access',
    links: [
      {
        type: 'link',
        namespace: 'roles',
        href: 'roles',
        label: 'models.roles.plural'
      },
      {
        type: 'link',
        namespace: 'permissions',
        href: 'permissions',
        label: 'models.permissions.plural'
      }
    ]
  },
  {
    type: 'dropdown',
    namespace:
      'pages|email_templates|tags|transaction_tags|sections|categories',
    label: 'words.content',
    links: [
      {
        type: 'link',
        namespace: 'pages',
        href: 'pages',
        label: 'models.pages.plural'
      },
      {
        type: 'link',
        namespace: 'email_templates',
        href: 'email_templates',
        label: 'models.email_templates.plural'
      },
      {
        type: 'link',
        namespace: 'tags',
        href: 'tags',
        label: 'models.tags.plural'
      },
      {
        type: 'link',
        namespace: 'transaction_tags',
        href: 'transaction_tags',
        label: 'models.transaction_tags.plural'
      },
      {
        type: 'link',
        namespace: 'sections',
        href: 'sections',
        label: 'models.sections.plural'
      },
      {
        type: 'link',
        namespace: 'categories',
        href: 'categories',
        label: 'models.categories.plural'
      }
    ]
  },
  {
    type: 'link',
    namespace: 'contacts',
    href: 'contacts',
    label: 'models.contacts.plural'
  },
  {
    type: 'link',
    namespace: 'imports',
    href: 'imports',
    label: 'models.imports.plural'
  }
];
