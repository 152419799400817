import { LOGOUT_USER } from '../AuthConstants';

import { removeAuthToken } from '../../utils/authToken';

// logoutUser

export default function logoutUser() {
  removeAuthToken();

  return {
    type: LOGOUT_USER
  };
}
