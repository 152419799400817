import {
  FETCH_ITEM_START,
  FETCH_ITEM_SUCCESS,
  FETCH_ITEM_ERROR
} from '../../constants';

interface ShowRequestState<ItemType> {
  itemLoading: boolean;
  itemErrorMessage: string | null;
  itemFetched: boolean;
  item: ItemType | null;
}

export const initialState = {
  itemLoading: false,
  itemErrorMessage: null,
  itemFetched: false,
  item: null
};

export type ShowRequestAction<ItemType> =
  | {
      type: 'FETCH_ITEM_START';
    }
  | {
      type: 'FETCH_ITEM_SUCCESS';
      item: ItemType;
    }
  | {
      type: 'FETCH_ITEM_ERROR';
      itemErrorMessage: string | null;
    };

export type ShowRequestReducerType<ItemType> = (
  prevState: ShowRequestState<ItemType>,
  action: ShowRequestAction<ItemType>
) => ShowRequestState<ItemType>;

export function showRequestReducer<ItemType>(
  state: ShowRequestState<ItemType>,
  action: ShowRequestAction<ItemType>
): ShowRequestState<ItemType> {
  switch (action.type) {
    case FETCH_ITEM_START:
      return {
        ...state,
        itemFetched: false,
        itemLoading: true,
        itemErrorMessage: null
      };

    case FETCH_ITEM_SUCCESS:
      return {
        ...state,
        itemLoading: false,
        itemErrorMessage: null,
        itemFetched: true,
        item: action.item
      };

    case FETCH_ITEM_ERROR:
      return {
        ...state,
        itemLoading: false,
        itemFetched: true,
        itemErrorMessage: action.itemErrorMessage
      };

    default:
      return state;
  }
}
