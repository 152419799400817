import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';

import { withCurrentUser } from '../../../main/common/HOCs/withCurrentUser';

import { MainLinkMenu, UserMenu } from './components';
import { DownloadsMenu } from '../../../helpers/DownloadsMenu';
import { MessagesMenu } from '../../../helpers/MessagesMenu';
import { HelpPageModalLink } from './components/HelpPageModalLink';
import { LinkTo, PureButton } from '../../../helpers';

const workerRegisterPath = '/workers/register';

function Header({
  auth,
  currentUser,
  authFetched,
  pathname,
  onToggleSidebarHidden,
  onToggleSidebarMobile,
  onLogout
}) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen]);

  return (
    <div className="navbar navbar-expand-md navbar-dark bg-indigo navbar-static">
      <div className="navbar-brand wmin-0 p-0 mr-2">
        <LinkTo className="d-inline-block" title="Archivizer.com" href="">
          Archivizer.com
          <svg id="av-logo" width="50" height="50">
            <g fillRule="evenodd" clipRule="evenodd" fill="#fff">
              <path d="M11.077,36.786c-0.061,0.396-0.403,0.673-0.799,0.665l-3.546,0 c-0.323,0-0.595-0.245-0.628-0.566c-0.003-0.023-0.005-0.047-0.003-0.07c0-0.066,0.011-0.129,0.03-0.188l5.444-20.388 c0.579-2.168,2.537-3.692,4.789-3.692c2.252,0,4.21,1.524,4.789,3.692l5.443,20.384c0.021,0.063,0.031,0.126,0.031,0.192 c0.002,0.023,0,0.047-0.003,0.07c-0.033,0.321-0.305,0.568-0.629,0.566l-3.543,0c-0.399,0.008-0.741-0.269-0.802-0.665 L16.83,17.491c-0.004-0.012-0.007-0.023-0.012-0.035c-0.003-0.009-0.005-0.017-0.007-0.026c-0.034-0.221-0.224-0.384-0.448-0.384 c-0.223,0-0.414,0.163-0.448,0.384c-0.001,0.009-0.004,0.017-0.007,0.026c-0.004,0.012-0.008,0.023-0.011,0.032L11.077,36.786z" />
              <path d="M28.349,13.214c-0.061-0.396-0.403-0.673-0.799-0.665l-3.546,0 c-0.323,0-0.595,0.245-0.628,0.566c-0.004,0.023-0.005,0.047-0.003,0.07c0,0.066,0.011,0.129,0.03,0.188l5.444,20.388 c0.579,2.168,2.537,3.692,4.789,3.692c2.252,0,4.21-1.524,4.789-3.692l5.443-20.384c0.021-0.063,0.031-0.126,0.031-0.192 c0.002-0.023,0-0.047-0.003-0.07c-0.033-0.321-0.305-0.568-0.629-0.566l-3.543,0c-0.399-0.008-0.741,0.269-0.802,0.665 l-4.821,19.295c-0.004,0.012-0.007,0.023-0.012,0.035c-0.003,0.009-0.005,0.017-0.007,0.026c-0.034,0.221-0.224,0.384-0.448,0.384 c-0.223,0-0.414-0.163-0.448-0.384c-0.001-0.009-0.004-0.017-0.007-0.026c-0.004-0.012-0.008-0.023-0.011-0.032L28.349,13.214z" />
            </g>
          </svg>
        </LinkTo>
      </div>

      {auth || pathname === workerRegisterPath ? null : (
        <Fragment>
          <div className="d-md-none">
            <PureButton
              className="navbar-toggler"
              icon="icon-paragraph-justify3"
              onClick={toggle}
            />
            <PureButton
              className={cl('navbar-toggler sidebar-mobile-component-toggle', {
                collapsed: !isOpen
              })}
              icon="icon-unfold"
              onClick={onToggleSidebarMobile}
            />
          </div>
          {authFetched ? (
            <div
              className={cl(
                'collapse',
                'navbar-collapse',
                isOpen ? 'show' : null
              )}
              id="navbar-mobile"
            >
              <MainLinkMenu currentUser={currentUser} pathname={pathname} />
              <ul className="navbar-nav ml-md-auto">
                <li className="nav-item">
                  <LinkTo
                    pureLink
                    href="#"
                    className="navbar-nav-link sidebar-control sidebar-component-toggle d-none d-md-block"
                    icon="icon-transmission"
                    onClick={onToggleSidebarHidden}
                  />
                </li>
                {currentUser.hasPermissions('io_dropdown_downloads_created') ? (
                  <DownloadsMenu />
                ) : null}
                <MessagesMenu />
                {currentUser.hasPermissions('read_help_page_link') ? (
                  <HelpPageModalLink />
                ) : null}
                <UserMenu onLogout={onLogout} />
              </ul>
            </div>
          ) : null}
        </Fragment>
      )}
    </div>
  );
}

Header.propTypes = {
  currentUser: PropTypes.shape({ id: PropTypes.number }).isRequired,
  auth: PropTypes.bool,
  authFetched: PropTypes.bool,
  pathname: PropTypes.string,
  onToggleSidebarHidden: PropTypes.func,
  onToggleSidebarMobile: PropTypes.func,
  onLogout: PropTypes.func
};

Header.defaultProps = {
  pathname: null,
  auth: false,
  authFetched: false,
  onToggleSidebarHidden: null,
  onToggleSidebarMobile: null,
  onLogout: null
};

export default withCurrentUser()(Header);
