export default {
  plural: 'Client Profiles',
  updated: 'Client Profile Updated',
  tabs: {
    description: 'Description',
    products: 'Products',
    templates: 'Templates',
    files: 'Files',
    images: 'Images'
  }
};
