import {
  watchFetchAllocations,
  watchFetchAllocation,
  watchCancelAllocation,
  watchCancelAllocationFromClientBalance,
  watchSetCanceledAllocation,
  watchSetSharedAllocation,
  watchShareAllocation,
  watchShareAllocationFromClientBalance
} from './allocations/AllocationSagas';

import {
  watchFetchBalances,
  watchFetchBalance,
  watchRecalculateBalances,
  watchRecalculateBalanceAccount
} from './balances/BalanceSagas';

import {
  watchFetchBills,
  watchFetchBill,
  watchDownloadBillsXls,
  watchPayBillFromAccount,
  watchCheckBillPayment,
  watchStripePayment,
  watchCancelBill,
  watchSetBillPaying,
  watchCheckOrderPayment
} from './bills/BillSagas';

import {
  watchFetchBillRefills,
  watchDownloadBillRefillsXls
} from './billRefills/BillRefillSagas';

import {
  watchFetchCategories,
  watchFetchCategory
} from './categories/CategorySagas';

import {
  watchFetchChanges
} from './changes/ChangeSagas';

import {
  watchUploadClientFiles,
  watchRemoveClientFile
} from './clientFiles/ClientFileSagas';

import {
  watchFetchDiscussionMemberInvites
} from './discussionMemberInvites/DiscussionMemberInviteSagas';

import {
  watchFetchDownloads,
  watchFetchDownload,
  watchUpdateDownload,
  watchRemoveDownload,
  watchCheckDownload,
  watchDropdownDownloadsChannelCreated,
  watchDownloadsChannelCreated,
  watchDownloadsChannelUpdated
} from './downloads/DownloadSagas';

import {
  watchFetchEmailTemplates,
  watchFetchEmailTemplate,
  watchCreateEmailTemplate,
  watchUpdateEmailTemplate,
  watchRemoveEmailTemplate
} from './emailTemplates/EmailTemplateSagas';

import {
  watchFetchFileAttachments,
  watchUpdateFileAttachment,
  // watchDownloadFileAttachment,
  watchUploadFileAttachments,
  watchRemoveFileAttachment,
  watchAttachFileAttachments
} from './fileAttachments/FileAttachmentSagas';

import {
  watchFetchFinanceTags,
  watchFetchFinanceTag,
  watchCreateFinanceTag,
  watchUpdateFinanceTag,
  watchRemoveFinanceTag
} from './financeTags/FinanceTagSagas';

import {
  watchFetchImages,
  watchUpdateImage,
  watchDownloadImage,
  watchUploadImages,
  watchRemoveImage
} from './images/ImageSagas';

import {
  watchRemoveMaxFile,
  watchUploadMaxFiles
} from './maxFiles/MaxFileSagas';

import {
  watchFetchLinkedAccounts,
  watchFetchLinkedAccount,
  watchCreateLinkedAccount,
  watchUpdateLinkedAccount,
  watchCreateLinkedAccounts,
  watchRemoveLinkedAccount,
  watchProvideAccessLinkedAccounts,
  watchRequestAccessLinkedAccounts
} from './linkedAccounts/LinkedAccountSagas';

import {
  watchAcceptLinkedAccountInvite
} from './linkedAccountInvites/LinkedAccountInviteSagas';

import {
  watchAcceptLinkedAccountRequest
} from './linkedAccountRequests/LinkedAccountRequestSagas';

import {
  watchNullifyFundsMember
} from './fundsMembers/FundsMemberSagas';

import {
  watchFetchMessages,
  watchFetchMessage,
  watchCreateMessage,
  watchUpdateMessage,
  watchReadMessage,
  watchUnreadMessage,
  watchForwardMessage,
  watchReadAllMessages,
  watchToggleSelectedMessage,
  watchDownloadMessageFileAttachments,
  watchDownloadMessageSelectedProducts,
  watchDownloadMessageSelectedTemplates,
  watchSubmitMessageFileAttachments,
  watchSubmitMessageSelectedProducts,
  watchSubmitMessageSelectedTemplates,
  watchDownloadMessagesXls
} from './messages/MessageSagas';

import { watchFetchOperations } from './operations/OperationSagas';

import {
  watchFetchProjects,
  watchFetchProject,
  watchCreateProject,
  watchUpdateProject,
  watchAcceptInviteToProject,
  watchToggleSelectedProject,
  watchInviteUsersToProject,
  watchRemoveUsersFromProject,
  watchCreateProjectBills,
  watchCreateProjectsBills,
  watchUpdateProjectsAmounts,
  watchAssignProjectToUser,
  watchDownloadProjectsXls,
  watchDownloadProjectResults,
  watchDownloadProjectFileAttachments,
  watchDownloadProjectSelectedProducts,
  watchDownloadProjectSelectedTemplates,
  watchDownloadProjectSourceFiles,
  watchSubmitProjectFileAttachments,
  watchSubmitProjectSelectedProducts,
  watchSubmitProjectSelectedTemplates,
  watchRecalculateProjectAccount,
  watchToggleProjectNotifications
} from './projects/ProjectSagas';

import {
  watchFetchProjectNotifications,
  watchFetchProjectNotification,
  watchCreateProjectNotification,
  watchUpdateProjectNotification,
  watchRemoveProjectNotification
} from './projectNotifications/ProjectNotificationSagas';

import {
  watchFetchProducts,
  watchFetchProduct,
  watchCreateProduct,
  watchUpdateProduct,
  watchDownloadProduct,
  watchToggleSelectedProduct,
  watchToggleBlockedProduct,
  watchRemoveProduct
} from './products/ProductSagas';

import {
  watchFetchProductsSet,
  watchDownloadProductsSet,
  watchEmptyProductsSet
} from './productsSets/ProductsSetSagas';

import {
  watchFetchRefills,
  watchFetchRefill
} from './refills/RefillSagas';

import {
  watchFetchReports,
  watchCreateReport,
  watchRemoveReport,
  watchDownloadReport,
  watchGenerateReport
} from './reports/ReportSagas';

import {
  watchFetchRevenueShareItems,
  watchFetchRevenueShareItem,
  watchCreateRevenueShareItem,
  watchCreateRevenueShareItems,
  watchUpdateRevenueShareItem,
  watchUpdateRevenueShareItems,
  watchRemoveRevenueShareItem
} from './revenueShareItems/RevenueShareItemSagas';

import {
  watchFetchSelectedProducts,
  watchFetchSelectedProduct,
  watchCreateSelectedProduct,
  watchUpdateSelectedProduct,
  watchDownloadSelectedProduct,
  watchRemoveSelectedProduct,
  watchAttachSelectedProducts
} from './selectedProducts/SelectedProductSagas';

import {
  watchFetchSelectedTemplates,
  watchFetchSelectedTemplate,
  watchCreateSelectedTemplate,
  watchUpdateSelectedTemplate,
  watchDownloadSelectedTemplate,
  watchRemoveSelectedTemplate,
  watchAttachSelectedTemplates
} from './selectedTemplates/SelectedTemplateSagas';

import {
  watchFetchSourceFiles,
  watchUpdateSourceFile,
  // watchDownloadSourceFile,
  watchUploadSourceFiles,
  watchRemoveSourceFile,
  watchAttachSourceFiles
} from './sourceFiles/SourceFileSagas';

import {
  watchFetchTags,
  watchFetchTag,
  watchCreateTag,
  watchUpdateTag,
  watchRemoveTag
} from './tags/TagSagas';

import {
  watchFetchTransactionTags,
  watchFetchTransactionTag,
  watchCreateTransactionTag,
  watchUpdateTransactionTag,
  watchRemoveTransactionTag
} from './transactionTags/TransactionTagSagas';

import {
  watchFetchSentMessages,
  watchFetchSentMessage
} from './sentMessages/SentMessageSagas';

import {
  watchFetchTasks,
  watchFetchTask,
  watchCreateTask,
  watchCreateTasks,
  watchUpdateTask,
  watchLeaveTask,
  watchPauseTask,
  watchAcceptTask,
  watchRevisionTask,
  watchRestoreTask,
  watchCancelTask,
  watchReallocateTask,
  watchAcceptTaskResults,
  watchAcceptTasksResults,
  watchApproveTaskResults,
  watchRevisionTaskResults,
  watchRemoveTaskResults,
  watchDownloadTaskAllocations,
  watchDownloadTasksXls,
  watchDownloadTaskFileAttachments,
  watchDownloadTaskResults,
  watchDownloadTasksResults,
  watchSendTaskResultsToEmail,
  watchSubmitTaskResults,
  watchSubmitTaskSourceFiles,
  watchSubmitTaskFileAttachments,
  watchSubmitTaskSelectedProducts,
  watchSubmitTaskSelectedTemplates,
  watchDownloadTaskSelectedProducts,
  watchDownloadTaskSelectedTemplates,
  watchDownloadTaskSourceFiles,
  watchTrackTaskTime,
  watchTrackTasksTime,
  watchCreateTaskBills,
  watchCreateTasksBills,
  watchToggleSelectedTask,
  watchUpdateTasksAmounts,
  watchUpdateTasksEstimateDates,
  watchAcceptInviteToTask,
  watchInviteUsersToTask,
  watchAssignTaskToUser,
  watchInviteUsersToTasks,
  watchRemoveUsersFromTask,
  watchAllocateFundsToTasks,
  watchDuplicateTasks,
  watchChangeTasksTags,
  watchChangeTasksVisibility,
  watchChangeTasksStatus,
  watchUpdateTaskProjectEstimateCost,
  watchRemoveTaskFileAttachments,
  watchRemoveTaskSelectedProducts,
  watchRemoveTaskSelectedTemplates,
  watchRemoveTaskSourceFiles,
  watchRecalculateTaskAccount,
  watchDownloadTaskSelectedProductsPdf,
  watchDownloadTaskSelectedTemplatesPdf,
  watchToggleTaskNotifications
} from './tasks/TaskSagas';

import {
  watchFetchTaskMemberInvites
} from './taskMemberInvites/TaskMemberInviteSagas';

import {
  watchFetchTaskNotifications,
  watchFetchTaskNotification,
  watchCreateTaskNotification,
  watchUpdateTaskNotification,
  watchRemoveTaskNotification
} from './taskNotifications/TaskNotificationSagas';

import {
  watchFetchTemplates,
  watchFetchTemplate,
  watchCreateTemplate,
  watchUpdateTemplate,
  watchDownloadTemplate,
  watchRemoveTemplate,
  watchToggleSelectedTemplate,
  watchToggleBlockedTemplate
} from './templates/TemplateSagas';

import {
  watchFetchTemplatesSet,
  watchDownloadTemplatesSet,
  watchEmptyTemplatesSet
} from './templatesSets/TemplatesSetSagas';

import {
  watchFetchTextureFiles,
  watchFetchTextureFile,
  watchUpdateTextureFile,
  watchDownloadTextureFile,
  watchUploadTextureFiles,
  watchRemoveTextureFile
} from './textureFiles/TextureFileSagas';

import {
  watchFetchTodoItems,
  watchFetchTodoItem,
  watchCreateTodoItem,
  watchUpdateTodoItem,
  watchRemoveTodoItem
} from './todoItems/TodoItemSagas';

import {
  watchFetchTransactions,
  watchDownloadTransactions
} from './transactions/TransactionSagas';

import {
  watchFetchUsers,
  watchFetchUser,
  watchUpdateUser,
  watchUpdatePassword,
  watchDeleteAccount,
  watchBlockUserAccount,
  watchUnblockUserAccount,
  watchUpdateUserPassword,
  watchUpdateUserEmail,
  watchDeleteUserAccount,
  watchToggleSelectedUser,
  watchDownloadUsersXls,
  watchAddUsersGroups,
  watchBlockUsers,
  watchUnblockUsers,
  watchOverwatchUsers,
  watchCancelOverwatchUsers,
  watchChangeUsersSeller,
  watchChangeUsersUpseller,
  watchDownloadWorkerDocuments
} from './users/UserSagas';

import {
  watchFetchRoles,
  watchFetchRole,
  watchCreateRole,
  watchUpdateRole,
  watchRemoveRole,
  watchAddPermissionToRole,
  watchRemovePermissionFromRole
} from './roles/RoleSagas';

import {
  watchFetchPages,
  watchFetchPage,
  watchCreatePage,
  watchUpdatePage,
  watchRemovePage
} from './pages/PageSagas';

import {
  watchCancelPayout
} from './payouts/PayoutSagas';

import {
  watchFetchPermissions,
  watchFetchPermission,
  watchCreatePermission,
  watchUpdatePermission,
  watchRemovePermission
} from './permissions/PermissionSagas';

import {
  watchFetchProjectProfiles,
  watchFetchProjectProfile,
  watchUpdateProjectProfile,
  watchRemoveProjectProfile,
  watchDownloadProjectProfileFileAttachments,
  watchDownloadProjectProfileSelectedProducts,
  watchDownloadProjectProfileSelectedTemplates,
  watchSubmitProjectProfileFileAttachments,
  watchSubmitProjectProfileSelectedProducts,
  watchSubmitProjectProfileSelectedTemplates
} from './projectProfiles/ProjectProfileSagas';

import {
  watchFetchContacts,
  watchFetchContact,
  watchCreateContact
} from './contacts/ContactSagas';

import {
  watchCreateIncomingPayments,
  watchCreateOutgoingPayments,
  watchCreateIncomingTransfers,
  watchCreateInternalTransfers,
  watchCreateOutgoingTransfers
} from './payments/PaymentSagas';

export default [
  watchFetchAllocations(),
  watchFetchAllocation(),
  watchCancelAllocation(),
  watchCancelAllocationFromClientBalance(),
  watchSetCanceledAllocation(),
  watchSetSharedAllocation(),
  watchShareAllocation(),
  watchShareAllocationFromClientBalance(),

  watchFetchBalances(),
  watchFetchBalance(),
  watchRecalculateBalances(),
  watchRecalculateBalanceAccount(),

  watchFetchBills(),
  watchFetchBill(),
  watchDownloadBillsXls(),
  watchPayBillFromAccount(),
  watchCheckBillPayment(),
  watchStripePayment(),
  watchCancelBill(),
  watchSetBillPaying(),
  watchCheckOrderPayment(),

  watchFetchBillRefills(),
  watchDownloadBillRefillsXls(),

  watchFetchCategories(),
  watchFetchCategory(),

  watchFetchChanges(),

  watchUploadClientFiles(),
  watchRemoveClientFile(),

  watchFetchDiscussionMemberInvites(),

  watchFetchDownloads(),
  watchFetchDownload(),
  watchUpdateDownload(),
  watchRemoveDownload(),
  watchCheckDownload(),
  watchDropdownDownloadsChannelCreated(),
  watchDownloadsChannelCreated(),
  watchDownloadsChannelUpdated(),

  watchFetchEmailTemplates(),
  watchFetchEmailTemplate(),
  watchCreateEmailTemplate(),
  watchUpdateEmailTemplate(),
  watchRemoveEmailTemplate(),

  watchFetchFileAttachments(),
  watchUpdateFileAttachment(),
  // watchDownloadFileAttachment(),
  watchUploadFileAttachments(),
  watchRemoveFileAttachment(),
  watchAttachFileAttachments(),

  watchFetchFinanceTags(),
  watchFetchFinanceTag(),
  watchCreateFinanceTag(),
  watchUpdateFinanceTag(),
  watchRemoveFinanceTag(),

  watchFetchImages(),
  watchUpdateImage(),
  watchDownloadImage(),
  watchUploadImages(),
  watchRemoveImage(),

  watchRemoveMaxFile(),
  watchUploadMaxFiles(),

  watchFetchLinkedAccounts(),
  watchFetchLinkedAccount(),
  watchCreateLinkedAccount(),
  watchUpdateLinkedAccount(),
  watchCreateLinkedAccounts(),
  watchRemoveLinkedAccount(),
  watchProvideAccessLinkedAccounts(),
  watchRequestAccessLinkedAccounts(),

  watchAcceptLinkedAccountInvite(),

  watchAcceptLinkedAccountRequest(),

  watchNullifyFundsMember(),

  watchFetchMessages(),
  watchFetchMessage(),
  watchCreateMessage(),
  watchUpdateMessage(),
  watchReadMessage(),
  watchUnreadMessage(),
  watchForwardMessage(),
  watchReadAllMessages(),
  watchToggleSelectedMessage(),
  watchDownloadMessageFileAttachments(),
  watchDownloadMessageSelectedProducts(),
  watchDownloadMessageSelectedTemplates(),
  watchSubmitMessageFileAttachments(),
  watchSubmitMessageSelectedProducts(),
  watchSubmitMessageSelectedTemplates(),
  watchDownloadMessagesXls(),

  watchFetchOperations(),

  watchFetchProjects(),
  watchFetchProject(),
  watchCreateProject(),
  watchUpdateProject(),
  watchAcceptInviteToProject(),
  watchToggleSelectedProject(),
  watchInviteUsersToProject(),
  watchRemoveUsersFromProject(),
  watchCreateProjectBills(),
  watchCreateProjectsBills(),
  watchUpdateProjectsAmounts(),
  watchAssignProjectToUser(),
  watchDownloadProjectsXls(),
  watchDownloadProjectResults(),
  watchDownloadProjectFileAttachments(),
  watchDownloadProjectSelectedProducts(),
  watchDownloadProjectSelectedTemplates(),
  watchDownloadProjectSourceFiles(),
  watchSubmitProjectFileAttachments(),
  watchSubmitProjectSelectedProducts(),
  watchSubmitProjectSelectedTemplates(),
  watchRecalculateProjectAccount(),
  watchToggleProjectNotifications(),

  watchFetchProjectNotifications(),
  watchFetchProjectNotification(),
  watchCreateProjectNotification(),
  watchUpdateProjectNotification(),
  watchRemoveProjectNotification(),

  watchFetchProductsSet(),
  watchDownloadProductsSet(),
  watchEmptyProductsSet(),

  watchFetchRefills(),
  watchFetchRefill(),

  watchFetchReports(),
  watchCreateReport(),
  watchRemoveReport(),
  watchDownloadReport(),
  watchGenerateReport(),

  watchFetchRevenueShareItems(),
  watchFetchRevenueShareItem(),
  watchCreateRevenueShareItem(),
  watchCreateRevenueShareItems(),
  watchUpdateRevenueShareItem(),
  watchUpdateRevenueShareItems(),
  watchRemoveRevenueShareItem(),

  watchFetchProducts(),
  watchFetchProduct(),
  watchCreateProduct(),
  watchUpdateProduct(),
  watchDownloadProduct(),
  watchToggleSelectedProduct(),
  watchToggleBlockedProduct(),
  watchRemoveProduct(),

  watchFetchSelectedProducts(),
  watchFetchSelectedProduct(),
  watchCreateSelectedProduct(),
  watchUpdateSelectedProduct(),
  watchDownloadSelectedProduct(),
  watchRemoveSelectedProduct(),
  watchAttachSelectedProducts(),

  watchFetchSelectedTemplates(),
  watchFetchSelectedTemplate(),
  watchCreateSelectedTemplate(),
  watchUpdateSelectedTemplate(),
  watchDownloadSelectedTemplate(),
  watchRemoveSelectedTemplate(),
  watchAttachSelectedTemplates(),

  watchFetchTags(),
  watchFetchTag(),
  watchCreateTag(),
  watchUpdateTag(),
  watchRemoveTag(),

  watchFetchTasks(),
  watchFetchTask(),
  watchCreateTask(),
  watchCreateTasks(),
  watchUpdateTask(),
  watchLeaveTask(),
  watchPauseTask(),
  watchAcceptTask(),
  watchRevisionTask(),
  watchRestoreTask(),
  watchCancelTask(),
  watchReallocateTask(),
  watchAcceptTaskResults(),
  watchAcceptTasksResults(),
  watchApproveTaskResults(),
  watchRevisionTaskResults(),
  watchRemoveTaskResults(),
  watchDownloadTaskAllocations(),
  watchDownloadTasksXls(),
  watchDownloadTaskFileAttachments(),
  watchDownloadTaskResults(),
  watchDownloadTasksResults(),
  watchDownloadTaskSelectedProducts(),
  watchDownloadTaskSelectedTemplates(),
  watchDownloadTaskSourceFiles(),
  watchSendTaskResultsToEmail(),
  watchSubmitTaskResults(),
  watchSubmitTaskSourceFiles(),
  watchSubmitTaskFileAttachments(),
  watchSubmitTaskSelectedProducts(),
  watchSubmitTaskSelectedTemplates(),
  watchTrackTaskTime(),
  watchTrackTasksTime(),
  watchCreateTaskBills(),
  watchCreateTasksBills(),
  watchToggleSelectedTask(),
  watchUpdateTasksAmounts(),
  watchUpdateTasksEstimateDates(),
  watchAcceptInviteToTask(),
  watchInviteUsersToTask(),
  watchAssignTaskToUser(),
  watchInviteUsersToTasks(),
  watchRemoveUsersFromTask(),
  watchAllocateFundsToTasks(),
  watchDuplicateTasks(),
  watchChangeTasksTags(),
  watchChangeTasksStatus(),
  watchChangeTasksVisibility(),
  watchUpdateTaskProjectEstimateCost(),
  watchRemoveTaskFileAttachments(),
  watchRemoveTaskSelectedProducts(),
  watchRemoveTaskSelectedTemplates(),
  watchRemoveTaskSourceFiles(),
  watchRecalculateTaskAccount(),
  watchDownloadTaskSelectedProductsPdf(),
  watchDownloadTaskSelectedTemplatesPdf(),
  watchToggleTaskNotifications(),

  watchFetchTaskMemberInvites(),

  watchFetchTaskNotifications(),
  watchFetchTaskNotification(),
  watchCreateTaskNotification(),
  watchUpdateTaskNotification(),
  watchRemoveTaskNotification(),

  watchFetchTemplates(),
  watchFetchTemplate(),
  watchCreateTemplate(),
  watchUpdateTemplate(),
  watchDownloadTemplate(),
  watchRemoveTemplate(),
  watchToggleSelectedTemplate(),
  watchToggleBlockedTemplate(),

  watchFetchTemplatesSet(),
  watchDownloadTemplatesSet(),
  watchEmptyTemplatesSet(),

  watchFetchSourceFiles(),
  watchUpdateSourceFile(),
  // watchDownloadSourceFile(),
  watchUploadSourceFiles(),
  watchRemoveSourceFile(),
  watchAttachSourceFiles(),

  watchFetchTextureFiles(),
  watchFetchTextureFile(),
  watchUpdateTextureFile(),
  watchDownloadTextureFile(),
  watchUploadTextureFiles(),
  watchRemoveTextureFile(),

  watchFetchTodoItems(),
  watchFetchTodoItem(),
  watchCreateTodoItem(),
  watchUpdateTodoItem(),
  watchRemoveTodoItem(),

  watchFetchTransactions(),
  watchDownloadTransactions(),

  watchFetchTransactionTags(),
  watchFetchTransactionTag(),
  watchCreateTransactionTag(),
  watchUpdateTransactionTag(),
  watchRemoveTransactionTag(),

  watchFetchUsers(),
  watchFetchUser(),
  watchUpdateUser(),
  watchUpdatePassword(),
  watchDeleteAccount(),
  watchBlockUserAccount(),
  watchUnblockUserAccount(),
  watchUpdateUserPassword(),
  watchUpdateUserEmail(),
  watchDeleteUserAccount(),
  watchToggleSelectedUser(),
  watchDownloadUsersXls(),
  watchAddUsersGroups(),
  watchBlockUsers(),
  watchUnblockUsers(),
  watchOverwatchUsers(),
  watchCancelOverwatchUsers(),
  watchChangeUsersSeller(),
  watchChangeUsersUpseller(),
  watchDownloadWorkerDocuments(),

  watchFetchRoles(),
  watchFetchRole(),
  watchCreateRole(),
  watchUpdateRole(),
  watchRemoveRole(),
  watchAddPermissionToRole(),
  watchRemovePermissionFromRole(),

  watchFetchPages(),
  watchFetchPage(),
  watchCreatePage(),
  watchUpdatePage(),
  watchRemovePage(),

  watchCancelPayout(),

  watchFetchPermissions(),
  watchFetchPermission(),
  watchCreatePermission(),
  watchUpdatePermission(),
  watchRemovePermission(),

  watchFetchProjectProfiles(),
  watchFetchProjectProfile(),
  watchUpdateProjectProfile(),
  watchRemoveProjectProfile(),
  watchDownloadProjectProfileFileAttachments(),
  watchDownloadProjectProfileSelectedProducts(),
  watchDownloadProjectProfileSelectedTemplates(),
  watchSubmitProjectProfileFileAttachments(),
  watchSubmitProjectProfileSelectedProducts(),
  watchSubmitProjectProfileSelectedTemplates(),

  watchFetchContacts(),
  watchFetchContact(),
  watchCreateContact(),

  watchFetchSentMessages(),
  watchFetchSentMessage(),

  watchCreateIncomingPayments(),
  watchCreateOutgoingPayments(),
  watchCreateIncomingTransfers(),
  watchCreateInternalTransfers(),
  watchCreateOutgoingTransfers()
];
