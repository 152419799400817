import Promise from 'bluebird';
import axios from 'axios';
import cookie from 'react-cookie';
import compact from 'lodash/compact';
import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';

import { MARKETING_API_URL } from '../../config';

import { authLoading, authUser, authError } from '../AuthActions';

import { mapErrorsToForm } from '../../utils/mapErrorsToForm';
import { apiRequest } from '../../utils/apiRequest';

// registerUser

const cookieParams = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  '_ga',
  'gclid',
  '__utmz_gtm',
  '_ym_uid',
  'ref'
];

function sendGoogleAnalycsEvents(currentUser) {
  const userID = get(currentUser, 'google_tag_user_id');

  // if (window.ga) {
  //   window.ga('send', 'event', 'registr', 'submit', 'done', 1);
  // }

  if (window.dataLayer) {
    // window.dataLayer.push({
    //   event: 'userIdUpdated',
    //   userID
    // });

    window.dataLayer.push({
      event: 'registration',
      userId: userID
    });
  }
}

function sendMarketingRequest(currentUser, ip, referer) {
  if (referer === 'cozymakeover.com') {
    return;
  }

  const params = fromPairs(compact(cookieParams.map(param => {
    const value = cookie.load(param);

    if (value && value !== '') {
      if (param === '_ga') {
        return ['cid', value.split('.').slice(2).join('.')];
      }

      return [param, value];
    }

    return null;
  })));

  return axios.post(`${MARKETING_API_URL}/contacts`, {
    lead_name: 'archivizer_leads',
    url: `${document.location.protocol}//${document.location.hostname}${document.location.pathname}`,
    title: document.title,
    ip,
    name: get(currentUser, 'full_name') || get(currentUser, 'name'),
    email: get(currentUser, 'email'),
    ...params
  });
}

export default function registerUser(auth) {
  return dispatch => {
    dispatch(authLoading());

    return apiRequest.authPost('auth/sign_up', { auth, serializer: 'sufficient' })
      .then(({ data: { data: currentUser, token, ip } }) => {
        sendGoogleAnalycsEvents(currentUser);
        sendMarketingRequest({ ...auth, ...currentUser }, ip, get(auth, 'referer'));
        return Promise.resolve(dispatch(authUser({ currentUser, token })));
      })
      .catch(error => mapErrorsToForm(dispatch, error, authError(error)));
  };
}
