import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, reduxForm } from 'redux-form';
import { intlShape, injectIntl } from 'react-intl';
import compose from 'recompose/compose';

import { withCurrentUser } from '../../../../common/HOCs/withCurrentUser';

import { AlertMessage, renderField } from '../../../../../helpers';
import { renderTextareaField } from '../../../../../helpers/renderTextareaField';
import { translate as t } from '../../../../../utils';
import { isEmail } from '../../../../../utils/isEmail';

function ContactForm({ isLoading, errorMessage, intl, handleSubmit, isAuthenticated }) {
  return (
    <Form onSubmit={handleSubmit}>
      <h2 className="text-center text-muted">
        {t('models.messages.write_us')}
      </h2>
      <p className="text-center text-muted mb-xl-5">
        {t('messages.online_ready_to_help')}
      </p>
      {!isAuthenticated && (
        <Fragment>
          <div className="row">
            <div className="col-sm-12">
              <Field
                name="name"
                required
                disabled={isLoading}
                component={renderField}
                inputClassName="form-control"
                placeholder={intl.formatMessage({
                  id: 'forms.fields.full_name'
                })}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <Field
                name="email"
                required
                disabled={isLoading}
                component={renderField}
                inputClassName="form-control"
                placeholder={intl.formatMessage({
                  id: 'forms.fields.your_email'
                })}
              />
            </div>
          </div>
        </Fragment>
      )}
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="subject"
            required
            disabled={isLoading}
            component={renderField}
            inputClassName="form-control"
            placeholder={intl.formatMessage({ id: 'models.messages.subject' })}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <Field
            name="body"
            required
            disabled={isLoading}
            component={renderTextareaField}
            inputClassName="form-control content-group"
            rows={2}
            placeholder={intl.formatMessage({ id: 'models.messages.text' })}
          />
        </div>
      </div>
      <AlertMessage>{errorMessage}</AlertMessage>
    </Form>
  );
}

ContactForm.propTypes = {
  isLoading: PropTypes.bool,
  errorMessage: PropTypes.string,
  intl: intlShape.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

ContactForm.defaultProps = {
  isLoading: false,
  errorMessage: null,
  isAuthenticated: false
};

function validate({ subject, body, name, email }) {
  const errors = {};

  if (!subject) {
    errors.subject = 'forms.errors.subject.required';
  }

  if (!body) {
    errors.body = 'forms.errors.body.required';
  }

  if (!name) {
    errors.name = 'forms.errors.name.required';
  }

  if (!email) {
    errors.email = 'forms.errors.email.required';
  } else if (!isEmail(email)) {
    errors.email = 'forms.errors.email.valid';
  }

  return errors;
}


export default compose(
  withCurrentUser({ fields: ['isAuthenticated'] }),
  reduxForm({ validate }),
  injectIntl
)(ContactForm);
