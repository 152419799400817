import { DOWNLOAD_BILLS_XLS } from '../BillConstants';

// downloadBillsXls

export default function downloadBillsXls(params, cb) {
  return dispatch => dispatch({
    type: DOWNLOAD_BILLS_XLS,
    ...params,
    cb
  });
}
