import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import { LoadableWrapper } from '../../utils/LoadableWrapper';

const TemplateEditPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "templates" */'./pages/TemplateEditPage/TemplateEditPage') });
const TemplatesIndexPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "templates" */'./pages/TemplatesIndexPage/TemplatesIndexPage') });

export default (
  <Fragment>
    <Route exact path="/templates/:templateId(\d+)" component={TemplateEditPage} />
    <Route exact path="/templates" component={TemplatesIndexPage} />
  </Fragment>
);
