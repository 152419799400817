import React, { Fragment, ReactNode, useCallback } from 'react';
import cl from 'classnames';
import isString from 'lodash/isString';

import { Item } from '../../../types';

import { Tooltip, TooltipProps } from '../Tooltip';

interface ButtonProps {
  item?: Item;
  id?: string;
  className?: string;
  addClass?: string | Array<{}> | Array<string>;
  color?: string;
  size?: string;
  outline?: boolean;
  rounded?: boolean;
  block?: boolean;
  icon?: string | ReactNode;
  afterIcon?: string;
  submit?: boolean;
  disabled?: boolean;
  onClick?: (arg: Item | React.MouseEvent<HTMLButtonElement>) => void;
  style?: {};
  children: ReactNode;
}

function Button({
  item,
  id,
  className,
  addClass,
  color,
  size,
  outline,
  rounded,
  block,
  icon,
  afterIcon,
  disabled,
  submit = false,
  onClick,
  children,
  style,
  tooltipTarget,
  tooltipI18nValue,
  tooltipPlacement,
  tooltipDelay,
  ...props
}: ButtonProps & TooltipProps) {
  const buttonClass =
    className ||
    cl(
      'btn',
      {
        [`btn-${color}`]: !!color,
        [`btn-${size}`]: !!size,
        'btn-outline': outline,
        'btn-rounded': rounded,
        'btn-block': block
      },
      addClass
    );

  const buttonIcon =
    icon &&
    (isString(icon) ? <i className={cl(icon, { 'mr-2': children })} /> : icon);
  const buttonAfterIcon =
    afterIcon &&
    (isString(afterIcon) ? (
      <i className={cl(afterIcon, { 'ml-2': children })} />
    ) : (
      afterIcon
    ));

  const handleClick = useCallback(
    e => {
      if (!submit) {
        e.preventDefault();
      }
      if (onClick) {
        onClick(item || e);
      }
    },
    [submit, onClick, item]
  );

  return (
    <Fragment>
      <button // eslint-disable-line
        id={id || tooltipTarget}
        type={submit ? 'submit' : 'button'}
        className={buttonClass}
        disabled={disabled}
        style={style}
        onClick={handleClick}
        {...props}
      >
        {buttonIcon}
        {buttonIcon && children ? ' ' : null}
        {children}
        {buttonAfterIcon && children ? ' ' : null}
        {buttonAfterIcon}
      </button>
      {tooltipTarget ? (
        <Tooltip
          placement={tooltipPlacement}
          target={tooltipTarget}
          delay={tooltipDelay}
          i18nText={tooltipI18nValue}
        />
      ) : null}
    </Fragment>
  );
}

export default Button;
