import { PAY_BILL_FROM_ACCOUNT } from '../BillConstants';

// payBillFromAccount

export default function payBillFromAccount(id, options = {}, cb) {
  return dispatch => dispatch({
    type: PAY_BILL_FROM_ACCOUNT,
    id,
    options,
    cb
  });
}
