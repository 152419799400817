import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import StripeCheckout from 'react-stripe-checkout';
import { injectIntl, intlShape } from 'react-intl';
import get from 'lodash/get';

import { STRIPE_API_KEY } from '../../config';

import { Button } from '../../helpers';
import { translate as t } from '../../utils';
import { renderAmount } from '../../utils/renderAmount';
import { analyticsSetBillCheckout } from '../../main/ts/bills/utils/analyticsSetBillCheckout';

function StripeCheckoutButton({ className, addClass, icon, bill, isLoading, disabled, onStripePayment, onSuccess, intl }) {
  const handlePayButtonClick = useCallback(() => {
    analyticsSetBillCheckout({ bill, step: 'click to pay' });
  }, [bill]);

  return (
    <StripeCheckout
      reconfigureOnUpdate
      name={`${intl.formatMessage({ id: 'words.pay' })} $${get(bill, 'amount')}`}
      description={`${intl.formatMessage({ id: 'models.bills.singular' })} #${get(bill, 'id')}`}
      amount={get(bill, 'amount') * 100}
      token={onStripePayment(bill, onSuccess)}
      currency="USD"
      stripeKey={STRIPE_API_KEY}
      disabled={disabled || isLoading}
    >
      <Button
        color="primary"
        className={className}
        addClass={addClass}
        disabled={disabled || isLoading}
        onClick={handlePayButtonClick}
      >
        <b className="mr-2">
          <i className={icon} />
        </b>
        {isLoading ? t('words.processing') : (
          <Fragment>
            {t('words.pay')}
            &nbsp;
            {renderAmount(get(bill, 'amount'), false)}
            &nbsp;
            {get(bill, 'currency')}
          </Fragment>
        )}
      </Button>
    </StripeCheckout>
  );
}

StripeCheckoutButton.propTypes = {
  bill: PropTypes.shape({ id: PropTypes.number }).isRequired,
  disabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  icon: PropTypes.string,
  className: PropTypes.string,
  addClass: PropTypes.string,
  onStripePayment: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  intl: intlShape.isRequired
};

StripeCheckoutButton.defaultProps = {
  icon: null,
  className: null,
  addClass: null,
  onSuccess: null
};

export default injectIntl(StripeCheckoutButton);
