import React, { Fragment } from 'react';

import { Route } from '../../../adapters/libs/react-router-dom';

import { LoadableWrapper } from '../../../utils/LoadableWrapper';

const BatchOperationsIndexPage = LoadableWrapper({
  loader: () =>
    import(
      /* webpackChunkName: "admin_batchOperations" */ './pages/BatchOperationsIndexPage/BatchOperationsIndexPage'
    )
});

export default (
  <Fragment>
    <Route
      exact
      path="/admin/batch_operations"
      component={BatchOperationsIndexPage}
    />
  </Fragment>
);
