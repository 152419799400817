import { CHECK_ORDER_PAYMENT } from '../BillConstants';

// checkOrderPayment

export default function checkOrderPayment(values, options = {}, cb) {
  return dispatch => dispatch({
    type: CHECK_ORDER_PAYMENT,
    values,
    options,
    cb
  });
}
