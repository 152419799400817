import { FETCH_ITEM_START } from '../../constants';

import { ShowRequestAction } from '../../reducers/showRequestReducer';

export default function fetchItemStart<ItemType>(): ShowRequestAction<
  ItemType
> {
  return {
    type: FETCH_ITEM_START
  };
}
