import Promise from 'bluebird';

import { authLoading, authUser, authError } from '../AuthActions';

import { mapErrorsToForm } from '../../utils/mapErrorsToForm';
import { apiRequest } from '../../utils/apiRequest';

import { sendGoogleAnalyticsEvents } from './utils';

// loginUser

export default function loginUser(auth) {
  return dispatch => {
    dispatch(authLoading());

    return apiRequest.authPost('auth/sign_in', { auth })
      .then(({ data: { data: currentUser, token } }) => {
        sendGoogleAnalyticsEvents(currentUser);
        return Promise.resolve(dispatch(authUser({ currentUser, token })));
      })
      .catch(error => mapErrorsToForm(dispatch, error, dispatch(authError(error))));
  };
}
