import React, { memo } from 'react';

import { HELP_PAGE_ID } from '../../../../../config';

import { PageModalLink } from '../../../../../main/ts/pages/helpers/PageModalLink';

function HelpPageModalLink() {
  return (
    <PageModalLink
      pageId={HELP_PAGE_ID as string}
      href={`/pages/${HELP_PAGE_ID}`}
      pureLink
      withoutCancel
      className="navbar-nav-link"
      submitColor="light"
      modalIcon="icon-question4"
      i18nModalTitle={'words.help'}
      i18nSubmitText="words.close"
      tooltipTarget="help-page-modal-link"
      tooltipI18nValue="words.help"
      icon="icon-question4"
    />
  );
}

export default memo(HelpPageModalLink);
