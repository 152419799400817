export default {
  acceptance: 'Ready for acceptance',
  account: 'Task account',
  add_body: 'Add task description',
  add_description: 'Add task description',
  burning: 'Burning tasks',
  cancel: 'Cancel task',
  count: 'Task count',
  create: 'Create task',
  create_multiple: 'Create multiple tasks',
  create_new: 'Create new task',
  details: 'Task Details',
  download_excel: 'Download excel document with all tasks',
  download_project_excel: 'Download excel document with all project tasks',
  download_selected_excel: 'Download excel document with selected tasks',
  duplicate: 'Duplicate tasks',
  edit_description: 'Edit task description',
  edit_details: 'Edit task details',
  enter_name: 'Enter task name',
  import: 'Import tasks',
  leave: 'Leave the task',
  members: 'Task members',
  multiple: 'Multiple tasks',
  my: 'My tasks',
  name: 'Task name',
  new: 'New Task',
  'new...': 'What needs to be done?',
  no: 'No tasks',
  pause: 'Pause task',
  plural: 'Tasks',
  profile: 'Task profile',
  restore: 'Restore task',
  select: 'Select task...',
  select_user: 'Select task member',
  selected_users: 'Selected task members',
  set_cost: 'Set task cost',
  show_all: 'Show all tasks',
  show_done: 'Show done tasks',
  show_hidden: 'Show hidden tasks',
  show_visible: 'Show visible tasks',
  show_only_visible: 'Show only visible tasks',
  show_with_canceled: 'Show with canceled tasks',
  show_without_canceled: 'Show without canceled tasks',
  show_with_done: 'Show with done tasks',
  show_without_done: 'Show without done tasks',
  show_with_hidden: 'Show with hidden tasks',
  single: 'Single task',
  singular: 'Task',
  singular_lower_case: 'task',
  states: {
    acceptance: 'Ready for acceptance',
    buttons: {
      cancel: 'Cancel',
      pause: 'Pause',
      restore: 'Restore',
      revision: 'Revision'
    },
    canceled: 'Canceled',
    definition: 'Setting task',
    incomplete_specification: 'Pending more info',
    performed: 'Done',
    qa: 'Quality control'
  },
  status: 'Task Status',
  status_change: 'Task status change',
  tags_change: 'Task tags change',
  task_timer: 'Task timer',
  team: 'Task team',
  unassigned: 'Unassigned tasks'
};
