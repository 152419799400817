function generateHtmlForm(str: string) {
  const iOS =
    !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  const isSafari =
    navigator.vendor &&
    navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') === -1 &&
    navigator.userAgent.indexOf('FxiOS') === -1;
  return iOS && isSafari && str ? str.replace(/target="_blank"/gi, '') : str;
}

export default generateHtmlForm;
