import { FETCH_ITEM_SUCCESS } from '../../constants';

import { ShowRequestAction } from '../../reducers/showRequestReducer';

export function fetchItemSuccess<ItemType>(
  item: ItemType
): ShowRequestAction<ItemType> {
  return {
    type: FETCH_ITEM_SUCCESS,
    item
  };
}
