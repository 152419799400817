import users from './users';

export default {
  users,
  presence: 'This field is required.',
  notANumber: 'Is not a number.',
  notAnInteger: 'Is not an integer number.',
  greaterThan: 'Must be greater than {count}.',
  greaterThanOrEqualTo: 'Must be greater than or equal to {count}.',
  lessThan: 'Must be less than {count}.',
  lessThanOrEqualTo: 'Must be less than or equal to {count}.',
  equalTo: 'Must be equal {count}.',
  otherThan: 'Must be not equal {count}.',
  odd: 'Must be odd.',
  even: 'Must be even.',
  dateFormat: 'Expected format: {format}.',
  dateInvalid: 'Is not a valid date.',
  dateRange: 'Should be {op} {date}.',
  tooShort: 'Is too short (minimum is {count} characters).',
  tooLong: 'Is too long (maximum is {count} characters).',
  wrongLength: 'Is the wrong length (should be {count} characters).',
  confirmation: 'Does not match \'{fieldLabel}\'.',
  invalid: 'Invalid format.',
  acceptance: 'Must be accepted.',
  inclusion: 'Is not included in the list.',
  exclusion: 'Is reserved.',
  absence: 'Must be blank.',
  url: 'Is not a valid URL.',

  current_password: {
    required: 'Please enter current password'
  },
  new_password: {
    required: 'Please enter new password'
  },
  file_import_ids: {
    required: 'Please, select file to import'
  },
  first_name: {
    required: 'Please enter first name'
  },
  last_name: {
    required: 'Please enter last name'
  },
  phone: {
    required: 'Please enter phone number'
  },
  name: {
    required: 'Please enter a name'
  },
  email: {
    required: 'Please enter an email',
    valid: 'Please enter valid email'
  },
  passport_number: {
    required: 'Please enter passport series and number'
  },
  passport_first_page: {
    required: 'Please attach passport first page photo'
  },
  passport_second_page: {
    required: 'Please attach passport second page photo'
  },
  password: {
    required: 'Please enter password',
    valid: 'Please enter valid password. It should contain at least 6 characters'
  },
  password_confirmation: {
    required: 'Please enter password confirmation',
    match: 'Passwords must match'
  },
  policy: {
    required: 'You must accept the Payment, Refund, Privacy policy'
  },
  non_disclosure_agreement: {
    required: 'You must accept the Privacy Policy'
  },
  service_agreement: {
    required: 'You must accept the Service Agreement'
  },
  amount: {
    required: 'Please, enter amount',
    positive: 'Amount must be positive number'
  },
  set_date: {
    required: 'Please enter created date',
    in_future: 'Created date must be in the future'
  },
  implementation_date: {
    in_future: 'Estimate date must be in the future',
    invalid: 'Estimate date is invalid or not set'
  },
  start_date: {
    in_future: 'Start date must be in the future'
  },
  title: {
    required: 'Please, enter title'
  },
  author_id: {
    required: 'Please, select client'
  },
  category_id: {
    required: 'Please, select category'
  },
  style_id: {
    required: 'Please, select style'
  },
  material_id: {
    required: 'Please, select material'
  },
  tone_id: {
    required: 'Please, select tone'
  },
  gamma_id: {
    required: 'Please, select gamma'
  },
  performer_id: {
    required: 'Please, select performer'
  },
  project_id: {
    required: 'Please, select project'
  },
  manufacturer_id: {
    required: 'Please, select manufacturer'
  },
  estimate_cost: {
    required: 'Please enter estimate cost',
    positive: 'Estimate cost must be positive number',
    non_negative: 'Estimate cost must be non-negative number'
  },
  payoneer_id: {
    selected_id_required: 'Please, enter selected type id'
  },
  prepayment: {
    positive: 'Prepayment must be positive number'
  },
  commission: {
    positive: 'Commission must be positive number'
  },
  credit_commission: {
    positive: 'Credit commission must be positive number'
  },
  hosting_commission: {
    positive: 'Hosting commission must be positive number'
  },
  rating: {
    required: 'You must rate the results'
  },
  feedback: {
    required: 'Please, add your feedback'
  },
  hours: {
    required: 'Please, enter time in hours',
    range: 'Hours must be in range from 0 to 7'
  },
  minutes: {
    required: 'Please, enter time in minutes',
    range: 'Minutes must be in range from 0 to 59'
  },
  file_attachment_ids: {
    required: 'Please, attach files.'
  },
  tags: {
    required: 'Please, select tags'
  },
  add: {
    less_than_debt: 'Value must be less than debt.',
    allocated_less_than_unallocated: 'Total allocated amount must be less than project unallocated sum.'
  },
  value: {
    positive: 'Must be a positive value',
    required: 'Please enter a value'
  },
  destination_project: {
    required: 'Please, select destination project.'
  },
  user: {
    required: 'Please select user.'
  },
  subject: {
    required: 'Please, enter message subject'
  },
  body: {
    required: 'Please, enter message text'
  },
  project: {
    required: 'Please, select project.'
  },
  task: {
    required: 'Please, select task.'
  },
  image_id: {
    required: 'Please, attach image file'
  },
  max_file_id: {
    required: 'Please, attach 3d model archive file'
  },
  main_color_ids: {
    required: 'Please, select main color'
  },
  accent_color_ids: {
    required: 'Please, select accent color'
  },
  account: {
    required: 'Please, select account.',
    must_be_different: 'Receiver and sender account types must be different.'
  },
  transaction_description: {
    required: 'Please, enter transaction description.'
  },
  payment_type: {
    required: 'Please, select payment type.'
  },
  seller_id: {
    required: 'Please, select sales manager.'
  },
  upsaler_id: {
    required: 'Please, select client manager.'
  },
  date: {
    required: 'Please, select date.'
  },
  operation_type: {
    required: 'Please, select operation type.'
  },
  state: {
    required: 'Please, select state.'
  },
  room_type: {
    required: 'Please, select room type.'
  },
  room_name: {
    required: 'Please, enter room name.'
  }
};
