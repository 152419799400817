export default {
  plural_downcase: 'templates',
  select: 'Attach template',
  singular_lower_case_accusative: 'template',
  no: 'No templates',
  add: 'Add a template',
  details: 'Template details',
  library: 'Templates library',
  singular: 'Selected Template',
  show: 'Show selected templates'
};
