import React, { Fragment, memo, ReactNode } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
// import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';

import { TOOLTIP_DELAY_HIDE, TOOLTIP_DELAY_SHOW } from '../../constants';

import { translate as t } from '../../../utils/ts/translate';

const defaultDelay = { show: TOOLTIP_DELAY_SHOW, hide: TOOLTIP_DELAY_HIDE };

export type TooltipPlacement =
  | 'auto-start'
  | 'auto'
  | 'auto-end'
  | 'top-start'
  | 'top'
  | 'top-end'
  | 'right-start'
  | 'right'
  | 'right-end'
  | 'bottom-end'
  | 'bottom'
  | 'bottom-start'
  | 'left-end'
  | 'left'
  | 'left-start';

interface TooltipDelay {
  show: number;
  hide: number;
}

interface TooltipComponentProps {
  target: string;
  className?: string;
  delay?: TooltipDelay;
  withIcon?: boolean;
  i18nText?: string;
  placement?: TooltipPlacement;
  children?: ReactNode;
}

export interface TooltipProps {
  tooltipTarget?: string;
  tooltipValue?: ReactNode;
  tooltipPlacement?: TooltipPlacement;
  tooltipDelay?: TooltipDelay;
  tooltipI18nValue?: string;
}

function Tooltip({
  target,
  className,
  delay,
  placement = 'bottom',
  i18nText,
  withIcon = true,
  children
}: TooltipComponentProps) {
  return (
    <Fragment>
      {withIcon ? <i id={target} className={className} /> : null}
      <UncontrolledTooltip
        target={target}
        delay={delay || defaultDelay}
        placement={placement}
      >
        {i18nText ? t(i18nText) : children}
      </UncontrolledTooltip>
    </Fragment>
  );
}

export default memo(Tooltip);
