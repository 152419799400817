class LocalStorage {
  static loadItem<T>(key: string): T | null {
    const value: any = localStorage.getItem(key);

    if (!value) return null;

    return JSON.parse(value) as T;
  }

  static saveItem<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }
}

export default LocalStorage;
