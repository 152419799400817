import { useCallback, useEffect } from 'react';

import isFunction from 'lodash/isFunction';

function useOnViewportChange(callback: Function): void {
  const handleViewportChange = useCallback(
    event => {
      if (isFunction(callback)) callback(event);
    },
    [callback]
  );

  useEffect(() => {
    window.addEventListener('resize', handleViewportChange);

    return () => {
      window.removeEventListener('resize', handleViewportChange);
    };
  });
}

export default useOnViewportChange;
