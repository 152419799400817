import { SET_BILL_PAYING } from '../BillConstants';

// setBillPaying

export default function setBillPaying(id, options = {}, cb) {
  return dispatch => dispatch({
    type: SET_BILL_PAYING,
    id,
    options,
    cb
  });
}
