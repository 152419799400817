import get from 'lodash/get';

import { Promise } from '../../../../../../../adapters/libs/bluebird';

import { fetchItemStart } from '../../actions/fetchItemStart';
import { fetchItemSuccess } from '../../actions/fetchItemSuccess';
import { fetchItemError } from '../../actions/fetchItemError';

import { ShowRequestAction } from '../../reducers/showRequestReducer';

import { apiRequest } from '../../../../../../../utils/apiRequest';
import { parseError } from '../../../../../../../utils/ts/parseError';

interface RequestConfig {
  serializer?: string;
}

export default function sendShowRequest<ItemType>(
  url: string,
  requestConfig: RequestConfig,
  dispatch: (action: ShowRequestAction<ItemType>) => void
) {
  dispatch(fetchItemStart<ItemType>());
  return new Promise<void>((resolve, reject) =>
    apiRequest
      .get(url, {
        serializer: requestConfig.serializer
      })
      .then(successResponse => {
        const item = get(successResponse, 'data.data');
        dispatch(fetchItemSuccess<ItemType>(item));
        resolve(item);
      })
      .catch(errorResponse => {
        dispatch(fetchItemError<ItemType>(parseError(errorResponse)));
        reject();
      })
  );
}
