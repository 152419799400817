export default {
  plural: 'Refills',
  singular: 'Refill',
  no: 'No refills',
  payer: 'Payer',
  statuses: {
    paid: 'Paid',
    paying: 'Paying',
    not_paid: 'Not paid',
    non_paid: 'Not paid',
    canceled: 'Canceled'
  },
  types: {
    swift_refill: 'Swift Refill',
    charge_back_refill: 'Chargeback Refill',
    two_checkout_refill: 'TwoCheckout Refill',
    rietumu_refill: 'Rietumu Refill',
    raiffeisen_bank_refill: 'RaiffeisenBank Refill',
    eway_refill: 'Eway Refill'
  }
};
