import get from 'lodash/get';

import { updateState, replaceById, removeById } from '../../../utils';
import { currentStateValue } from '../../../utils/currentStateValue';

export function removeMessageSelectedAttachmentTextureFilesStart(state, { id, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_message_id, message => ({
      ...message,
      ...(options.selected_facility_id ? {
        selected_facilities: replaceById(get(message, 'selected_facilities', []), options.selected_facility_id, selectedProduct => ({
          ...selectedProduct,
          texture_files: replaceById(get(selectedProduct, 'texture_files', []), id, textureFile => ({
            ...textureFile,
            error: null,
            loading: true
          }))
        }))
      } : {}),
      ...(options.selected_scene_id ? {
        selected_scenes: replaceById(get(message, 'selected_scenes', []), options.selected_scene_id, selectedTemplate => ({
          ...selectedTemplate,
          texture_files: replaceById(get(selectedTemplate, 'texture_files', []), id, textureFile => ({
            ...textureFile,
            error: null,
            loading: true
          }))
        }))
      } : {})
    }))
  }, options);
}

export function removeMessageSelectedAttachmentTextureFilesSuccess(state, { id, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_message_id, message => ({
      ...message,
      ...(options.selected_facility_id ? {
        selected_facilities: replaceById(get(message, 'selected_facilities', []), options.selected_facility_id, selectedProduct => ({
          ...selectedProduct,
          texture_files: removeById(get(selectedProduct, 'texture_files', []), id)
        }))
      } : {}),
      ...(options.selected_scene_id ? {
        selected_scenes: replaceById(get(message, 'selected_scenes', []), options.selected_scene_id, selectedTemplate => ({
          ...selectedTemplate,
          texture_files: removeById(get(selectedTemplate, 'texture_files', []), id)
        }))
      } : {})
    }))
  }, options);
}

export function removeMessageSelectedAttachmentTextureFilesError(state, { id, errorMessage, options = {} }) {
  return updateState(state, {
    items: replaceById(currentStateValue(state, 'items', options), options.discussion_message_id, message => ({
      ...message,
      ...(options.selected_facility_id ? {
        selected_facilities: replaceById(get(message, 'selected_facilities', []), options.selected_facility_id, selectedProduct => ({
          ...selectedProduct,
          texture_files: replaceById(get(selectedProduct, 'texture_files', []), id, textureFile => ({
            ...textureFile,
            error: errorMessage,
            loading: false
          }))
        }))
      } : {}),
      ...(options.selected_scene_id ? {
        selected_scenes: replaceById(get(message, 'selected_scenes', []), options.selected_scene_id, selectedTemplate => ({
          ...selectedTemplate,
          texture_files: replaceById(get(selectedTemplate, 'texture_files', []), id, textureFile => ({
            ...textureFile,
            error: errorMessage,
            loading: false
          }))
        }))
      } : {})
    }))
  }, options);
}
