import { FETCH_ITEM_ERROR } from '../../constants';

import { ShowRequestAction } from '../../reducers/showRequestReducer';

function fetchItemError<ItemType>(
  itemErrorMessage: string
): ShowRequestAction<ItemType> {
  return {
    type: FETCH_ITEM_ERROR,
    itemErrorMessage
  };
}

export default fetchItemError;
