import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import { LoadableWrapper } from '../utils/LoadableWrapper';

const LoginPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "auth" */'./pages/LoginPage/LoginPage') });
const FacebookTokenLoginPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "auth" */'./pages/FacebookTokenLoginPage/FacebookTokenLoginPage') });
const GoogleLoginPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "auth" */'./pages/GoogleLoginPage/GoogleLoginPage') });
const PolicyPage = LoadableWrapper({ loader: () => import('./pages/PolicyPage/PolicyPage') });
const RegisterPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "auth" */'./pages/RegisterPage/RegisterPage') });
const RegisterWorkerPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "auth" */'./pages/RegisterWorkerPage/RegisterWorkerPage') });
const ResetPasswordPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "auth" */'./pages/ResetPasswordPage/ResetPasswordPage') });
const UpdatePasswordPage = LoadableWrapper({ loader: () => import(/* webpackChunkName: "auth" */'./pages/UpdatePasswordPage/UpdatePasswordPage') });

export default (
  <Fragment>
    <Route exact path="/auth/login" component={LoginPage} />
    <Route exact path="/auth/facebook" component={FacebookTokenLoginPage} />
    <Route exact path="/auth/google" component={GoogleLoginPage} />
    <Route exact path="/auth/register" component={RegisterPage} />
    <Route exact path="/auth/register/worker" component={RegisterWorkerPage} />
    <Route exact path="/auth/forgot" component={ResetPasswordPage} />
    <Route exact path="/auth/update/:token" component={UpdatePasswordPage} />
    <Route exact path="/register" component={RegisterPage} />
    <Route exact path="/pages/:pageId(\d+)" component={PolicyPage} />
  </Fragment>
);
