import { CHECK_BILL_PAYMENT } from '../BillConstants';

// checkBillPayment

export default function checkBillPayment(id, options = {}, cb) {
  return dispatch => dispatch({
    type: CHECK_BILL_PAYMENT,
    id,
    options,
    cb
  });
}
