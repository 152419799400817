import { LOCATION_CHANGE } from 'react-router-redux';
import { takeEvery, put, spawn, take, cancel } from 'redux-saga/effects';

import {
  FETCH_BILL,
  FETCH_BILL_START,
  FETCH_BILL_SUCCESS,
  FETCH_BILL_ERROR
} from '../BillConstants';

import { fetchItemStart, fetchItemResource } from '../../common/sagas';

function* fetchBill({ id, options, cb }) {
  yield put(fetchItemStart(FETCH_BILL_START, id, options));

  const fetchBillAction = yield spawn(fetchItemResource, 'bills', {
    success: FETCH_BILL_SUCCESS,
    error: FETCH_BILL_ERROR
  }, { id, options, cb });

  yield take(LOCATION_CHANGE);
  yield cancel(fetchBillAction);
}

export default function* watchFetchBill() {
  yield takeEvery(FETCH_BILL, fetchBill);
}
