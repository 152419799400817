import { createReducer } from 'redux-create-reducer';

import {
  defaultState,
  fetchItemsStart, fetchItemsSuccess, fetchItemsError,
  fetchItemStart, fetchItemSuccess, fetchItemError,
  createItemStart, createItemSuccess, createItemError,
  updateItemStart, updateItemSuccess, updateItemError,
  clearItemsFiltersStart, clearItemsFiltersSuccess,
  itemsChannelUpdated, locationChange
} from '../../common/reducers';

import { initMessagesFilters } from './common';
import {
  toggleSelectedMessageStart,
  toggleSelectedMessageSuccess,
  toggleSelectedMessageError
} from './toggleSelectedMessage';

import { clearMessageModalValues } from './clearMessageModalValues';

import { readMessageStart, readMessageSuccess, readMessageError } from './readMessage';
import { unreadMessageStart, unreadMessageSuccess, unreadMessageError } from './unreadMessage';

import { updateMessageFileAttachmentsStart, updateMessageFileAttachmentsSuccess, updateMessageFileAttachmentsError } from './updateMessageFileAttachments';
import { removeMessageFileAttachmentsStart, removeMessageFileAttachmentsSuccess, removeMessageFileAttachmentsError } from './removeMessageFileAttachments';

import { updateMessageSelectedProductsStart, updateMessageSelectedProductsSuccess, updateMessageSelectedProductsError } from './updateMessageSelectedProducts';
import { removeMessageSelectedProductsStart, removeMessageSelectedProductsSuccess, removeMessageSelectedProductsError } from './removeMessageSelectedProducts';

import { updateMessageSelectedTemplatesStart, updateMessageSelectedTemplatesSuccess, updateMessageSelectedTemplatesError } from './updateMessageSelectedTemplates';
import { removeMessageSelectedTemplatesStart, removeMessageSelectedTemplatesSuccess, removeMessageSelectedTemplatesError } from './removeMessageSelectedTemplates';

import {
  forwardMessageStart,
  forwardMessageSuccess,
  forwardMessageError
} from './forwardMessage';

import {
  removeMessageSelectedAttachmentTextureFilesStart,
  removeMessageSelectedAttachmentTextureFilesSuccess,
  removeMessageSelectedAttachmentTextureFilesError
} from './removeMessageSelectedAttachmentTextureFiles';

import {
  submitMessageFileAttachmentsStart,
  submitMessageFileAttachmentsSuccess,
  submitMessageFileAttachmentsError
} from './submitMessageFileAttachments';

import {
  submitMessageSelectedProductsStart,
  submitMessageSelectedProductsSuccess,
  submitMessageSelectedProductsError
} from './submitMessageSelectedProducts';

import {
  submitMessageSelectedTemplatesStart,
  submitMessageSelectedTemplatesSuccess,
  submitMessageSelectedTemplatesError
} from './submitMessageSelectedTemplates';

import {
  readAllMessagesStart,
  readAllMessagesSuccess,
  readAllMessagesError
} from './readAllMessages';

import { projectsChannelMessages } from './projectsChannelMessages';
import { tasksChannelMessages } from './tasksChannelMessages';

export default createReducer(defaultState, {
  '@@router/LOCATION_CHANGE': locationChange(defaultState),
  AUTH_USER: initMessagesFilters,

  FETCH_MESSAGES_START: fetchItemsStart,
  FETCH_MESSAGES_SUCCESS: fetchItemsSuccess,
  FETCH_MESSAGES_ERROR: fetchItemsError,

  FETCH_MESSAGE_START: fetchItemStart,
  FETCH_MESSAGE_SUCCESS: fetchItemSuccess,
  FETCH_MESSAGE_ERROR: fetchItemError,

  CREATE_MESSAGE_START: createItemStart,
  CREATE_MESSAGE_SUCCESS: createItemSuccess,
  CREATE_MESSAGE_ERROR: createItemError,

  UPDATE_MESSAGE_START: updateItemStart,
  UPDATE_MESSAGE_SUCCESS: updateItemSuccess,
  UPDATE_MESSAGE_ERROR: updateItemError,

  TOGGLE_SELECTED_MESSAGE_START: toggleSelectedMessageStart,
  TOGGLE_SELECTED_MESSAGE_SUCCESS: toggleSelectedMessageSuccess,
  TOGGLE_SELECTED_MESSAGE_ERROR: toggleSelectedMessageError,

  CLEAR_MESSAGE_FILTERS_START: clearItemsFiltersStart,
  CLEAR_MESSAGE_FILTERS_SUCCESS: clearItemsFiltersSuccess,

  CLEAR_MESSAGE_MODAL_VALUES: clearMessageModalValues,

  MESSAGES_CHANNEL_UPDATED: itemsChannelUpdated,

  READ_MESSAGE_START: readMessageStart,
  READ_MESSAGE_SUCCESS: readMessageSuccess,
  READ_MESSAGE_ERROR: readMessageError,

  UNREAD_MESSAGE_START: unreadMessageStart,
  UNREAD_MESSAGE_SUCCESS: unreadMessageSuccess,
  UNREAD_MESSAGE_ERROR: unreadMessageError,

  FORWARD_MESSAGE_START: forwardMessageStart,
  FORWARD_MESSAGE_SUCCESS: forwardMessageSuccess,
  FORWARD_MESSAGE_ERROR: forwardMessageError,

  UPDATE_FILE_ATTACHMENT_START: updateMessageFileAttachmentsStart,
  UPDATE_FILE_ATTACHMENT_SUCCESS: updateMessageFileAttachmentsSuccess,
  UPDATE_FILE_ATTACHMENT_ERROR: updateMessageFileAttachmentsError,

  REMOVE_FILE_ATTACHMENT_START: removeMessageFileAttachmentsStart,
  REMOVE_FILE_ATTACHMENT_SUCCESS: removeMessageFileAttachmentsSuccess,
  REMOVE_FILE_ATTACHMENT_ERROR: removeMessageFileAttachmentsError,

  UPDATE_SELECTED_PRODUCT_START: updateMessageSelectedProductsStart,
  UPDATE_SELECTED_PRODUCT_SUCCESS: updateMessageSelectedProductsSuccess,
  UPDATE_SELECTED_PRODUCT_ERROR: updateMessageSelectedProductsError,

  REMOVE_SELECTED_PRODUCT_START: removeMessageSelectedProductsStart,
  REMOVE_SELECTED_PRODUCT_SUCCESS: removeMessageSelectedProductsSuccess,
  REMOVE_SELECTED_PRODUCT_ERROR: removeMessageSelectedProductsError,

  UPDATE_SELECTED_TEMPLATE_START: updateMessageSelectedTemplatesStart,
  UPDATE_SELECTED_TEMPLATE_SUCCESS: updateMessageSelectedTemplatesSuccess,
  UPDATE_SELECTED_TEMPLATE_ERROR: updateMessageSelectedTemplatesError,

  REMOVE_SELECTED_TEMPLATE_START: removeMessageSelectedTemplatesStart,
  REMOVE_SELECTED_TEMPLATE_SUCCESS: removeMessageSelectedTemplatesSuccess,
  REMOVE_SELECTED_TEMPLATE_ERROR: removeMessageSelectedTemplatesError,

  REMOVE_TEXTURE_FILE_START: removeMessageSelectedAttachmentTextureFilesStart,
  REMOVE_TEXTURE_FILE_SUCCESS: removeMessageSelectedAttachmentTextureFilesSuccess,
  REMOVE_TEXTURE_FILE_ERROR: removeMessageSelectedAttachmentTextureFilesError,

  SUBMIT_MESSAGE_FILE_ATTACHMENTS_START: submitMessageFileAttachmentsStart,
  SUBMIT_MESSAGE_FILE_ATTACHMENTS_SUCCESS: submitMessageFileAttachmentsSuccess,
  SUBMIT_MESSAGE_FILE_ATTACHMENTS_ERROR: submitMessageFileAttachmentsError,

  SUBMIT_MESSAGE_SELECTED_PRODUCTS_START: submitMessageSelectedProductsStart,
  SUBMIT_MESSAGE_SELECTED_PRODUCTS_SUCCESS: submitMessageSelectedProductsSuccess,
  SUBMIT_MESSAGE_SELECTED_PRODUCTS_ERROR: submitMessageSelectedProductsError,

  SUBMIT_MESSAGE_SELECTED_TEMPLATES_START: submitMessageSelectedTemplatesStart,
  SUBMIT_MESSAGE_SELECTED_TEMPLATES_SUCCESS: submitMessageSelectedTemplatesSuccess,
  SUBMIT_MESSAGE_SELECTED_TEMPLATES_ERROR: submitMessageSelectedTemplatesError,

  PROJECTS_CHANNEL_MESSAGES: projectsChannelMessages,
  PROJECTS_CHANNEL_HIDDEN_MESSAGES: projectsChannelMessages,
  PROJECTS_CHANNEL_RED_MESSAGES: projectsChannelMessages,

  TASKS_CHANNEL_MESSAGES: tasksChannelMessages,
  TASKS_CHANNEL_HIDDEN_MESSAGES: tasksChannelMessages,
  TASKS_CHANNEL_RED_MESSAGES: tasksChannelMessages,

  READ_ALL_MESSAGES_START: readAllMessagesStart,
  READ_ALL_MESSAGES_SUCCESS: readAllMessagesSuccess,
  READ_ALL_MESSAGES_ERROR: readAllMessagesError
});
