import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import includes from 'lodash/includes';

import { BillsContainer } from '../../../../common/containers/BillsContainer';

import { AlertMessage, LinkTo, Loading } from '../../../../../helpers';
import { ModalButton } from '../../../../../helpers/ModalButton';
import { StripeCheckoutButton } from '../../../../../helpers/StripeCheckoutButton';
import { TwoCheckoutButton } from '../../../../common/helpers/TwoCheckoutButton';
import { translate as t } from '../../../../../utils';
import { CurrencyAmount } from '../../../../../helpers/ts/CurrencyAmount';
import { analyticsSetBillCheckout } from '../../../../ts/bills/utils/analyticsSetBillCheckout';

class ItemBillsModal extends PureComponent {
  static propTypes = {
    id: PropTypes.number.isRequired,
    item: PropTypes.shape({ id: PropTypes.number }),
    className: PropTypes.string,
    submitClassName: PropTypes.string,
    addClass: PropTypes.string,
    modalButtonAddClass: PropTypes.string,
    modalButtonColor: PropTypes.string,
    type: PropTypes.string.isRequired,
    buttonText: PropTypes.node.isRequired,
    tooltip: PropTypes.shape({
      target: PropTypes.string.isRequired,
      value: PropTypes.node.isRequired,
      placement: PropTypes.string
    }),
    submitIcon: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    modalIcon: PropTypes.string,
    onPayment: PropTypes.func
  };

  static defaultProps = {
    className: null,
    submitClassName: null,
    addClass: null,
    modalButtonAddClass: null,
    submitIcon: null,
    disabled: false,
    modalButtonColor: 'primary',
    icon: null,
    modalIcon: null,
    item: null,
    tooltip: null,
    onPayment: null
  };

  handleOnToggle = ({ fetchBill }) => opened =>
    opened && fetchBill(this.props.id);

  handleSubmit = ({ bill, payBillFromAccount }) => ({ hideModal }) => () => {
    if (this.props.type === 'card') {
      analyticsSetBillCheckout({ bill, step: 'click to pay' });
      return bill.payment_url && window.open(bill.payment_url);
    }

    return payBillFromAccount(bill, () => {
      hideModal();
      this.props.onPayment && this.props.onPayment();
    });
  };

  handleRenderSubmit = ({ bill, billFetched, billLoading, stripePayment }) => ({
    hideModal
  }) => {
    if (!billFetched || this.props.type === 'account') {
      return null;
    }

    switch (bill.button_type) {
      case 'twocheckout_button':
        return (
          <TwoCheckoutButton
            icon={this.props.submitIcon}
            className={this.props.submitClassName}
            addClass={this.props.addClass}
            bill={bill}
            isLoading={billLoading}
            disabled={!billFetched || billLoading}
            onStripePayment={stripePayment}
            onSuccess={hideModal}
          />
        );
      case 'stripe_button':
        return (
          <StripeCheckoutButton
            icon={this.props.submitIcon}
            className={this.props.submitClassName}
            addClass={this.props.addClass}
            bill={bill}
            isLoading={billLoading}
            disabled={!billFetched || billLoading}
            onStripePayment={stripePayment}
            onSuccess={hideModal}
          />
        );

      default:
        return null;
    }
  };

  renderBill = ({
    bill,
    billFetched,
    billLoading,
    billErrorMessage,
    fetchBill,
    stripePayment,
    payBillFromAccount
  }) => (
    <ModalButton
      withoutFooter={
        !billFetched ||
        (this.props.type === 'card' &&
          includes(['twocheckout_button', 'stripe_button'], bill.button_type))
      }
      modalSize="lg"
      modalTitle={t(`models.bills.payment_types.${this.props.type}`)}
      modalIcon={`${this.props.modalIcon} mr-2`}
      color={this.props.modalButtonColor}
      icon={this.props.icon}
      className={this.props.className}
      addClass={this.props.modalButtonAddClass || this.props.addClass}
      buttonText={this.props.buttonText}
      submitDisabled={!billFetched || billLoading}
      isLoading={billLoading}
      submitAddClass="btn-labeled btn-labeled-left legitRipple"
      disabled={this.props.disabled}
      tooltip={this.props.disabled ? null : this.props.tooltip}
      submitTooltip={{
        target: `bill-button-${this.props.type}-${bill.id}`,
        placement: 'bottom',
        value: t(`models.bills.payment_types.${this.props.type}`)
      }}
      submitText={
        <Fragment>
          <b className="mr-2">
            <i className={this.props.icon || this.props.submitIcon} />
          </b>
          {t('words.pay')}
          &nbsp;
          <CurrencyAmount usdAmount={bill.amount} />
        </Fragment>
      }
      onToggle={this.handleOnToggle({ fetchBill })}
      onSubmit={this.handleSubmit({ bill, payBillFromAccount })}
      renderSubmit={
        this.props.type === 'card' &&
        includes(['twocheckout_button', 'stripe_button'], bill.button_type)
          ? this.handleRenderSubmit({
              bill,
              billFetched,
              billLoading,
              stripePayment
            })
          : null
      }
    >
      <AlertMessage>{billErrorMessage}</AlertMessage>
      <Loading item={bill} loaded={billFetched}>
        <table className="table table-xs">
          <thead>
            <tr>
              <th className="col-sm-12">
                {t(`models.${bill.task_id ? 'projects' : 'tasks'}.name`)}
              </th>
              <th>{t('words.cost')}</th>
              <th>{t('words.paid')}</th>
              <th>{t('words.debt')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p className="mb-0 font-weight-semibold">
                  {bill.task ? (
                    <LinkTo
                      className="text-default font-weight-semibold"
                      href={`projects/${get(bill, 'task.id')}`}
                    >
                      {get(bill.task, 'name')}
                    </LinkTo>
                  ) : (
                    <Fragment>
                      {get(bill, 'discussion.task') ? (
                        <LinkTo
                          className="text-default font-weight-semibold"
                          href={`projects/${get(bill, 'discussion.task.id')}`}
                        >
                          {get(bill, 'discussion.task.name')}
                        </LinkTo>
                      ) : null}
                      {bill.discussion ? (
                        <LinkTo
                          className="d-block text-muted font-size-sm"
                          href={`tasks/${get(bill, 'discussion.id')}`}
                        >
                          {get(bill, 'discussion.name')}
                        </LinkTo>
                      ) : null}
                    </Fragment>
                  )}
                </p>
                <p className="text-muted mb-0">
                  #{bill.discussion_id || bill.task_id}
                </p>
              </td>
              <td>
                <CurrencyAmount usdAmount={bill.amount} />
              </td>
              <td>
                <CurrencyAmount usdAmount={0} />
              </td>
              <td className="font-weight-semibold font-size-lg text-primary">
                <CurrencyAmount usdAmount={bill.amount} />
              </td>
            </tr>
          </tbody>
        </table>
      </Loading>
    </ModalButton>
  );

  render() {
    return (
      <BillsContainer fetchType="ignore" id={this.props.id}>
        {this.renderBill}
      </BillsContainer>
    );
  }
}

export default ItemBillsModal;
