export default {
  plural: 'Payments',
  balance_types: {
    debit_account: 'Debit Account',
    credit_account: 'Credit Account',
    bonus_account: 'Bonus Account',
    prepaid_balance_amount: 'Prepaid Balance Amount',
    balance_amount: 'Balance Amount'
  },
  types: {
    card: 'Pay with credit card',
    credit_card: 'Pay with credit card',
    account: 'Pay with Archivizer account',
    swift: 'SWIFT',
    charge_back: 'Charge back',
    payout: 'Payout',
    other: 'Other',
    transaction: 'Transaction'
  }
};
