import axios from 'axios';
import FileSaver from 'file-saver';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import keys from 'lodash/keys';
import last from 'lodash/last';
import omit from 'lodash/omit';
import replace from 'lodash/replace';
import snakeCase from 'lodash/snakeCase';

import { API_URI, FILES_API_URI } from '../../config';

import { loadAuthToken } from '../../utils/authToken';

function headers(config = {}) {
  return {
    headers: {
      'Content-Type': 'application/json',
      authorization: ['Bearer', loadAuthToken()].join(' ')
    },
    ...config
  };
}

const conf = {
  onError: () => {}
};

export default class apiRequest {
  static onError(cb) {
    conf.onError = cb;
  }

  static get(path, options = null, skipSnakeCase = false) {
    const url = isEmpty(options) ? path : [
      path,
      `${compact(keys(options).map(key => {
        if (options[key]) {
          if (skipSnakeCase) {
            return [
              key,
              isObject(options[key])
                ? JSON.stringify(options[key], (k, v) => (isString(v) ? encodeURIComponent(replace(v, /[\\"]/g, '\\$&')) : v))
                : encodeURIComponent(options[key])
            ].join('=');
          }
          return [
            snakeCase(key),
            isObject(options[key])
              ? JSON.stringify(options[key], (k, v) => (isString(v) ? encodeURIComponent(replace(v, /[\\"]/g, '\\$&')) : v))
              : encodeURIComponent(options[key])
          ].join('=');
        }

        return null;
      })).join('&')}`
    ].join('?');

    return axios
      .get(`${API_URI}/${url}`, headers())
      .catch(conf.onError);
  }

  static post(path, data, options = {}) {
    return axios.post(compact([
      `${API_URI}/${path}`,
      options.serializer ? `serializer=${options.serializer}` : null
    ]).join('?'), data, headers());
  }

  static upload(path, data, options = {}) {
    return axios.post(compact([
      `${FILES_API_URI}/${path}`,
      options.serializer ? `serializer=${options.serializer}` : null
    ]).join('?'), data, headers(omit(options, 'serializer')));
  }

  static patch(path, data, options = {}) {
    return axios.patch(compact([
      `${API_URI}/${path}`,
      options.serializer ? `serializer=${options.serializer}` : null
    ]).join('?'), data, headers());
  }

  static delete(path) {
    return axios.delete(`${API_URI}/${path}`, headers());
  }

  static download(path, values, name = null, options = {}) {
    return axios({
      url: `${API_URI}/${path}`,
      [options.method === 'GET' ? 'params' : 'data']: values,
      method: options.method ? options.method : 'POST',
      responseType: 'blob',
      ...headers()
    }).then(({ data, headers: hdrs }) => FileSaver.saveAs(data, name || last(hdrs['content-disposition'].split('=')).replace(/"/g, '')));
  }

  static authGet(path) {
    return axios
      .get(`${API_URI}/${path}`, headers())
      .catch(conf.onError);
  }

  static authToken() {
    return axios.get(`${API_URI}/auth/validate_token`, headers());
  }

  static authPost(path, data) {
    return axios.post(`${API_URI}/${path}`, data);
  }
}
