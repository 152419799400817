import cookie from 'react-cookie';

const authKey = 'archi_auth_token';

export function loadAuthToken() {
  return cookie.load(authKey) || localStorage.getItem('auth_token');
}

export function saveAuthToken(token) {
  localStorage.removeItem('auth_token');
  cookie.save(authKey, token, { path: '/', expires: new Date(Date.now() + (1000 * 60 * 60 * 24 * 14)) });
}

export function removeAuthToken() {
  localStorage.removeItem('auth_token');
  cookie.remove(authKey, { path: '/' });
}
